import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { customToastError } from '../utils/custom-toast-error';
import { CopyCalendarResponse } from '../interfaces/copy-calendar-response';
import { CopyCalendarRequest } from '../interfaces/copy-calendar-request';
import calendarService from '../services/calendar-service';

export function useCopyCalendar(
  configOptions: UseMutationOptions<CopyCalendarResponse, any, any, any> = {},
) {
  const queryClient = useQueryClient();
  return useMutation((body: CopyCalendarRequest) => calendarService.copyCalendar(body), {
    onError: (data: AxiosError) => {
      const err = data.response?.data as any;
      customToastError('Error', err?.message ?? 'Sorry there was a problem');
    },
    onSettled: () => {
      queryClient.invalidateQueries([calendarService.workingTimesQueryKey]);
    },
    ...configOptions,
  });
}
