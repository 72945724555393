import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePrintWorkTimeDetailsSlice } from '../../store/print-worktime-details-slice';
import { formatDate } from '../../utils/format-date';

const PrintWorkTimeDetails = () => {
  const navigate = useNavigate();
  const data = usePrintWorkTimeDetailsSlice((state) => state.data);

  useEffect(() => {
    window.print();
  }, []);

  return (
    <>
      <div className="mx-auto space-y-0 max-w-7xl pb-9 print:p-4 print:font-sans print:max-w-none print:w-full">
        <div className="flex items-center justify-between my-4 print:hidden">
          <button
            type="button"
            onClick={() => navigate(-1)}
            className="w-32 py-2 text-sm text-gray-700 border border-gray-200 rounded bg-gray-50"
          >
            Back
          </button>
          <button
            type="button"
            onClick={() => window.print()}
            className="w-32 py-2 text-sm text-gray-700 border border-gray-200 rounded bg-gray-50"
          >
            Print
          </button>
        </div>
        <div className="flex flex-col w-full">
          <div className="flex items-center justify-between px-4 mb-2 text-[10px] text-gray-500">
            <div className="flex flex-col">
              <span className="font-medium capitalize">Name: {data?.staff_name}</span>
              <span className="text-gray-300">Email: {data?.staff_email}</span>
            </div>
            <div className="flex flex-col">
              <span className="capitalize">Job: {data?.staff_job}</span>
            </div>
          </div>
          <div className="grid w-full h-12 grid-cols-7 text-xs print:text-[8px] font-medium text-gray-700 bg-gray-100 rounded">
            <div className="flex items-center justify-center h-full col-span-1 text-center">
              Date
            </div>
            {/* <div className="flex items-center justify-center h-full col-span-1 text-center">
              Scheduled
            </div> */}
            <div className="flex items-center justify-center h-full col-span-1 text-center">
              Worked
            </div>
            <div className="flex items-center justify-center h-full col-span-1 text-center">
              Used Time Off
            </div>
            {/* <div className="flex items-center justify-center h-full col-span-1 text-center">
              Owed
            </div> */}
            <div className="flex items-center justify-center h-full col-span-1 text-center">
              Extra
            </div>
            <div className="flex items-center justify-center h-full col-span-1 text-center">
              Used Breaks
            </div>
            <div className="flex items-center justify-center h-full col-span-1 text-center">
              Total Break
            </div>
            <div className="flex items-center justify-center h-full col-span-1 text-center">
              Unscheduled Working Time
            </div>
          </div>
          <div className="flex flex-col w-full divide-y-[1px] divide-gray-100 print:border-b print:border-b-gray-50">
            {data?.days.map((report, i) => {
              return (
                <>
                  <div
                    key={`${report.date}-${i}`}
                    className="grid w-full grid-cols-7 text-xs print:text-[8px] font-medium text-gray-700 rounded h-14 print:h-10"
                  >
                    <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2">
                      {formatDate(report.date_ms, 'dd-MM-y')}
                    </div>
                    {/* <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2">
                    {report.schedules_duration}
                  </div> */}
                    <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2">
                      {report.clocks_duration}
                    </div>
                    <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2">
                      {report.total_leave_requests_duration}
                    </div>
                    {/* <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2">
                    {report.owed_schedules_duration}
                  </div> */}
                    <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2">
                      {report.extra_schedules_duration}
                    </div>
                    <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2">
                      {report.clocks_breaks_duration}
                    </div>
                    <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2">
                      {report.schedules_breaks_duration}
                    </div>
                    <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2">
                      {report.un_schedules_duration}
                    </div>
                  </div>
                  {report.clocks.map((clock) => {
                    return (
                      <div
                        key={clock.id}
                        className="grid w-full grid-cols-7 text-xs print:text-[8px] font-medium text-gray-700 rounded h-14 print:h-10"
                      >
                        <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2 print:text-[14px]">
                          &rarr;
                        </div>
                        <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2">
                          Clock-in: {clock.start}
                        </div>
                        <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2">
                          Clock-out: {clock.end}
                        </div>
                      </div>
                    );
                  })}
                  {(report?.leaveRequests ?? []).map((lr) => {
                    return (
                      <div
                        key={lr.id}
                        className="grid w-full grid-cols-7 text-xs print:text-[8px] font-medium text-gray-700 rounded h-14 print:h-10"
                      >
                        <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2 text-[10px] print:text-[10px]">
                          Time off
                        </div>
                        <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2">
                          {lr.start ? `Start: ${lr.start}` : 'Daily'}
                        </div>
                        <div className="flex items-center justify-center h-full col-span-1 text-center capitalize line-clamp-2">
                          {lr.end ? `End: ${lr.end}` : 'Daily'}
                        </div>
                        <div className="flex items-center justify-center h-full col-span-4 text-center capitalize line-clamp-2">
                          Reason: {lr.reason}
                        </div>
                      </div>
                    );
                  })}
                </>
              );
            })}
          </div>
          <div className="flex flex-col w-full print:px-4">
            <div className="flex items-center h-12 px-12 text-sm font-medium text-gray-500 print:text-xs bg-gray-50 print:p-0">
              Total
            </div>
            <div className="flex space-x-4 text-xs print:text-[8px] print:mt-0">
              <div className="flex flex-col w-1/2 px-12 divide-y-[1px] divide-gray-100 print:p-0">
                <div className="flex items-center justify-between w-full text-center capitalize h-14 print:h-10 line-clamp-2">
                  <span className="font-medium text-gray-500">Scheduled</span>
                  <span className="print:font-medium">{data?.schedules_duration}</span>
                </div>
                <div className="flex items-center justify-between w-full text-center capitalize h-14 print:h-10 line-clamp-2">
                  <span className="font-medium text-gray-500">Worked</span>
                  <span className="print:font-medium">{data?.clocks_duration}</span>
                </div>
                {/* <div className="flex items-center justify-between w-full text-center capitalize h-14 print:h-10 line-clamp-2">
                  <span className="font-medium text-gray-500">Owed</span>
                  <span className="print:font-medium">{data?.owed_schedules_duration}</span>
                </div> */}
                <div className="flex items-center justify-between w-full text-center capitalize h-14 print:h-10 line-clamp-2">
                  <span className="font-medium text-gray-500">Extra</span>
                  <span className="print:font-medium">{data?.extra_schedules_duration}</span>
                </div>
              </div>
              <div className="h-[160px] w-px border-r border-r-gray-50"></div>
              <div className="flex flex-col w-1/2 px-12 divide-y-[1px] divide-gray-100 print:p-0">
                <div className="flex items-center justify-between w-full text-center capitalize h-14 print:h-10 line-clamp-2">
                  <span className="font-medium text-gray-500">Used Breaks</span>
                  <span className="print:font-medium">{data?.clocks_breaks_duration}</span>
                </div>
                {/* <div className="flex items-center justify-between w-full text-center capitalize h-14 print:h-10 line-clamp-2">
                  <span className="font-medium text-gray-500">Total Break</span>
                  <span className="print:font-medium">{data?.schedules_breaks_duration}</span>
                </div> */}
                <div className="flex items-center justify-between w-full text-center capitalize h-14 print:h-10 line-clamp-2">
                  <span className="font-medium text-gray-500">Unscheduled Working Time</span>
                  <span className="print:font-medium">{data?.un_schedules_duration}</span>
                </div>
                <div className="flex items-center justify-between w-full text-center capitalize h-14 print:h-10 line-clamp-2">
                  <span className="font-medium text-gray-500">Used Time Off</span>
                  <span className="print:font-medium">{data?.total_leave_requests_duration}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrintWorkTimeDetails;
