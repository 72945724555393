import { Resource, WeekDays } from '../calendar-reports';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Popover from '@mui/material/Popover';
import ScheduleModal from './schedule-modal';
import { Tab, useCalendarSlice } from '../../../store/calendar-slice';
import calendarService from '../../../services/calendar-service';
import { AxiosError } from 'axios';
import { dateUtc, utcDate } from '../../../utils/format-date';
import DepartmentGroup from './department-group';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import {
  useChangeDepartmentsSortData,
  useChangeEmployeesSortData,
  useDepartmentsSortData,
} from '../../../store/departments-sort-slice';
import { customToastError } from '../../../utils/custom-toast-error';
import { useCopyScheduleOfCalendar } from '../../../hooks/use-copy-schedule-of-calendar';

const reOrderDepartment = (list: Resource[][] = [], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const reOrderEmployee = (list: Resource[] = [], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

interface CalendarBodyProps {
  weekDays: WeekDays[];
  resourceGroup: Resource[][];
}

const CalendarBody = ({ weekDays, resourceGroup }: CalendarBodyProps) => {
  const queryClient = useQueryClient();
  const contextMenu = useCalendarSlice((state) => state.contextMenu);
  const contextMenuData = useCalendarSlice((state) => state.contextMenuData);
  const contextCopyData = useCalendarSlice((state) => state.contextCopyData);
  const updateContextMenuData = useCalendarSlice((state) => state.updateContextMenuData);
  const updateContextMenu = useCalendarSlice((state) => state.updateContextMenu);
  const updateScheduleModalTab = useCalendarSlice((state) => state.updateScheduleModalTab);
  const updateShowScheduleModal = useCalendarSlice((state) => state.updateShowScheduleModal);
  const updateSelectedDate = useCalendarSlice((state) => state.updateSelectedDate);
  const updateActiveResource = useCalendarSlice((state) => state.updateActiveResource);
  const updateContextCopyData = useCalendarSlice((state) => state.updateContextCopyData);
  const departmentsSortData = useDepartmentsSortData();
  const changeDepartmentsSortData = useChangeDepartmentsSortData();
  const changeEmployeesSortData = useChangeEmployeesSortData();
  const copyScheduleOfCalendar = useCopyScheduleOfCalendar();
  const sortedResourceGroup = departmentsSortData
    ? resourceGroup.sort(
        (a, b) => departmentsSortData.indexOf(a[0].depId) - departmentsSortData.indexOf(b[0].depId),
      )
    : resourceGroup;

  const createMultiWorkTimes = useMutation(calendarService.createMultiWorktime, {
    onSuccess: () => {
      queryClient.invalidateQueries([calendarService.workingTimesQueryKey]);
    },
    onError: (data: AxiosError) => {
      const err = data.response?.data as any;
      customToastError('Error', err?.message ?? 'Sorry there was a problem');
    },
  });

  const handleCloseContext = () => {
    updateContextMenu(null);
    updateContextMenuData(null);
  };

  const handleScheduleModelData = (tab: Tab) => {
    handleCloseContext();
    updateShowScheduleModal(true);
    updateScheduleModalTab(tab);
    updateActiveResource(contextMenuData!.resource);
    updateSelectedDate(contextMenuData!.date);
  };

  const handleCopyContext = () => {
    updateContextCopyData(contextMenuData!.resource);
    handleCloseContext();
  };

  const handlePasteContext = () => {
    if (!contextCopyData || !contextMenuData || createMultiWorkTimes.isLoading) {
      return;
    }
    copyScheduleOfCalendar.mutate({
      from: dateUtc(contextCopyData.schedules?.[0]?.start_at).getTime(),
      to: utcDate(dateUtc(contextMenuData.date)).getTime(),
      from_staff_id: contextCopyData.id,
      to_staff_id: contextMenuData?.resource.id,
    });
    updateContextCopyData(null);
    handleCloseContext();
  };

  const handleAddWorkTimeContext = () => {
    if (!contextMenuData) {
      return;
    }
    handleScheduleModelData('Add Worktime');
  };

  const handleWorkTimeListContext = () => {
    if (!contextMenuData) {
      return;
    }
    handleScheduleModelData('Worktime List');
  };

  const handleAddBreakContext = () => {
    if (!contextMenuData) {
      return;
    }
    handleScheduleModelData('Add Break');
  };

  const handleBreakListContext = () => {
    if (!contextMenuData) {
      return;
    }
    handleScheduleModelData('Break List');
  };

  const handleLeaveContext = () => {
    if (!contextMenuData) {
      return;
    }
    handleScheduleModelData('Time off Requests');
  };

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    if (
      result.type === 'department' &&
      result.source.droppableId === result.destination.droppableId
    ) {
      const depId = result.source.droppableId.split('-').at(2);
      const resources = resourceGroup.find((r) => r.at(0)?.depId === +depId!);
      const employeesGroup = reOrderEmployee(
        resources,
        result.source.index,
        result.destination.index,
      );
      const employeesSortingData = employeesGroup.map((d) => {
        return d.id;
      });
      const key = resources![0].depId.toString();
      changeEmployeesSortData({ [key]: employeesSortingData });
    }
    if (result.type === 'calendar') {
      const departmentGroup = reOrderDepartment(
        sortedResourceGroup,
        result.source.index,
        result.destination.index,
      );
      const departmentsSortingData = departmentGroup.map((d) => {
        return d[0].depId;
      });
      changeDepartmentsSortData(departmentsSortingData);
    }
    if (result.type === 'date-slot') {
      const draggableId = result.draggableId.split('-');
      const droppableDes = result.destination.droppableId.split('-');
      copyScheduleOfCalendar.mutate({
        from: +draggableId[1],
        to: utcDate(dateUtc(+droppableDes[1])).getTime(),
        from_staff_id: +draggableId[0],
        to_staff_id: +droppableDes[0],
      });
    }
  };

  return (
    <>
      <DragDropContext onDragEnd={(result) => handleOnDragEnd(result)}>
        <Droppable droppableId="calendar-droppable" type="calendar">
          {(provided, snapshot) => (
            <>
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="flex flex-col w-full"
              >
                {sortedResourceGroup.map((resources, idx) => {
                  return (
                    <Draggable
                      key={resources[0].depId.toString()}
                      draggableId={resources[0].depId.toString()}
                      index={idx}
                    >
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.draggableProps}>
                          <DepartmentGroup
                            weekDays={weekDays}
                            resources={resources}
                            dragHandler={provided.dragHandleProps}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              </div>
              {provided.placeholder}
            </>
          )}
        </Droppable>
      </DragDropContext>
      <Popover
        // transitionDuration={0}
        open={!!contextMenu}
        anchorReference="anchorPosition"
        anchorPosition={{ top: contextMenu?.y ?? 300, left: contextMenu?.x ?? 300 }}
        onClose={handleCloseContext}
      >
        <div className="flex flex-col p-2 divide-y-2 w-44">
          {contextMenuData?.resource.schedules.length ? (
            <button
              type="button"
              onClick={handleCopyContext}
              className="block w-40 px-2 text-sm font-medium text-left text-gray-900 rounded h-9 hover:bg-gray-100 disabled:text-gray-400 disabled:pointer-events-none"
            >
              Copy
            </button>
          ) : null}
          {contextCopyData ? (
            <button
              type="button"
              onClick={handlePasteContext}
              className="block w-40 px-2 text-sm font-medium text-left text-gray-900 rounded h-9 hover:bg-gray-100 disabled:text-gray-400 disabled:pointer-events-none"
            >
              Paste
            </button>
          ) : null}
          <button
            type="button"
            // disabled={!contextMenuData?.canModify}
            onClick={handleAddWorkTimeContext}
            className="block w-40 px-2 text-sm font-medium text-left text-gray-900 rounded h-9 hover:bg-gray-100 disabled:text-gray-400 disabled:pointer-events-none"
          >
            Add Worktime
          </button>
          <button
            type="button"
            onClick={handleWorkTimeListContext}
            className="block w-40 px-2 text-sm font-medium text-left text-gray-900 rounded h-9 hover:bg-gray-100 disabled:text-gray-400 disabled:pointer-events-none"
          >
            Worktime List
          </button>
          <button
            type="button"
            // disabled={!contextMenuData?.canModify}
            onClick={handleAddBreakContext}
            className="block w-40 px-2 text-sm font-medium text-left text-gray-900 rounded h-9 hover:bg-gray-100 disabled:text-gray-400 disabled:pointer-events-none"
          >
            Add Break
          </button>
          <button
            type="button"
            onClick={handleBreakListContext}
            className="block w-40 px-2 text-sm font-medium text-left text-gray-900 rounded h-9 hover:bg-gray-100"
          >
            Break List
          </button>
          <button
            type="button"
            onClick={handleLeaveContext}
            className="block w-40 px-2 text-sm font-medium text-left text-gray-900 rounded h-9 hover:bg-gray-100"
          >
            Time off Requests
          </button>
          {/* {contextMenuData?.resource?.schedules?.length ? (
            <button
              type="button"
              onClick={handleDeleteShiftsContext}
              className="block w-40 px-2 text-sm font-medium text-left text-gray-900 rounded h-9 hover:bg-gray-100"
            >
              Delete All Shifts
            </button>
          ) : null} */}
        </div>
      </Popover>
      <ScheduleModal />
    </>
  );
};

export default CalendarBody;
