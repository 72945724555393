import Dialog from '@mui/material/Dialog';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from '@mui/material/TextField';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { getHours, getMinutes, isBefore, setHours, setMinutes, setSeconds } from 'date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { utcDate } from '../../../utils/format-date';
import reportsService from '../../../services/reports-service';
import { useAttendanceClockSlice } from '../../../store/attendance-clock-slice';
import { useCreateClock } from '../../../hooks/use-create-clock';
import { customToastError } from '../../../utils/custom-toast-error';

interface IForm {
  start_at: string | Date | null;
  end_at: string | Date | null;
}

const schema = yup
  .object({
    start_at: yup
      .date()
      .transform((value) => value ?? undefined)
      .required('Please fill the Start Time'),
    end_at: yup
      .date()
      .transform((value) => value ?? undefined)
      .required('Please fill the End Time'),
  })
  .required();

const CreateWorkTimeModal = () => {
  const queryClient = useQueryClient();
  const worktime = useAttendanceClockSlice((state) => state.worktime);
  const isCreateWorktimeModalOpen = useAttendanceClockSlice(
    (state) => state.isCreateWorktimeModalOpen,
  );
  const resetClock = useAttendanceClockSlice((state) => state.updateClock);
  const closeModal = useAttendanceClockSlice((state) => state.updateIsCreateWorktimeModalOpen);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IForm>({
    defaultValues: {
      start_at: null,
      end_at: null,
    },
    resolver: yupResolver<any>(schema),
  });
  const createClock = useCreateClock({
    onSuccess: (data) => {
      toast.success('Schedule Updated');
      handleCloseModal();
    },
    onSettled: () => {
      queryClient.invalidateQueries([reportsService.clockInReportsQueryKey]);
    },
  });

  const onSubmit: SubmitHandler<IForm> = (data) => {
    if (createClock.isLoading) {
      return;
    }
    const startH = setHours(worktime!.start_at, getHours(new Date(data.start_at!)));
    const startM = setMinutes(startH, getMinutes(new Date(data.start_at!)));
    const start_at = utcDate(setSeconds(startM, 0)).getTime();
    const endH = setHours(worktime!.start_at, getHours(new Date(data.end_at!)));
    const endM = setMinutes(endH, getMinutes(new Date(data.end_at!)));
    const end_at = utcDate(setSeconds(endM, 0)).getTime();
    if (isBefore(endM, startM)) {
      customToastError('Error', `Please Select a valid time`);
      return;
    }
    createClock.mutate({
      clock_in: start_at,
      clock_out: end_at,
      working_object_id: worktime!.id,
    });
  };

  const handleCloseModal = () => {
    closeModal(false);
    resetClock(null);
  };

  return (
    <Dialog
      onClose={handleCloseModal}
      open={isCreateWorktimeModalOpen}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <div className="flex flex-col w-full p-6">
        <div className="pb-6 mb-6 text-lg font-medium leading-6 text-gray-900 border-b">
          Create New Clock-in
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-6">
          <div className="grid grid-cols-1 pt-6 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <Controller
                name="start_at"
                control={control}
                render={({ field }) => (
                  <TimePicker
                    {...field}
                    label="Start Time"
                    ampm={false}
                    renderInput={(params) => <TextField fullWidth={true} {...params} />}
                  />
                )}
              />
            </div>
            <div className="sm:col-span-3">
              <Controller
                name="end_at"
                control={control}
                render={({ field }) => (
                  <TimePicker
                    {...field}
                    label="End Time"
                    ampm={false}
                    renderInput={(params) => <TextField fullWidth={true} {...params} />}
                  />
                )}
              />
            </div>
          </div>
          <div className="flex items-center justify-between w-full pt-6 h-[72px] mx-6 mt-6 border-t md:mx-0">
            <button
              type="button"
              onClick={handleCloseModal}
              className="h-10 text-sm tracking-wide text-white transition bg-red-500 border border-transparent rounded shadow-sm w-28 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Close
            </button>
            <button
              type="submit"
              className="h-10 text-sm tracking-wide text-white transition bg-indigo-500 border border-transparent rounded shadow-sm w-28 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default CreateWorkTimeModal;
