import { CreateDeviceRequest } from '../interfaces/central-devices/create-device-request';
import { Device } from '../interfaces/central-devices/device';
import { useActiveWorkspaceSlice } from '../store/active-workspace-slice';
import http from './axios-instance';

const centralDevicesQueryKey = 'CentralDevices';
const getCentralDevices = async (): Promise<Device[]> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/central_devices/list`;
  const res = await http.get(url, { headers });
  return res.data;
};

const centralDevicesStaffsQueryKey = 'CentralDevicesStaffs';
const getCentralDevicesStaffs = async (): Promise<any[]> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/central_devices/staff`;
  const res = await http.get(url, { headers });
  return res.data;
};

const centralDevicesMerchantsQueryKey = 'CentralDevicesMerchants';
const getCentralDevicesMerchants = async (): Promise<any[]> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/central_devices/merchants`;
  const res = await http.get(url, { headers });
  return res.data;
};

const centralDevicesDepartmentsQueryKey = 'CentralDevicesDepartments';
const getCentralDevicesDepartments = async (): Promise<any[]> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/central_devices/departments`;
  const res = await http.get(url, { headers });
  return res.data;
};

const centralDevicesLocationsQueryKey = 'CentralDevicesLocations';
const getCentralDevicesLocations = async (): Promise<any[]> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/central_devices/locations`;
  const res = await http.get(url, { headers });
  return res.data;
};

const centralDeviceQueryKey = 'CentralDevice';
const getCentralDevice = async (id: any): Promise<Device> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/central_devices/one/${id}`;
  const res = await http.get(url, { headers });
  return res.data;
};

const createCentralDevice = async (data: CreateDeviceRequest): Promise<any> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/central_devices/create`;
  const res = await http.post(url, data, { headers });
  return res.data;
};

const updateCentralDevice = async (data: any): Promise<any> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/central_devices/update`;
  const res = await http.put(url, data, { headers });
  return res.data;
};

const detachCentralDevice = async (id: number): Promise<any> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/central_devices/detach`;
  const res = await http.put(url, { id }, { headers });
  return res.data;
};

const emailPinCodesCentralDevice = async (id: number): Promise<any> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/central_devices/email_pin_codes`;
  const res = await http.put(url, { id }, { headers });
  return res.data;
};

const deleteCentralDevice = async (id: any): Promise<{ status: string; message: string }> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/central_devices/delete/${id}`;
  const res = await http.delete(url, { headers });
  return res.data;
};

const devicesService = {
  centralDevicesQueryKey,
  getCentralDevices,
  centralDevicesStaffsQueryKey,
  getCentralDevicesStaffs,
  centralDevicesMerchantsQueryKey,
  getCentralDevicesMerchants,
  centralDevicesDepartmentsQueryKey,
  getCentralDevicesDepartments,
  centralDevicesLocationsQueryKey,
  getCentralDevicesLocations,
  centralDeviceQueryKey,
  getCentralDevice,
  createCentralDevice,
  updateCentralDevice,
  deleteCentralDevice,
  detachCentralDevice,
  emailPinCodesCentralDevice,
};

export default devicesService;
