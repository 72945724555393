import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import clockService from '../services/clock-service';
import {
  ChangeScheduleStatusRequest,
  ChangeScheduleStatusResponse,
} from '../interfaces/change-schedule-status';

export function useChangeScheduleStatus(
  configOptions: UseMutationOptions<
    ChangeScheduleStatusResponse,
    any,
    ChangeScheduleStatusRequest,
    any
  > = {},
) {
  return useMutation(
    (body: ChangeScheduleStatusRequest) => clockService.changeScheduleStatus(body),
    {
      ...configOptions,
    },
  );
}
