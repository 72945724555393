import { useMemo, useState } from 'react';
import { useTitle } from 'react-use';
import Spinner from '../../components/spinner';
import { endOfDay, subDays } from 'date-fns';
import { formatDate, formatDateToTimeZone } from '../../utils/format-date';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import PrevPage from '../../components/ui/prev-page';
import { useActiveWorkspaceSlice } from '../../store/active-workspace-slice';
import { useStaffWorkTimeReport } from '../../hooks/use-staff-worktime-report';
import { usePrintWorkTimeClocksSlice } from '../../store/print-worktime-clocks-slice';

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const StaffsWorkTimeClocksReport = () => {
  useTitle(`${import.meta.env.VITE_APP_TITLE} | Staffs Worktime Details`);
  const navigate = useNavigate();
  const query = useQuery();
  const { id, date } = useParams();
  const fromParam = query.get('from');
  const toParam = query.get('to');
  const [startDate] = useState<Date | null>(
    fromParam ? new Date(fromParam) : subDays(new Date(), 7),
  );
  const [endDate] = useState<Date | null>(toParam ? new Date(toParam) : endOfDay(new Date()));
  const from = startDate ? formatDateToTimeZone(startDate, 'y-MM-dd') : null;
  const to = endDate ? formatDateToTimeZone(endDate, 'y-MM-dd') : null;
  const workSpaceSubDomain = useActiveWorkspaceSlice((state) => state.workspace?.sub_domain)!;
  const printWorkTimeClocksSlice = usePrintWorkTimeClocksSlice((state) => state.updateData);
  const staffWorkTimeReport = useStaffWorkTimeReport(workSpaceSubDomain, from, to);
  const staffWorkTimeDetailsReport = staffWorkTimeReport.data?.find((s) => s.staff_id === +id!);
  const day = staffWorkTimeDetailsReport?.days.find((d) => d.date_ms === +(date ?? -1));
  const csvData = [
    [staffWorkTimeDetailsReport?.staff_name, staffWorkTimeDetailsReport?.staff_email],
    ['', '', ''],
    ['Date', 'Job', 'Clock in', 'Clock out'],
    ...(day?.clocks ?? []).map((report) => {
      return [
        `'${formatDate(day!.date_ms, 'dd-MM-y')}`,
        report.job_title,
        `'${report.start}`,
        `'${report.end}`,
      ];
    }),
  ];

  const handlePrint = () => {
    if (staffWorkTimeDetailsReport) {
      printWorkTimeClocksSlice({
        ...day!,
        email: staffWorkTimeDetailsReport.staff_email,
        job: staffWorkTimeDetailsReport.staff_job,
        name: staffWorkTimeDetailsReport.staff_name,
      });
      navigate('/print-worktime-clocks');
    }
  };

  return (
    <>
      <div className="w-full mx-auto space-y-0 pb-14 print:p-0 print:max-w-none print:w-full">
        <div className="flex justify-between mt-6 print:hidden md:mt-0">
          <PrevPage to="../" />
        </div>
        <div className="flex flex-col items-center justify-center w-full p-4 bg-white border border-gray-100 rounded-md shadow-lg md:p-9 print:shadow-none print:p-0 print:border-none">
          <div className="flex items-center justify-between w-full pb-6 mb-6 font-medium border-b print:pb-4 print:mb-0 print:border-b print:border-b-gray-200">
            <div className="flex flex-col w-full font-medium">
              <div className="flex items-center justify-between">
                <span className="capitalize">{staffWorkTimeDetailsReport?.staff_name}</span>
                <p className="mt-2 text-sm font-normal text-gray-400 print:mt-0">
                  {staffWorkTimeDetailsReport?.staff_email}
                </p>
              </div>
              <div className="flex flex-col w-full mt-6 space-y-8 font-medium md:space-y-0 md:space-x-4 md:flex-row md:items-center md:justify-between print:mt-0 print:hidden">
                <div className="flex items-center space-x-4">
                  <CSVLink
                    data={csvData}
                    filename={`${staffWorkTimeDetailsReport?.staff_name}-${from}-${to}.csv`}
                    className="flex items-center justify-center h-12 text-white transition duration-200 rounded w-28 bg-brand-primary hover:bg-brand-primary-dark"
                    target="_blank"
                  >
                    Export
                  </CSVLink>
                  <button
                    className="items-center justify-center hidden h-12 text-white transition duration-200 rounded md:flex w-28 bg-brand-primary hover:bg-brand-primary-dark"
                    type="button"
                    onClick={handlePrint}
                  >
                    Print
                  </button>
                </div>
              </div>
            </div>
          </div>
          {staffWorkTimeReport.isLoading ? (
            <div className="flex items-end justify-center w-full my-9">
              <span className="mr-4">Please wait</span>
              <Spinner size="small" />
            </div>
          ) : (
            <div className="flex flex-col w-full">
              <div className="grid w-full h-12 grid-cols-12 text-xs print:text-[8px] font-medium text-gray-700 bg-gray-100 rounded">
                <div className="flex items-center justify-center h-full col-span-3 text-center md:flex md:col-span-2">
                  <span className="line-clamp-2">Date</span>
                </div>
                <div className="flex items-center justify-center h-full col-span-3 text-center md:flex md:col-span-2">
                  <span className="line-clamp-2">Department</span>
                </div>
                <div className="flex items-center justify-center h-full col-span-3 text-center md:flex md:col-span-2">
                  <span className="line-clamp-2">Clock-in</span>
                </div>
                <div className="flex items-center justify-center h-full col-span-3 text-center md:flex md:col-span-2">
                  <span className="line-clamp-2">Clock-out</span>
                </div>
                <div className="items-center justify-center hidden h-full col-span-2 text-center md:flex md:col-span-2">
                  <span className="line-clamp-2">Location</span>
                </div>
                <div className="items-center justify-center hidden h-full col-span-2 text-center md:flex md:col-span-2">
                  <span className="line-clamp-2">Images</span>
                </div>
              </div>
              <div className="flex flex-col w-full divide-y-[1px] divide-gray-100">
                {day?.clocks.map((clock) => {
                  return (
                    <div
                      key={clock.id}
                      className="grid w-full grid-cols-12 text-xs print:text-[8px] font-medium text-gray-700 rounded h-14 print:h-10"
                    >
                      <div className="flex flex-col items-center justify-center h-full col-span-3 text-center capitalize md:flex md:col-span-2 line-clamp-2">
                        {formatDate(day.date_ms, 'dd-MM-y')}
                        {day.is_weekend ? (
                          <span className="mt-1 text-xs text-red-400">Weekend</span>
                        ) : day.is_holiday ? (
                          <span className="mt-1 text-xs text-red-400">Public Holiday</span>
                        ) : null}
                      </div>
                      <div className="flex items-center justify-center h-full col-span-3 text-center capitalize md:flex md:col-span-2 line-clamp-2">
                        {clock?.department_title}
                      </div>
                      <div className="flex items-center justify-center h-full col-span-3 text-center capitalize md:flex md:col-span-2 line-clamp-2">
                        {clock.start}
                      </div>
                      <div className="flex items-center justify-center h-full col-span-3 text-center capitalize md:flex md:col-span-2 line-clamp-2">
                        {clock.end}
                      </div>
                      <div className="items-center justify-center hidden h-full col-span-2 text-center capitalize md:flex md:col-span-2 line-clamp-2">
                        {clock?.location_title}
                      </div>
                      <div className="items-center justify-center hidden h-full col-span-2 space-x-4 text-center capitalize md:flex md:col-span-2 line-clamp-2">
                        {clock?.in_pic_url && (
                          <a
                            className="underline"
                            href={clock?.in_pic_url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            IN
                          </a>
                        )}
                        {clock?.out_pic_url && (
                          <a
                            className="underline"
                            href={clock?.out_pic_url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            OUT
                          </a>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default StaffsWorkTimeClocksReport;
