import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '../../components/spinner';
import { useEmployees } from '../../hooks/use-employees';
import { capitalizeFirstLetter } from '../../utils/capitalize-first-letter';
import { useStaffClockInReports } from '../../hooks/use-staff-clock-in-reports';
import ScheduleRow from './components/schedule-row';
import CreateWorkTimeModal from './components/create-clock-modal';
import EditWorkTimeModal from './components/edit-worktime-modal';
import EditClockModal from './components/edit-clock-modal';
import EditBreakModal from './components/edit-break-modal';
import ArrowLeftIcon from '~icons/mdi/chevron-left';
import { convertMToHMWithLabel } from '../../utils/format-minutes';
import { millisecondsToMinutes } from 'date-fns';

const AttendanceDetailsDate = () => {
  const navigate = useNavigate();
  const { attendanceId, date, from = '', to = '' } = useParams();
  const { data: employees = [] } = useEmployees();
  const emp = employees.find((e) => e.id === +(attendanceId ?? -1));
  const empFullName = emp ? capitalizeFirstLetter(`${emp.first_name} ${emp.last_name}`) : '-';
  const staffClockInReports = useStaffClockInReports(attendanceId, +from, +to);
  const schedules = staffClockInReports.data?.[date!];

  if (!emp) {
    return (
      <div className="flex items-end justify-center w-full my-9">
        <span className="mr-4">Please wait</span>
        <Spinner size="small" />
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col w-full mx-auto space-y-6 pb-14">
        <div className="grid grid-cols-12 gap-2 md:gap-6">
          <div className="flex flex-col col-span-12 p-6 text-xs bg-white border border-gray-100 divide-y divide-gray-100 rounded-md shadow-lg md:col-span-6 3xl:col-span-3">
            <h6 className="flex items-center justify-between h-10 font-medium text-gray-500 uppercase rounded">
              <span>Accepted</span>
              <span className="w-2 h-2 bg-green-500 rounded-full"></span>
            </h6>
            <div className="flex items-center justify-between h-8">
              <span className="">Total</span>
              <span className="font-medium">
                {convertMToHMWithLabel(
                  millisecondsToMinutes(schedules?.report?.acceptedTotal?.workObjects ?? 0),
                )}
              </span>
            </div>
            <div className="flex items-center justify-between h-8">
              <span className="">Total Count</span>
              <span className="font-medium">
                {schedules?.report.acceptedTotal.workObjectsCount}
              </span>
            </div>
            <div className="flex items-center justify-between h-8">
              <span className="">Worked</span>
              <span className="font-medium">
                {convertMToHMWithLabel(
                  millisecondsToMinutes(schedules?.report?.acceptedTotal?.clocks ?? 0),
                )}
              </span>
            </div>
            <div className="flex items-center justify-between h-8">
              <span className="">Worked Count</span>
              <span className="font-medium">{schedules?.report.acceptedTotal.clocksCount}</span>
            </div>
          </div>
          <div className="flex flex-col col-span-12 p-6 text-xs bg-white border border-gray-100 divide-y divide-gray-100 rounded-md shadow-lg md:col-span-6 3xl:col-span-3">
            <h6 className="flex items-center justify-between h-10 font-medium text-gray-500 uppercase rounded">
              <span>Pending</span>
              <span className="w-2 h-2 bg-yellow-300 rounded-full"></span>
            </h6>
            <div className="flex items-center justify-between h-8">
              <span className="">Total</span>
              <span className="font-medium">
                {convertMToHMWithLabel(
                  millisecondsToMinutes(schedules?.report?.pendingTotal?.workObjects ?? 0),
                )}
              </span>
            </div>
            <div className="flex items-center justify-between h-8">
              <span className="">Total Count</span>
              <span className="font-medium">{schedules?.report.pendingTotal.workObjectsCount}</span>
            </div>
            <div className="flex items-center justify-between h-8">
              <span className="">Worked</span>
              <span className="font-medium">
                {convertMToHMWithLabel(
                  millisecondsToMinutes(schedules?.report?.pendingTotal?.clocks ?? 0),
                )}
              </span>
            </div>
            <div className="flex items-center justify-between h-8">
              <span className="">Worked Count</span>
              <span className="font-medium">{schedules?.report.pendingTotal.clocksCount}</span>
            </div>
          </div>
          <div className="flex flex-col col-span-12 p-6 text-xs bg-white border border-gray-100 divide-y divide-gray-100 rounded-md shadow-lg md:col-span-6 3xl:col-span-3">
            <h6 className="flex items-center justify-between h-10 font-medium text-gray-500 uppercase rounded">
              <span>Rejected</span>
              <span className="w-2 h-2 bg-red-500 rounded-full"></span>
            </h6>
            <div className="flex items-center justify-between h-8">
              <span className="">Total</span>
              <span className="font-medium">
                {convertMToHMWithLabel(
                  millisecondsToMinutes(schedules?.report?.rejectedTotal?.workObjects ?? 0),
                )}
              </span>
            </div>
            <div className="flex items-center justify-between h-8">
              <span className="">Total Count</span>
              <span className="font-medium">
                {schedules?.report.rejectedTotal.workObjectsCount}
              </span>
            </div>
            <div className="flex items-center justify-between h-8">
              <span className="">Worked</span>
              <span className="font-medium">
                {convertMToHMWithLabel(
                  millisecondsToMinutes(schedules?.report?.rejectedTotal?.clocks ?? 0),
                )}
              </span>
            </div>
            <div className="flex items-center justify-between h-8">
              <span className="">Worked Count</span>
              <span className="font-medium">{schedules?.report.rejectedTotal.clocksCount}</span>
            </div>
          </div>
          <div className="flex flex-col col-span-12 p-6 text-xs bg-white border border-gray-100 divide-y divide-gray-100 rounded-md shadow-lg md:col-span-6 3xl:col-span-3">
            <h6 className="flex items-center justify-between h-10 font-medium text-gray-500 uppercase rounded">
              <span>Breaks</span>
              <span className="w-2 h-2 bg-yellow-500 rounded-full"></span>
            </h6>
            <div className="flex items-center justify-between h-8">
              <span className="">Total</span>
              <span className="font-medium">
                {convertMToHMWithLabel(
                  millisecondsToMinutes(schedules?.report?.breaksTotal?.workObjectBreaks ?? 0),
                )}
              </span>
            </div>
            <div className="flex items-center justify-between h-8">
              <span className="">Total Count</span>
              <span className="font-medium">
                {schedules?.report.breaksTotal.workObjectBreaksCount}
              </span>
            </div>
            <div className="flex items-center justify-between h-8">
              <span className="">Used</span>
              <span className="font-medium">
                {convertMToHMWithLabel(
                  millisecondsToMinutes(schedules?.report?.breaksTotal?.clockBreaks ?? 0),
                )}
              </span>
            </div>
            <div className="flex items-center justify-between h-8">
              <span className="">Used Count</span>
              <span className="font-medium">{schedules?.report.breaksTotal.clockBreaksCount}</span>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center w-full space-y-6 bg-white border border-gray-100 rounded-md shadow-lg md:space-y-0 p-9">
          <div className="flex flex-col w-full text-sm text-gray-500 md:h-12 md:flex-row md:justify-between">
            <div className="flex space-x-2">
              <button
                onClick={() => navigate(-1)}
                className="flex items-center justify-center w-7 h-6 -mt-[2px] text-white transition duration-200 ease-in-out rounded bg-brand-primary"
              >
                <ArrowLeftIcon className="w-6 h-6" />
              </button>
              <span className="font-medium">{empFullName}</span>
            </div>
            <span className="mt-4 font-medium md:hidden pl-9 md:pl-0 md:mt-0">{date}</span>
            <span className="hidden mt-4 font-medium md:block pl-9 md:pl-0 md:mt-0">
              Date: {date}
            </span>
          </div>
          <div className="grid w-full grid-cols-6 font-medium text-gray-500 border-gray-100 border-[1px] rounded-tr-xl rounded-tl-xl bg-gray-50 h-14">
            <div className="flex items-center col-span-2 pl-2 text-sm font-medium text-left md:col-span-1 lg:pl-6">
              Type
            </div>
            <div className="flex items-center col-span-2 pl-2 text-sm font-medium text-left md:col-span-1 lg:pl-6">
              Start
            </div>
            <div className="flex items-center col-span-2 pl-2 text-sm font-medium text-left md:col-span-1 lg:pl-6">
              End
            </div>
            <div className="items-center hidden col-span-1 pl-2 text-sm font-medium text-left md:flex lg:pl-6">
              Clocks
            </div>
            <div className="items-center hidden col-span-1 pl-2 text-sm font-medium text-left md:flex lg:pl-6">
              Status
            </div>
            <div className="items-center hidden col-span-1 pl-2 text-sm font-medium text-left md:flex lg:pl-6">
              Actions
            </div>
          </div>
          <div className="flex flex-col w-full space-y-2">
            {(schedules?.workObjects ?? []).map((schedule) => {
              return <ScheduleRow key={schedule.id} schedule={schedule} />;
            })}
          </div>
        </div>
      </div>
      <CreateWorkTimeModal />
      <EditWorkTimeModal />
      <EditClockModal />
      <EditBreakModal />
    </>
  );
};

export default AttendanceDetailsDate;
