import { UnSplash } from '../interfaces/unsplash';

export const unSplashImage = async (): Promise<UnSplash> => {
  const url: any = new URL(`${import.meta.env.VITE_UNSPLASH_BASE_URL}/photos/random/`);
  const params = {
    client_id: `${import.meta.env.VITE_UNSPLASH_CLIENT_ID}`,
    // orientation: 'landscape',
    query: 'time'
    // fit: 'crop'
    // w: '1024',
    // h: '300'
  };
  url.search = new URLSearchParams(params).toString();
  return fetch(url, {
    headers: {
      'Accept-Version': 'v1'
    }
  }).then(data => {
    return data.json();
  });
};
