import { useQuery } from '@tanstack/react-query';
import notificationService from '../services/notification-service';

export function useNotifications(subDomain: string | undefined) {
  return useQuery(
    [notificationService.notificationsQueryKey],
    notificationService.getNotifications,
    {
      enabled: !!subDomain,
      staleTime: 1000 * 60 * 1,
      select(data) {
        return data.data.filter((el) => el.seen === 0);
      },
    },
  );
}
