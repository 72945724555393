import { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTitle } from 'react-use';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useAuthSlice } from '../../store/auth-slice';
import { VerifyRequest } from '../../interfaces/verify-interface';
import { AxiosError } from 'axios';
import { SendVerify } from '../../interfaces/send-verify-interface';
import authService from '../../services/auth-service';
import FormError from '../../components/form-error';
import { VERIFY_CODE_TIME } from '../../constants/verify-code';
import UnSplashImage from '../../components/libs/unsplash-image';
import { useOtpSlice } from '../../store/otp-slice';
import { addSeconds, differenceInSeconds, isAfter } from 'date-fns';
import { customToastError } from '../../utils/custom-toast-error';

const schema = yup
  .object({
    otp: yup
      .string()
      .matches(/^[0-9]+$/, 'Please enter the code')
      .min(6, 'Must be exactly 6 digits')
      .max(6, 'Must be exactly 6 digits')
      .required('Please enter the code'),
  })
  .required();

const CheckOtp = () => {
  useTitle(`${import.meta.env.VITE_APP_TITLE} | Check Otp`);
  const navigate = useNavigate();
  const updateUser = useAuthSlice((state) => state.updateUser);
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const ex = searchParams.get('ex');
  const [timeLeft, setTimeLeft] = useState<Date>(
    ex ? addSeconds(new Date(), +ex) : addSeconds(new Date(), VERIFY_CODE_TIME),
  );
  const [sec, setSec] = useState<number>(-1);
  const emailFromStore = useOtpSlice((state) => state.email);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Pick<VerifyRequest, 'otp'>>({
    resolver: yupResolver<any>(schema),
  });

  useEffect(() => {
    if (!emailFromStore) {
      navigate(`/login`);
    }
  }, [emailFromStore]);

  useEffect(() => {
    if (isAfter(new Date(), timeLeft)) {
      setSec(-1);
      return;
    }
    const intervalId = setInterval(() => {
      const diff = differenceInSeconds(timeLeft, new Date());
      setSec(diff);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const {
    mutate: verifyMutate,
    isLoading: verifyIsLoading,
    error: checkOtpError,
  } = useMutation(authService.verify, {
    onSuccess: (data) => {
      updateUser(data);
      toast.success(`Welcome ${data.user.first_name}, Let's go`);
      navigate('/');
    },
  });

  const { mutate: sendCodeMutate, isLoading: sendCodeIsLoading } = useMutation(
    authService.sendVerifyCode,
    {
      onSuccess: (data) => {
        setTimeLeft(addSeconds(new Date(), VERIFY_CODE_TIME));
        toast.success(`Code has been sent`);
      },
      onError: (data: AxiosError<SendVerify>) => {
        if (data.response?.status === 406) {
          const time = data.response?.data?.ex;
          setTimeLeft(addSeconds(new Date(), +(time ?? VERIFY_CODE_TIME)));
        }
        // customToastError('Error', data.response?.data.message ?? 'Sorry there is a problem');
      },
    },
  );

  const onSubmit: SubmitHandler<Pick<VerifyRequest, 'otp'>> = async (data) => {
    if (verifyIsLoading) {
      return;
    }
    if (email) {
      verifyMutate({ ...data, email });
    } else {
      customToastError('Error', `Email doesn't exist`);
    }
  };

  const resendCode = () => {
    if (sendCodeIsLoading) {
      return;
    }
    if (email) {
      if (sec < 0) {
        sendCodeMutate({ email });
        return;
      }
      customToastError('Error', `you cant resend right now`);
      return;
    }
    customToastError('Error', `Email doesn't exist`);
    return;
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="fixed top-0 bottom-0 right-0 left-0 z-[1]">
        <UnSplashImage />
      </div>
      <div className="z-50 w-full max-w-sm mx-auto bg-white rounded-md shadow-lg lg:max-w-xl p-9">
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-3xl font-bold text-center">Congratulations!</h1>
          <h1 className="text-3xl font-bold text-center">
            You&apos;ve set up your account! Wasn&apos;t that easy?
          </h1>
          <p className="mt-10 text-sm text-center text-gray-600">
            Just one more step: Please check your email account ({email}) for the verification code
            we just sent you and enter that code in the box below.
          </p>
        </div>
        <div className="mt-8">
          <div className="mt-6">
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
              <div>
                <label htmlFor="otp" className="block text-sm font-medium text-gray-700">
                  Code
                </label>
                <div className="mt-1">
                  <input
                    id="otp"
                    type="text"
                    {...register('otp')}
                    className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <p className="h-8 pt-2 text-red-500 capitalize">{errors.otp?.message}</p>
              </div>
              <div>
                <button
                  type="submit"
                  className="flex justify-center w-full px-4 py-4 mt-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Confirm
                </button>
              </div>
              <div className="flex items-center mt-4 text-sm font-medium text-gray-600">
                <span>Didn&apos;t receive a code?</span>
                <button
                  disabled={sec >= 0 || sendCodeIsLoading}
                  type="button"
                  className="ml-2 text-blue-600 underline border-0"
                  onClick={resendCode}
                >
                  Resend
                </button>
                {sec >= 0 ? <span className="ml-2">in {sec} Sec</span> : null}
              </div>
              <FormError error={checkOtpError} />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CheckOtp;
