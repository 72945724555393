import { XMarkIcon } from '@heroicons/react/24/outline';
import Dialog from '@mui/material/Dialog';
import { useState } from 'react';

export interface ConfirmationWithReasonModalData {
  confirm: boolean;
  text: string;
}

interface ConfirmationWithReasonModalProps {
  show: boolean;
  closeModal: ({ confirm, text }: ConfirmationWithReasonModalData) => void;
  title?: string;
  description?: string;
}

const ConfirmationWithReasonModal = ({
  show,
  closeModal,
  title = 'Are you sure about this?',
  description = '',
}: ConfirmationWithReasonModalProps) => {
  const [text, setText] = useState('');
  const [error, setError] = useState(false);

  const handleClose = (confirm: boolean) => {
    if (confirm && !text) {
      setError(true);
      return;
    }
    closeModal({ confirm, text });
    setText('');
    setError(false);
  };

  return (
    <Dialog onClose={() => handleClose(false)} open={show} fullWidth={false} maxWidth={'sm'}>
      <div className="flex flex-col max-w-full min-w-[400px] p-6">
        <div className="flex items-center justify-between text-lg font-medium leading-6 text-gray-900">
          <div className="flex flex-col">
            <span className="leading-9">{title}</span>
            {description ? <span className="mt-1 text-base text-gray-500">{description}</span> : ''}
          </div>
          <button
            type="button"
            onClick={() => handleClose(false)}
            className="ml-6 transition border border-transparent rounded shadow-sm self-baseline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          >
            <XMarkIcon className="w-6 h-6" />
          </button>
        </div>
        <textarea
          value={text}
          onChange={(e) => setText(e.target.value)}
          className={`h-40 p-4 mt-4 border rounded ${error ? 'border-red-500' : 'border-gray-200'}`}
          placeholder="reason..."
        ></textarea>
        <div className="flex items-center justify-between w-full mx-6 pt-9 md:mx-0">
          <button
            type="button"
            onClick={() => handleClose(true)}
            className="h-10 ml-auto text-sm tracking-wide text-white transition bg-indigo-500 border border-transparent rounded shadow-sm w-28 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Confirm
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmationWithReasonModal;
