import { useCallback, useState } from 'react';
import { useTitle } from 'react-use';
import PencilIcon from '~icons/carbon/edit';
import TrashIcon from '~icons/carbon/trash-can';
import InformationIcon from '~icons/carbon/information';
import { Link, useNavigate } from 'react-router-dom';
import Confirmation from '../../components/libs/confirmation';
import Spinner from '../../components/spinner';
import { capitalizeFirstLetter } from '../../utils/capitalize-first-letter';
import { PlusIcon } from '@heroicons/react/24/outline';
import { formatDateToTimeZone } from '../../utils/format-date';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { matchSorter } from 'match-sorter';
import { useGetCentralDevices } from '../../hooks/centeral-devices/use-get-central-devices';
import { deviceLoginMethods } from '../new-device/new-device';
import { Device } from '../../interfaces/central-devices/device';
import { useDeleteCentralDevice } from '../../hooks/centeral-devices/use-delete-central-device';
import { useDetachCentralDevice } from '../../hooks/centeral-devices/use-detach-central-device';
import { Tooltip } from '@mui/material';
import { useEmailPinCodeCentralDevice } from '../../hooks/centeral-devices/use-email-pin-code-central-device';

const DevicesList = () => {
  useTitle(`${import.meta.env.VITE_APP_TITLE} | Devices List`);
  const navigate = useNavigate();
  const centralDevices = useGetCentralDevices();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deviceId, setDeviceId] = useState<Device['id'] | null>(null);
  const [term, setTerm] = useState('');
  const filteredDevices = term
    ? matchSorter(centralDevices.data ?? [], term, {
        keys: ['name'],
      })
    : centralDevices.data;
  const deleteDevice = useDeleteCentralDevice();
  const detachCentralDevice = useDetachCentralDevice();
  const emailPinCodeCentralDevice = useEmailPinCodeCentralDevice();

  const handleEdit = (id: Device['id']) => {
    navigate(`../edit-device/${id}`);
  };

  const handleConfirmation = (bool: boolean) => {
    if (bool && deviceId) {
      deleteDevice.mutate(deviceId);
    }
  };

  const handleDelete = (id: number) => {
    setDeviceId(id);
    handleOpenConfirmation();
  };

  // const handleGenerateCode = (id: number) => {

  // };

  const handleLockDevice = (id: number) => {
    detachCentralDevice.mutate(id);
  };

  const handleEmail = (id: number) => {
    emailPinCodeCentralDevice.mutate(id);
  };

  const handleOpenConfirmation = useCallback(() => {
    setShowConfirmation(true);
  }, []);

  const handleCloseConfirmation = useCallback(() => {
    setShowConfirmation(false);
  }, []);

  const handleTermChanges = useCallback((term: string) => {
    setTerm(term);
  }, []);

  if (centralDevices.isLoading) {
    return (
      <div className="flex items-end justify-center w-full my-9">
        <span className="mr-4">Please wait</span>
        <Spinner size="small" />
      </div>
    );
  }

  return (
    <div className="w-full mx-auto space-y-8 pb-14">
      <div className="flex flex-col pt-1 space-y-4 md:space-y-0 md:flex-row md:items-center md:justify-between">
        <div className="flex flex-col space-y-4 md:flex-row md:items-center md:space-y-0 md:space-x-4">
          <Link
            to="../new-device"
            className="flex items-center justify-center w-full text-sm font-medium text-white transition duration-200 rounded shadow md:w-40 bg-brand-primary hover:bg-brand-primary-dark h-11"
          >
            <PlusIcon className="w-6 h-6 mr-2" />
            Add Device
          </Link>
          <div className="relative text-gray-400 border border-gray-200 rounded focus-within:text-gray-500">
            <input
              id="mobile-search"
              type="search"
              placeholder="Search ..."
              onChange={(e) => handleTermChanges(e.target.value)}
              className="block w-full pl-10 placeholder-gray-500 border-none rounded-md focus:outline-none focus:ring-brand-primary h-11 "
            />
            <div className="absolute inset-y-0 left-0 flex items-center justify-center pl-3">
              <MagnifyingGlassIcon className="w-5 h-5" aria-hidden="true" />
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-4 md:flex-row md:items-center md:space-y-0 md:space-x-4"></div>
      </div>
      <div className="flex flex-col items-center justify-center w-full p-4 bg-white border border-gray-100 rounded-md shadow-lg md:p-9">
        <div className="flex flex-col w-full pt-2 pb-6 mb-6 border-b md:flex-row md:items-center md:justify-between">
          <div className="flex flex-col">
            <span>Devices List</span>
            <p className="mt-2 text-sm font-normal text-gray-400">
              Here is a quick snapshot of your devices.
            </p>
          </div>
        </div>
        <div className="grid w-full grid-cols-12 text-sm font-medium rounded bg-gray-50 text-gray-90 h-14">
          <div className="items-center hidden col-span-2 pl-2 pr-2 md:pl-8 md:flex">
            <span className="line-clamp-2">Created at</span>
          </div>
          <div className="flex items-center col-span-2 pl-2 pr-2 md:pl-8 md:col-span-2 md:flex">
            <span className="line-clamp-2">Name</span>
          </div>
          <div className="items-center hidden col-span-3 pl-2 pr-2 md:pl-8 md:flex">
            <span className="line-clamp-2">Default Login Method</span>
          </div>
          <div className="items-center hidden col-span-2 pl-2 pr-2 md:pl-8 md:flex">
            <span className="line-clamp-2">Pairing Code</span>
          </div>
          <div className="flex items-center col-span-3 pl-2 pr-2 md:pl-8 md:col-span-2 md:flex">
            <span className="line-clamp-2">Actions</span>
          </div>
        </div>
        <div className="flex flex-col w-full text-gray-700 divide-y divide-gray-100">
          {(filteredDevices ?? []).map((device) => {
            const defaultLoginMethod = deviceLoginMethods.find(
              (item) => item.value === device.default_staff_login_method,
            );
            return (
              <div className="grid w-full h-16 grid-cols-12 text-sm" key={device.id}>
                <div className="flex-col justify-center hidden w-full col-span-2 pl-2 pr-2 md:pl-8 md:flex">
                  <span className="font-medium first-letter:capitalize">
                    {formatDateToTimeZone(device.created_at, 'dd-MM-y')}
                  </span>
                </div>
                <div className="flex items-center w-full col-span-2 pl-2 pr-2 md:pl-8 md:flex md:col-span-2">
                  <span
                    title={capitalizeFirstLetter(`${device.name}`)}
                    className="font-medium text-left line-clamp-1"
                  >
                    {capitalizeFirstLetter(`${device.name}`)}
                  </span>
                </div>
                <div className="flex items-center w-full col-span-3 pl-2 pr-2 md:pl-8 md:flex md:col-span-3">
                  <span
                    title={capitalizeFirstLetter(`${defaultLoginMethod}`)}
                    className="font-medium text-left line-clamp-1"
                  >
                    {defaultLoginMethod?.label}
                  </span>
                </div>
                <div className="flex items-center w-full col-span-2 pl-2 pr-2 md:pl-8 md:flex md:col-span-2">
                  <span className="flex items-center space-x-2 font-medium text-left line-clamp-1">
                    <Tooltip title="Enter this code into the Central Terminal app to pair the device. Once paired, the app will be ready for employees and the admin to use.">
                      <span>
                        <InformationIcon className={`w-5 h-5 text-blue-500`} />
                      </span>
                    </Tooltip>
                    <span>{device?.pairing_code}</span>
                  </span>
                </div>
                <div className="flex items-center col-span-3 pl-2 pr-2 space-x-2 md:pl-8 md:flex md:col-span-3 2xl:space-x-4">
                  <button
                    type="button"
                    className=""
                    disabled={deleteDevice.isLoading}
                    onClick={() => handleDelete(device.id)}
                  >
                    <TrashIcon className="w-6 h-6 text-gray-400 transition hover:text-gray-800" />
                  </button>
                  <button
                    type="button"
                    className=""
                    disabled={detachCentralDevice.isLoading || deleteDevice.isLoading}
                    onClick={() => handleEdit(device.id)}
                  >
                    <PencilIcon className="w-6 h-6 text-gray-400 transition hover:text-gray-800" />
                  </button>
                  {/* <button
                    type="button"
                    className="h-10 px-2 text-xs font-medium text-white bg-green-500 rounded w-28"
                    disabled={true}
                    onClick={() => handleGenerateCode(device.id)}
                  >
                    Generate Code
                  </button> */}
                  {device.device_lock_code && (
                    <button
                      type="button"
                      className="h-10 px-2 text-xs font-medium text-white bg-red-500 rounded w-28"
                      disabled={detachCentralDevice.isLoading || deleteDevice.isLoading}
                      onClick={() => handleLockDevice(device.id)}
                    >
                      Detach
                    </button>
                  )}
                  <button
                    type="button"
                    title="Send login details to all employees on this device."
                    className="h-10 px-2 text-xs font-medium text-white bg-blue-500 rounded w-28"
                    disabled={
                      detachCentralDevice.isLoading ||
                      deleteDevice.isLoading ||
                      emailPinCodeCentralDevice.isLoading
                    }
                    onClick={() => handleEmail(device.id)}
                  >
                    Send Details
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Confirmation
        show={showConfirmation}
        closeModal={handleCloseConfirmation}
        confirm={handleConfirmation}
      />
    </div>
  );
};

export default DevicesList;
