import { create } from 'zustand';

type State = {
  employeeForm: any;
};

type Actions = {
  updateEmployeeForm: (employeeForm: any) => void;
  reset: () => void;
};

const initialState: State = {
  employeeForm: null,
};

export const useEmployeeFormSlice = create<State & Actions>()((set) => ({
  ...initialState,
  updateEmployeeForm: (employeeForm) => {
    set({ employeeForm });
  },
  reset: () => {
    set({ ...initialState });
  },
}));
