import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { endOfYear, startOfDay, startOfYear } from 'date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { utcDate } from '../../../utils/format-date';
import { DatePicker } from '@mui/x-date-pickers';

interface IForm {
  date: Date;
  description: string;
}

const schema = yup
  .object({
    date: yup
      .date()
      .transform((value) => value ?? null)
      .required('Please fill the Date'),
    description: yup.string(),
  })
  .required();

interface HolidayModalProps {
  open: boolean;
  close: (data: { date: number; description: string } | null) => void;
}

const HolidayModal = ({ open, close }: HolidayModalProps) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IForm>({
    defaultValues: {
      date: new Date(),
      description: '',
    },
    resolver: yupResolver<any>(schema),
  });

  const onSubmit: SubmitHandler<IForm> = (data) => {
    const date = utcDate(startOfDay(data.date)).getTime();
    handleCloseModal({ ...data, date });
  };

  const handleCloseModal = (data: { date: number; description: string } | null = null) => {
    close(data);
    reset({ date: new Date(), description: '' });
  };

  return (
    <Dialog onClose={() => handleCloseModal()} open={open} fullWidth={true} maxWidth={'sm'}>
      <div className="flex flex-col w-full p-6">
        <div className="pb-6 mb-6 text-lg font-medium leading-6 text-gray-900 border-b">
          Add Holiday
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-6">
          <div className="grid grid-cols-12 pt-6 gap-y-6 gap-x-4">
            <div className="col-span-12">
              <Controller
                name="date"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    label="Date"
                    disableMaskedInput
                    minDate={startOfYear(new Date())}
                    maxDate={endOfYear(new Date())}
                    inputFormat="dd MMM y"
                    renderInput={(params) => <TextField {...params} fullWidth={true} {...params} />}
                  />
                )}
              />
            </div>
            <div className="col-span-12">
              <textarea
                id="description"
                placeholder="Description"
                rows={4}
                {...register('description')}
                className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="flex items-center justify-between w-full pt-6 h-[72px] mx-6 mt-6 border-t md:mx-0">
            <button
              type="button"
              onClick={() => handleCloseModal()}
              className="h-10 text-sm tracking-wide text-white transition bg-red-500 border border-transparent rounded shadow-sm w-28 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Close
            </button>
            <button
              type="submit"
              className="h-10 text-sm tracking-wide text-white transition bg-indigo-500 border border-transparent rounded shadow-sm w-28 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default HolidayModal;
