import React, { useEffect, useState } from 'react';
import { useDepartments } from '../../../hooks/use-departments';
import { useJobs } from '../../../hooks/use-jobs';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Popover } from '@mui/material';
import { AccessLevelStaffs, calculateLength } from '../../../utils/employee-utils';
import { useEmployees } from '../../../hooks/use-employees';
import { Employee as IEmployee } from '../../../interfaces/employee-interface';

interface AccessLevelProps {
  handleStaffIdsChange: (data: number[]) => void;
  employeeId?: string | null;
}

const AccessLevel = ({ employeeId, handleStaffIdsChange }: AccessLevelProps) => {
  const [accessLevelStaffs, setAccessLevelStaffs] = useState<AccessLevelStaffs[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { data: employees = [] } = useEmployees();
  const { data: departments = [] } = useDepartments();
  const { data: jobs = [] } = useJobs();
  const open = Boolean(anchorEl);
  const selectedStaffsNumber = calculateLength(accessLevelStaffs);

  useEffect(() => {
    let emp: IEmployee | null = null;
    const filteredEmployees = employees?.filter((e) => e.id !== +(employeeId ?? -1));
    if (employeeId) {
      emp = employees?.find((d) => d.id === +employeeId) ?? null;
    }
    const list = departments.map((d) => {
      const js = jobs.flatMap((j) => {
        if (j.department_id === d.id) {
          const staffs = filteredEmployees.flatMap((e) => {
            if (e.job_id === j.id) {
              const find = emp?.staff_ids?.find((s) => s === e.id);
              let isStaffActive = false;
              if (employeeId && find) {
                isStaffActive = true;
              }
              return [
                {
                  staffId: e.id,
                  staffName: `${e.first_name} ${e.last_name}`,
                  active: isStaffActive,
                },
              ];
            }
            return [];
          });
          return [
            {
              jobId: j.id,
              jobName: j.title,
              active: staffs.length ? staffs.every((s) => s.active) : false,
              staffs,
            },
          ];
        }
        return [];
      });
      return {
        depId: d.id,
        depName: d.title,
        active: js.length ? js.every((j) => j.active) : false,
        isOpen: false,
        jobs: js,
      };
    });
    setAccessLevelStaffs(list);
  }, [departments, jobs, employees]);

  useEffect(() => {
    handleChange();
  }, [accessLevelStaffs]);

  const handleChange = () => {
    const ids: number[] = [];
    accessLevelStaffs.forEach((d) => {
      d.jobs.forEach((j) => {
        j.staffs.forEach((s) => {
          if (s.active) {
            ids.push(s.staffId);
          }
        });
      });
    });
    handleStaffIdsChange(ids);
  };

  const handleOpenAccessLevel = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAccessLevel = () => {
    setAnchorEl(null);
  };

  const handleALDepartment = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, id } = e.target as HTMLInputElement;
    const depId = +id.split('-')[0];
    setAccessLevelStaffs((deps) => {
      return deps.map((dep) => {
        if (dep.depId === depId) {
          return {
            ...dep,
            active: checked,
            jobs: dep.jobs.map((job) => {
              return {
                ...job,
                active: checked,
                staffs: job.staffs.map((staff) => {
                  return { ...staff, active: checked };
                }),
              };
            }),
          };
        }
        return dep;
      });
    });
  };

  const handleALJob = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, id } = e.target as HTMLInputElement;
    const str = id.split('-');
    const depId = +str[0];
    const jobId = +str[1];
    setAccessLevelStaffs((als) => {
      return als.map((a) => {
        if (a.depId === depId) {
          return {
            ...a,
            jobs: a.jobs.map((j) => {
              if (j.jobId === jobId) {
                return {
                  ...j,
                  active: checked,
                  staffs: j.staffs.map((staff) => {
                    return { ...staff, active: checked };
                  }),
                };
              }
              return j;
            }),
          };
        }
        return a;
      });
    });
  };

  const handleALStaff = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, id } = e.target as HTMLInputElement;
    const str = id.split('-');
    const depId = +str[0];
    const jobId = +str[1];
    const staffId = +str[2];
    setAccessLevelStaffs((als) => {
      return als.map((a) => {
        if (a.depId === depId) {
          return {
            ...a,
            jobs: a.jobs.map((j) => {
              if (j.jobId === jobId) {
                return {
                  ...j,
                  staffs: j.staffs.map((s) => {
                    if (s.staffId === staffId) {
                      return { ...s, active: checked };
                    }
                    return s;
                  }),
                };
              }
              return j;
            }),
          };
        }
        return a;
      });
    });
  };

  const handleToggleDepartment = (id: number) => {
    setAccessLevelStaffs((deps) => {
      return deps.map((dep) => {
        if (dep.depId === id) {
          return {
            ...dep,
            isOpen: !dep.isOpen,
          };
        }
        return dep;
      });
    });
  };

  return (
    <>
      <label htmlFor="staffs" className="block text-sm font-medium text-gray-700">
        Access level
      </label>
      <div className="">
        <button
          type="button"
          onClick={handleOpenAccessLevel}
          className="w-40 h-10 text-sm font-medium text-white bg-indigo-500 border border-transparent rounded shadow-sm hover:bg-indigo-700"
        >
          <>{selectedStaffsNumber} Staff Selected</>
        </button>
        <Popover
          id="al"
          open={open}
          anchorEl={anchorEl}
          onClose={handleCloseAccessLevel}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div className="flex flex-col p-4 select-none">
            <div className="relative grid gap-2 bg-white w-[250px] max-h-[250px] overflow-y-auto divide-y divide-gray-100">
              {accessLevelStaffs.map((dep) => {
                const depKey = `${dep.depId}-${dep.depName}`;
                return (
                  <React.Fragment key={depKey}>
                    <div className="flex items-center flex-1 p-2 mr-2 bg-gray-200">
                      <input
                        type="checkbox"
                        id={depKey}
                        checked={dep.active}
                        onChange={(e) => handleALDepartment(e)}
                        className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                      />
                      <label
                        htmlFor={depKey}
                        className="flex-1 block ml-4 mr-4 text-sm font-medium text-gray-700"
                      >
                        {dep.depName}
                      </label>
                      <ChevronDownIcon
                        onClick={() => handleToggleDepartment(dep.depId)}
                        className={`cursor-pointer h-5 w-5 transition ${
                          !dep.isOpen ? 'rotate-180 transform' : ''
                        }`}
                      />
                    </div>
                    {dep.isOpen &&
                      dep.jobs.map((job) => {
                        const jobKey = `${dep.depId}-${job.jobId}-${job.jobName}`;
                        return (
                          <React.Fragment key={jobKey}>
                            <div className="flex items-center flex-1 p-2 pl-4 mr-2 bg-gray-100">
                              <input
                                type="checkbox"
                                id={jobKey}
                                checked={job.active}
                                onChange={(e) => handleALJob(e)}
                                className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                              />
                              <label
                                htmlFor={jobKey}
                                className="flex-1 block ml-4 text-sm font-medium text-gray-700"
                              >
                                {job.jobName}
                              </label>
                            </div>
                            {job.staffs.map((staff) => {
                              const staffKey = `${dep.depId}-${job.jobId}-${staff.staffId}-${staff.staffName}`;
                              return (
                                <React.Fragment key={staffKey}>
                                  <div className="flex items-center flex-1 p-2 pl-8 mr-2">
                                    <input
                                      type="checkbox"
                                      id={staffKey}
                                      checked={staff.active}
                                      onChange={(e) => handleALStaff(e)}
                                      className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                                    />
                                    <label
                                      htmlFor={staffKey}
                                      className="flex-1 block ml-4 text-sm font-medium text-gray-700"
                                    >
                                      {staff.staffName}
                                    </label>
                                  </div>
                                </React.Fragment>
                              );
                            })}
                          </React.Fragment>
                        );
                      })}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </Popover>
      </div>
    </>
  );
};

export default AccessLevel;
