import './drop-loading.scss';

const DropLoading = () => {
  return (
    <div className="lds-ripple">
      <div></div>
      <div></div>
    </div>
  );
};

export default DropLoading;
