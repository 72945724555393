import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { CreateClock, CreateClockResponse } from '../interfaces/clock-interface';
import clockService from '../services/clock-service';
import reportsService from '../services/reports-service';
import { customToastError } from '../utils/custom-toast-error';

export function useCreateClock(
  configOptions: UseMutationOptions<CreateClockResponse, any, any, any> = {},
) {
  const queryClient = useQueryClient();
  return useMutation((body: CreateClock) => clockService.createClock(body), {
    onError: (data: AxiosError) => {
      const err = data.response?.data as any;
      customToastError('Error', err?.message ?? 'Sorry there was a problem');
    },
    onSettled: () => {
      queryClient.invalidateQueries([reportsService.clockInReportsQueryKey]);
    },
    ...configOptions,
  });
}
