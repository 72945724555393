import { isBefore, isAfter, startOfDay, addHours } from 'date-fns';

export function greetings() {
  const now = new Date();
  const start = startOfDay(now);
  const greet = {
    night: isBefore(now, addHours(start, 5)),
    morning: isAfter(now, addHours(start, 5)) && isBefore(now, addHours(start, 12)),
    afternoon: isAfter(now, addHours(start, 12)) && isBefore(now, addHours(start, 17)),
    evening: isAfter(now, addHours(start, 17)),
  } as Record<string, boolean>;
  for (const key in greet) {
    if (greet[key]) {
      return key;
    }
  }
}
