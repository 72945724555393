import { useState } from 'react';
import PinIcon from '~icons/carbon/pin';
import PencilIcon from '~icons/carbon/edit';
import TrashIcon from '~icons/carbon/trash-can';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import Confirmation from '../../../components/libs/confirmation';
import locationsService from '../../../services/locations-service';
import { formatDate } from '../../../utils/format-date';
import { Location as ILocation } from '../../../interfaces/location-interface';
import { useNavigate } from 'react-router-dom';
import { customToastError } from '../../../utils/custom-toast-error';
import { useActiveWorkspaceSlice } from '../../../store/active-workspace-slice';
import NewLocationModal from './new-location-modal';

interface LocationsTableProps {
  locations: any[];
  handleActiveShape: (id: number) => void;
  locId: ILocation['id'] | null;
}

const LocationsTable = ({ locations, handleActiveShape, locId }: LocationsTableProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showNewLocationModal, setShowNewLocationModal] = useState(false);
  const [id, setId] = useState<number | null>(null);
  const defaultLocationId = useActiveWorkspaceSlice(
    (state) => state.workspace?.default_location_id,
  );

  const deleteLocation = useMutation(
    ({ id, newId }: { id: number; newId?: number }) => locationsService.deleteLocation(id, newId),
    {
      onMutate: (id) => {
        queryClient.setQueryData([locationsService.locationsQueryKey], (old: any[] | undefined) => {
          return old ? old.filter((ws) => ws.id !== id) : undefined;
        });
      },
      onSuccess: (data, id) => {
        toast.success(`Location Deleted Successfuly.`);
      },
      onError: (err: AxiosError<Error>) => {
        customToastError('Error', `${err.response?.data.message}`);
        if (err.response?.status === 406) {
          handleOpenNewLocation();
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries([locationsService.locationsQueryKey]);
      },
    },
  );

  const handleOpenConfirmation = () => {
    setShowConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleOpenNewLocation = () => {
    setShowNewLocationModal(true);
  };

  const handleCloseNewLocation = (bool: boolean, newId?: number) => {
    setShowNewLocationModal(false);
    if (bool && newId) {
      deleteLocation.mutate({ id: id!, newId });
    }
  };

  const handleConfirmation = (bool: boolean) => {
    if (bool && id) {
      if (id === defaultLocationId) {
        customToastError('Error', `Sorry, you can't delete Headquarter`);
      } else {
        deleteLocation.mutate({ id });
      }
    }
  };

  const handleDelete = (locationId: number) => {
    setId(locationId);
    handleOpenConfirmation();
  };

  const handleEditLocation = (locationId: number) => {
    navigate(`../new-location/${locationId}`);
  };

  // if (!locations.length) {
  //   return null;
  // }

  return (
    <>
      <div className="flex flex-col w-full">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          <div className="flex flex-col">
            <div className="grid grid-cols-12 bg-gray-50">
              <div className="flex items-center col-span-6 pl-4 text-sm font-semibold text-center text-gray-900 md:col-span-4 justify-left h-14">
                Title
              </div>
              <div className="items-center justify-center hidden text-sm font-semibold text-center text-gray-900 col-span-0 md:col-span-4 md:flex h-14">
                Date
              </div>
              <div className="flex items-center justify-center col-span-6 text-sm font-semibold text-center text-gray-900 md:col-span-4 h-14">
                Actions
              </div>
            </div>
            <div className="flex flex-col w-full divide-y-2 divide-gray-100">
              {locations.map((location) => (
                <div key={location.id} className="grid grid-cols-12 text-sm font-medium h-14">
                  <div className="flex items-center col-span-6 pl-4 md:col-span-4 justify-left">
                    <span className="capitalize line-clamp-1">{location?.title}</span>
                  </div>
                  <div className="items-center justify-center hidden col-span-0 md:col-span-4 md:flex">
                    <span className="line-clamp-1">{formatDate(location?.created_at)}</span>
                  </div>
                  <div className="flex items-center justify-center col-span-6 space-x-2 md:col-span-4">
                    <button
                      type="button"
                      className=""
                      disabled={deleteLocation.isLoading}
                      onClick={() => handleDelete(location.id)}
                    >
                      <TrashIcon className="w-6 h-6 text-gray-400 transition hover:text-gray-800" />
                    </button>
                    <button
                      type="button"
                      className=""
                      disabled={deleteLocation.isLoading}
                      onClick={() => handleEditLocation(location.id)}
                    >
                      <PencilIcon
                        className={`w-6 h-6 text-gray-400 transition hover:text-gray-800`}
                      />
                    </button>
                    <button
                      type="button"
                      className=""
                      onClick={() => handleActiveShape(location.id)}
                    >
                      <PinIcon
                        className={`w-6 h-6 text-gray-400 transition hover:text-gray-800 ${
                          locId === location.id ? 'text-gray-800' : ''
                        }`}
                      />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Confirmation
        show={showConfirmation}
        closeModal={handleCloseConfirmation}
        confirm={handleConfirmation}
      />
      <NewLocationModal show={showNewLocationModal} closeModal={handleCloseNewLocation} id={id} />
    </>
  );
};

export default LocationsTable;
