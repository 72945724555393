import { Dashboard, DashboardParams } from '../interfaces/dashboard-interface';
import { Workspace, WorkspaceRequest } from '../interfaces/workspace-interface';
import { useActiveWorkspaceSlice } from '../store/active-workspace-slice';
import http from './axios-instance';

interface Res {
  message: string;
  status: string;
}

const dashboardQueryKey = 'Dashboard';
const getDashboard = async (params: DashboardParams): Promise<Dashboard> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/workspace/dashboard`;
  const res = await http.get(url, { headers, params });
  return res.data;
};

const workspacesQueryKey = 'Workspaces';
const getWorkspaces = async (): Promise<Workspace[]> => {
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/workspace/get`;
  const res = await http.get(url);
  return res.data;
};

const createWorkspace = async (data: WorkspaceRequest): Promise<Workspace> => {
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/workspace/create`;
  const res = await http.post(url, data);
  return res.data;
};

const verifyWorkspace = async ({
  otp,
  workSpaceSubDomain,
}: {
  otp: string;
  workSpaceSubDomain: string;
}): Promise<Res> => {
  const data = {
    otp,
    workSpaceSubDomain,
  };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/workspace/verify`;
  const res = await http.post(url, data);
  return res.data;
};

const sendOtpWorkspace = async (workSpaceSubDomain: string): Promise<Res> => {
  const data = {
    workSpaceSubDomain,
  };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/workspace/sendOtp`;
  const res = await http.post(url, data);
  return res.data;
};

const updateWorkspace = async (params: Partial<Workspace>): Promise<Workspace> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/workspace/edit`;
  const res = await http.put(url, params, { headers });
  return res.data;
};

const deleteWorkspace = async (): Promise<any> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/workspace/delete`;
  const res = await http.delete(url, { headers });
  return res.data;
};

const stickyNote = async (data: { note: string }): Promise<Workspace> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/workspace/sticky_note`;
  const res = await http.post(url, data, { headers });
  return res.data;
};

const checkSubDomain = async (data: { sub_domain: string | undefined }): Promise<boolean> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/workspace/check_subdomain`;
  const res = await http.post(url, data, { headers });
  return res.data;
};

const workspaceService = {
  workspacesQueryKey,
  getWorkspaces,
  createWorkspace,
  verifyWorkspace,
  sendOtpWorkspace,
  updateWorkspace,
  deleteWorkspace,
  stickyNote,
  dashboardQueryKey,
  getDashboard,
  checkSubDomain,
};

export default workspaceService;
