import { Link } from 'react-router-dom';

interface EmptyStateCalendarProps {
  path: string;
  buttonName: string;
  message: string;
  extraMessage?: string;
  showBtn?: boolean;
  showImg?: boolean;
  videoUrl?: string;
  videoTitle?: string;
}

const EmptyStateCalendar = ({
  path,
  buttonName,
  message,
  extraMessage,
  showBtn = true,
  videoUrl = '',
}: EmptyStateCalendarProps) => {
  return (
    <>
      <div className="flex flex-col items-center justify-center min-h-full py-4 mx-auto overflow-y-auto max-w-7xl">
        <h1 className="max-w-3xl text-2xl font-semibold text-center text-gray-600">{message}</h1>
        <div className="flex items-center justify-center w-full max-w-3xl mt-6">
          {showBtn ? (
            <Link
              to={path}
              type="button"
              className="flex items-center justify-center text-sm font-medium text-white transition rounded shadow shrink-0 h-14 w-52 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500"
            >
              {buttonName}
            </Link>
          ) : null}
        </div>
        {/* ring-2 ring-offset-4 ring-[#592d88] */}
        <div className="flex items-center justify-between w-full max-w-3xl p-4 my-6 bg-white rounded shadow-lg">
          <video width="100%" height="350" controls autoPlay muted>
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        {extraMessage ? (
          <p className="w-full max-w-3xl mt-6 text-center text-gray-400 ">{extraMessage}</p>
        ) : null}
      </div>
    </>
  );
};

export default EmptyStateCalendar;
