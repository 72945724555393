import { useState } from 'react';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTitle } from 'react-use';
import Spinner from '../../components/spinner';
import { endOfDay, isAfter, subDays } from 'date-fns';
import { formatDateToTimeZone, utcDate } from '../../utils/format-date';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useStaffWorkTimeReport } from '../../hooks/use-staff-worktime-report';
import { CSVLink } from 'react-csv';
import { useActiveWorkspaceSlice } from '../../store/active-workspace-slice';
import { usePrintWorkTimeSlice } from '../../store/print-worktime-slice';
import { useReportLock } from '../../hooks/use-report-lock';
import { toast } from 'react-hot-toast';
import Confirmation from '../../components/libs/confirmation';
import { matchSorter } from 'match-sorter';

const StaffsWorkTimeReport = () => {
  useTitle(`${import.meta.env.VITE_APP_TITLE} | Staffs Worktime`);
  const [searchParams, setSearchParams] = useSearchParams();
  const startParam = searchParams.get('start');
  const endParam = searchParams.get('end');
  const navigate = useNavigate();
  const reportLock = useReportLock({
    onSuccess: (data) => {
      toast.success(data.message);
    },
  });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const workSpaceSubDomain = useActiveWorkspaceSlice((state) => state.workspace?.sub_domain)!;
  const [startDate, setStartDate] = useState<Date>(
    startParam ? new Date(+startParam) : subDays(new Date(), 7),
  );
  const [endDate, setEndDate] = useState<Date>(
    endParam ? new Date(+endParam) : endOfDay(new Date()),
  );
  const from = startDate ? formatDateToTimeZone(startDate, 'y-MM-dd') : null;
  const to = endDate ? formatDateToTimeZone(endDate, 'y-MM-dd') : null;
  const staffWorkTimeReport = useStaffWorkTimeReport(workSpaceSubDomain, from, to);
  const updatePrintWorkTimeData = usePrintWorkTimeSlice((state) => state.updateData);
  const filteredReports = matchSorter(staffWorkTimeReport.data ?? [], searchTerm, {
    keys: ['staff_name', 'staff_job'],
  });

  const handleShowDetails = (id: number) => {
    navigate(`./${id}?from=${from}&to=${to}`);
  };

  const handleShowDetailsV2 = (id: number) => {
    navigate(`./simple/${id}?from=${from}&to=${to}`);
  };

  const handlePrint = () => {
    updatePrintWorkTimeData(filteredReports);
    navigate('/print-worktime');
  };

  const handleLock = () => {
    if (startDate && endDate) {
      // const from = utcDate(startDate).getTime();
      const to = utcDate(endDate).getTime();
      reportLock.mutate({ to });
    }
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleConfirmation = (bool: boolean) => {
    if (bool && !reportLock.isLoading) {
      handleLock();
      return;
    }
  };

  return (
    <>
      <div className="w-full mx-auto space-y-0 pb-14 print:p-0 print:max-w-none print:w-full">
        <div className="flex flex-col items-center justify-center w-full bg-white border border-gray-100 rounded-md shadow-lg p-9 print:shadow-none print:p-0 print:border-none">
          <div className="flex items-center justify-between w-full pb-6 mb-6 font-medium border-b print:hidden print:pb-4 print:mb-0 print:border-b print:border-b-gray-200">
            <div className="flex flex-col w-full font-medium">
              <span>Staff Work Time</span>
              <p className="mt-2 text-sm font-normal text-gray-400">
                A detailed report which gives you the tool to measure staff productivity, including
                employees’ hours at work, overtime, breaks and time off.
              </p>
              <div className="flex flex-col w-full mt-6 space-y-8 font-medium md:space-y-0 md:space-x-4 md:flex-row md:items-center md:justify-between">
                <div className="flex items-center space-x-4">
                  <CSVLink
                    data={filteredReports.map((report) => {
                      return {
                        Name: report.staff_name,
                        Job: report.staff_job,
                        Scheduled: report.schedules_duration,
                        Worked: report.clocks_duration,
                        'Used Time Off': report.total_leave_requests_duration,
                        Owed: report.owed_schedules_duration,
                        Extra: report.extra_schedules_duration,
                        'Used Breaks': report.clocks_breaks_duration,
                        'Total Break': report.schedules_breaks_duration,
                        'Unscheduled Working Time': report.un_schedules_duration,
                      };
                    })}
                    filename={`${from}-${to}.csv`}
                    className="flex items-center justify-center h-12 text-white transition duration-200 rounded w-28 bg-brand-primary hover:bg-brand-primary-dark"
                    target="_blank"
                  >
                    Export
                  </CSVLink>
                  <button
                    className="items-center justify-center hidden h-12 text-white transition duration-200 rounded md:flex w-28 bg-brand-primary hover:bg-brand-primary-dark"
                    type="button"
                    onClick={handlePrint}
                  >
                    Print
                  </button>
                </div>
                <div className="flex flex-col space-y-4 md:space-y-0 md:space-x-4 md:flex-row md:items-center">
                  <TextField
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <DatePicker
                    label="Start date"
                    value={startDate}
                    disableMaskedInput
                    inputFormat="dd MMM y"
                    onChange={(newValue) => {
                      if (newValue) {
                        searchParams.delete('start');
                        searchParams.append('start', new Date(newValue).getTime().toString());
                        setSearchParams(searchParams);
                        setStartDate(newValue);
                      }
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <DatePicker
                    label="End date"
                    value={endDate}
                    disableMaskedInput
                    inputFormat="dd MMM y"
                    onChange={(newValue) => {
                      if (newValue && startDate && isAfter(newValue, startDate)) {
                        const endD = endOfDay(newValue);
                        searchParams.delete('end');
                        searchParams.append('end', endD.getTime().toString());
                        setSearchParams(searchParams);
                        setEndDate(endD);
                      }
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </div>
              </div>
            </div>
          </div>
          {staffWorkTimeReport.isLoading ? (
            <div className="flex items-end justify-center w-full my-9">
              <span className="mr-4">Please wait</span>
              <Spinner size="small" />
            </div>
          ) : (
            <div className="flex flex-col w-full">
              <div className="grid w-full h-12 grid-cols-12 text-xs font-medium text-gray-700 bg-gray-100 rounded print:text-[8px] print:grid-cols-11 print:border-b print:border-b-gray-200">
                <div className="flex items-center justify-center h-full col-span-4 text-center md:flex md:col-span-2">
                  <span className="line-clamp-2" title="Name">
                    Name
                  </span>
                </div>
                <div className="items-center justify-center hidden h-full col-span-1 text-center md:flex">
                  <span className="line-clamp-2" title="Job">
                    Job
                  </span>
                </div>
                <div className="items-center justify-center hidden h-full col-span-1 text-center md:flex">
                  <span className="line-clamp-2" title="Scheduled">
                    Scheduled
                  </span>
                </div>
                <div className="flex items-center justify-center h-full col-span-4 text-center md:flex md:col-span-1">
                  <span className="line-clamp-2" title="Worked">
                    Worked
                  </span>
                </div>
                <div className="items-center justify-center hidden h-full col-span-1 text-center md:flex">
                  <span className="line-clamp-2" title="Used Time Off">
                    Used Time Off
                  </span>
                </div>
                <div className="items-center justify-center hidden h-full col-span-1 text-center md:flex">
                  <span className="line-clamp-2" title="Owed">
                    Owed
                  </span>
                </div>
                <div className="items-center justify-center hidden h-full col-span-1 text-center md:flex">
                  <span className="line-clamp-2" title="Extra">
                    Extra
                  </span>
                </div>
                <div className="items-center justify-center hidden h-full col-span-1 text-center md:flex">
                  <span className="line-clamp-2" title="Used Breaks">
                    Used Breaks
                  </span>
                </div>
                <div className="items-center justify-center hidden h-full col-span-1 text-center md:flex">
                  <span className="line-clamp-2" title="Total Break">
                    Total Break
                  </span>
                </div>
                <div className="items-center justify-center hidden h-full col-span-1 text-center md:flex">
                  <span className="line-clamp-2" title="Unscheduled Working Time">
                    Unscheduled Working Time
                  </span>
                </div>
                {/* <div className="items-center justify-center hidden h-full col-span-1 text-center md:flex">
                  <span className="line-clamp-2" title="Total Time Off">
                    Total Time Off
                  </span>
                </div> */}
                <div className="flex items-center justify-center h-full col-span-4 text-center md:flex md:col-span-1">
                  <span className="line-clamp-2" title="Actions">
                    Actions
                  </span>
                </div>
              </div>
              <div className="flex flex-col w-full divide-y-[1px] divide-gray-100">
                {filteredReports.map((report, i) => {
                  return (
                    <div
                      key={`${report.staff_id}-${i}`}
                      className="grid w-full grid-cols-12 text-xs print:text-[8px] print:grid-cols-11 font-medium text-gray-700 rounded h-20 print:h-10"
                    >
                      <div className="flex items-center justify-center h-full col-span-4 text-center capitalize md:col-span-2 md:flex line-clamp-2">
                        {report.staff_name}
                      </div>
                      <div className="items-center justify-center hidden h-full col-span-1 text-center capitalize md:flex line-clamp-2">
                        {report.staff_job}
                      </div>
                      <div className="items-center justify-center hidden h-full col-span-1 text-center capitalize md:flex line-clamp-2">
                        {report.schedules_duration}
                      </div>
                      <div className="flex items-center justify-center h-full col-span-4 text-center capitalize md:col-span-1 md:flex line-clamp-2">
                        {report.clocks_duration}
                      </div>
                      <div className="items-center justify-center hidden h-full col-span-1 text-center capitalize md:flex line-clamp-2">
                        {report.total_leave_requests_duration}
                      </div>
                      <div className="items-center justify-center hidden h-full col-span-1 text-center capitalize md:flex line-clamp-2">
                        {report.owed_schedules_duration}
                      </div>
                      <div className="items-center justify-center hidden h-full col-span-1 text-center capitalize md:flex line-clamp-2">
                        {report.extra_schedules_duration}
                      </div>
                      <div className="items-center justify-center hidden h-full col-span-1 text-center capitalize md:flex line-clamp-2">
                        {report.clocks_breaks_duration}
                      </div>
                      <div className="items-center justify-center hidden h-full col-span-1 text-center capitalize md:flex line-clamp-2">
                        {report.schedules_breaks_duration}
                      </div>
                      <div className="items-center justify-center hidden h-full col-span-1 text-center capitalize md:flex line-clamp-2">
                        {report.un_schedules_duration}
                      </div>
                      <div className="flex flex-col items-center justify-around h-full col-span-4 text-center md:col-span-1 md:flex line-clamp-2capitalize print:hidden">
                        <button
                          type="button"
                          className="w-full h-8 transition duration-200 bg-gray-100 rounded line-clamp-1 hover:bg-gray-200"
                          title="Details"
                          disabled={false}
                          onClick={() => handleShowDetails(report.staff_id)}
                        >
                          <span className="text-[11px] font-medium text-gray-500 line-clamp-2 leading-3">
                            Details
                          </span>
                        </button>
                        <button
                          type="button"
                          className="w-full h-8 transition duration-200 bg-gray-100 rounded line-clamp-1 hover:bg-gray-200"
                          title="Clock-in/out History"
                          disabled={false}
                          onClick={() => handleShowDetailsV2(report.staff_id)}
                        >
                          <span className="text-[11px] font-medium text-gray-500 line-clamp-2 leading-3">
                            Clock-in/out History
                          </span>
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
      <Confirmation
        show={showConfirmation}
        closeModal={handleCloseConfirmation}
        confirm={handleConfirmation}
        title={`Are you sure you want to proceed and lock?`}
        description={
          <span className="mt-1 text-base text-gray-500">
            Once locked, any modifications to records prior to{' '}
            <b className="inline-block">{formatDateToTimeZone(endDate, 'dd-MM-y')}</b> will be
            permanently disabled.
          </span>
        }
      />
    </>
  );
};

export default StaffsWorkTimeReport;
