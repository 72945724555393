import Dialog from '@mui/material/Dialog';
import { useCalendarSlice } from '../../../store/calendar-slice';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Calendar } from '../../../interfaces/calendar-interface';
import toast from 'react-hot-toast';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useCopyCalendar } from '../../../hooks/use-copy-calendar';

interface IForm {
  type: string;
}

interface CalendarCopyModalProps {
  schedules: Record<string, Calendar[]>;
  from: number;
  to: number;
}

const schema = yup
  .object({
    type: yup.string().required(),
  })
  .required();

const CalendarCopyModal = ({ schedules, from, to }: CalendarCopyModalProps) => {
  const show = useCalendarSlice((state) => state.showCopyModal);
  const copyCalendar = useCopyCalendar({
    onSuccess() {
      toast.success('Copied successfully.');
      handleCloseModal();
    },
  });
  const updateShowCopyModal = useCalendarSlice((state) => state.updateShowCopyModal);
  const { handleSubmit, control, reset } = useForm<IForm>({
    defaultValues: {
      type: 'single',
    },
    resolver: yupResolver<any>(schema),
  });

  const onSubmit: SubmitHandler<IForm> = (data) => {
    if (copyCalendar.isLoading) {
      return;
    }
    if (data.type === 'single') {
      copyToNextWeek();
    } else {
      // copyToOtherWeeks();
    }
  };

  const copyToNextWeek = () => {
    const staffIds = Object.keys(schedules).map((k) => +k);
    copyCalendar.mutate({ from, to, staff_ids: staffIds });
  };

  const handleCloseModal = () => {
    reset();
    updateShowCopyModal(false);
  };

  return (
    <Dialog onClose={handleCloseModal} open={show} fullWidth={true} maxWidth={'sm'}>
      <div className="flex flex-col w-full p-6">
        <div className="flex items-center justify-between pb-6 mb-6 text-lg font-medium leading-6 text-gray-900 border-b">
          <span>Copy Scheduled Shifts</span>
          <button
            type="button"
            onClick={handleCloseModal}
            className="transition border border-transparent rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            <XMarkIcon className="w-6 h-6" />
          </button>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-6">
          <div>
            <FormControl>
              <FormLabel id="copy-radio">
                <span className="text-sm font-medium">Select one to continue</span>
              </FormLabel>
              <Controller
                rules={{ required: true }}
                control={control}
                name="type"
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    aria-labelledby="copy-radio"
                    defaultValue="single"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="single"
                      control={<Radio />}
                      label={<span className="text-sm font-medium">Copy to Next Week</span>}
                    />
                    {/* <FormControlLabel
                      value="multiple"
                      control={<Radio />}
                      label={<span className="text-sm font-medium">Copy to Multiple Weeks</span>}
                    />
                    <FormControlLabel
                      value="employee"
                      control={<Radio />}
                      label={<span className="text-sm font-medium">Copy from Employee</span>}
                    /> */}
                  </RadioGroup>
                )}
              />
            </FormControl>
          </div>
          <div className="flex items-center justify-end w-full pt-6 h-[72px] mt-6 border-t">
            <button
              type="submit"
              className="h-10 text-sm tracking-wide text-white transition bg-indigo-500 border border-transparent rounded shadow-sm w-28 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Next
            </button>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default CalendarCopyModal;
