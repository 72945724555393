import { XMarkIcon } from '@heroicons/react/24/outline';
import Dialog from '@mui/material/Dialog';

export type ChangeStatusModelContext = 'CLOSE' | 'PENDING' | 'ACCEPT' | 'REJECT';

interface ChangeStatusModalProps {
  show: boolean;
  status?: string;
  closeModal: (ctx: ChangeStatusModelContext) => void;
  title?: string;
  description?: string;
}

const ChangeStatusModal = ({
  show,
  status,
  closeModal,
  title = 'Are you sure about this?',
  description = '',
}: ChangeStatusModalProps) => {
  const handleClose = (ctx: ChangeStatusModelContext) => {
    closeModal(ctx);
  };

  return (
    <Dialog onClose={() => handleClose('CLOSE')} open={show} fullWidth={false} maxWidth={'sm'}>
      <div className="flex flex-col max-w-full min-w-[400px] p-6">
        <div className="flex items-center justify-between text-lg font-medium leading-6 text-gray-900 capitalize">
          <div className="flex flex-col">
            <span className="leading-9">{title}</span>
            {description ? <span className="mt-1 text-base text-gray-500">{description}</span> : ''}
          </div>
          <button
            type="button"
            onClick={() => handleClose('CLOSE')}
            className="ml-6 transition border border-transparent rounded shadow-sm self-baseline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          >
            <XMarkIcon className="w-6 h-6" />
          </button>
        </div>
        <div className="flex items-center justify-end w-full mx-6 space-x-4 pt-9 md:mx-0">
          {/* <button
            type="button"
            onClick={() => handleClose('REJECT')}
            className="h-10 ml-auto text-sm tracking-wide text-white transition bg-orange-500 border border-transparent rounded shadow-sm w-28 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
          >
            Reject
          </button>
          <button
            type="button"
            onClick={() => handleClose('PENDING')}
            className="h-10 ml-auto text-sm tracking-wide text-white transition bg-orange-500 border border-transparent rounded shadow-sm w-28 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
          >
            Pending
          </button> */}
          <button
            type="button"
            onClick={() => handleClose('ACCEPT')}
            className="h-10 ml-auto text-sm tracking-wide text-white transition bg-indigo-500 border border-transparent rounded shadow-sm w-28 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Accept
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default ChangeStatusModal;
