import { useQuery } from '@tanstack/react-query';
import staffsService from '../services/staffs-service';
import { useActiveWorkspaceSlice } from '../store/active-workspace-slice';

export function useArchivedEmployees() {
  const activeWorkspace = useActiveWorkspaceSlice((state) => state.workspace);

  return useQuery(
    [staffsService.archivedStaffsQueryKey, activeWorkspace?.id],
    staffsService.getArchivedStaffs,
    {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 3,
    },
  );
}
