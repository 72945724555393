import { differenceInMinutes, millisecondsToMinutes } from 'date-fns';
import { StaffClockInReport } from '../interfaces/clockin-reports-interface';

export const calcTimes = (clockInReport: StaffClockInReport[] | null) => {
  let availableWtMins = 0;
  let availableBrsMins = 0;
  let wtMins = 0;
  let brMins = 0;
  let customAvailableWtMins = 0;
  let customWtMins = 0;
  let customBrMins = 0;
  let madeBySupervisor = 0;
  let madeByUser = 0;
  let scheduledUserBreaks = 0;
  let unScheduledUserBreaks = 0;
  clockInReport?.forEach((cr) => {
    if (!cr.built_by_staff) {
      madeBySupervisor = madeBySupervisor + 1;
      availableWtMins = availableWtMins + differenceInMinutes(cr?.end_at, cr?.start_at);
      cr?.breaks?.forEach((b) => {
        let duration = 0;
        if (b.type === 'float') {
          duration = millisecondsToMinutes(b.totall);
        } else {
          duration = differenceInMinutes((b.start_at ?? 0) + b.totall, b.start_at ?? 0);
        }
        availableBrsMins = availableBrsMins + duration;
      });
      cr?.clocks?.forEach((c) => {
        wtMins =
          wtMins + (c.clock_in && c.clock_out ? differenceInMinutes(c.clock_out, c.clock_in) : 0);
        c.breaks?.forEach((b) => {
          brMins = brMins + (b.start && b.end ? differenceInMinutes(b.end, b.start) : 0);
        });
        scheduledUserBreaks = scheduledUserBreaks + (c.breaks?.length ?? 0);
      });
    } else {
      madeByUser = madeByUser + 1;
      customAvailableWtMins = customAvailableWtMins + differenceInMinutes(cr?.end_at, cr?.start_at);
      cr?.clocks?.forEach((c) => {
        customWtMins =
          customWtMins +
          (c.clock_in && c.clock_out ? differenceInMinutes(c.clock_out, c.clock_in) : 0);
        c.breaks?.forEach((b) => {
          customBrMins =
            customBrMins + (b.start && b.end ? differenceInMinutes(b.end, b.start) : 0);
        });
        unScheduledUserBreaks = unScheduledUserBreaks + (c.breaks?.length ?? 0);
      });
    }
  });
  const mustWorkMins = availableWtMins - availableBrsMins;
  const usedBreakMins = availableBrsMins - brMins;
  const actuallyWorked = wtMins - brMins;
  const actuallyCustomWorked = customWtMins - customBrMins;
  return {
    availableWtMins,
    customAvailableWtMins,
    mustWorkMins,
    usedBreakMins,
    actuallyWorked,
    brMins,
    customWtMins,
    customBrMins,
    madeBySupervisor,
    madeByUser,
    actuallyCustomWorked,
    scheduledUserBreaks,
    unScheduledUserBreaks,
  };
};
