import http from './axios-instance';
import { useActiveWorkspaceSlice } from '../store/active-workspace-slice';
import { CalendarParams, Calendar } from '../interfaces/calendar-interface';
import {
  DuplicateWorkTimesRequest,
  UpdateWorktimeRequest,
  Worktime,
  WorktimeRequest,
} from '../interfaces/worktime-interface';
import { Break, BreakRequest, UpdateBreakRequest } from '../interfaces/break-interface';
import { LeaveReq, LeaveReqParams } from '../interfaces/calendar-leave-request-interface';
import { RejectScheduleAttendanceResponse } from '../interfaces/reject-schedule-attendance';
import { CopyCalendarRequest } from '../interfaces/copy-calendar-request';
import { CopyCalendarResponse } from '../interfaces/copy-calendar-response';
import { CopyScheduleOfCalendarRequest } from '../interfaces/copy-schedule-of-calendar-request';
import { CopyScheduleOfCalendarResponse } from '../interfaces/copy-schedule-of-calendar-response';

export type WorktimeDeleteType = 'CALENDAR' | 'ATTENDANCE';

const workingTimesQueryKey = 'WorkingTimes';
const getCalendarWorkingTimes = async (
  params: CalendarParams,
): Promise<Record<string, Calendar[]>> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/schedule/get_object_calendar`;
  const res = await http.get(url, { headers, params });
  return res.data;
};

const createWorktime = async (data: WorktimeRequest): Promise<Worktime> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/schedule/create_object`;
  const res = await http.post(url, data, { headers });
  return res.data;
};

const updateWorktime = async (
  params: UpdateWorktimeRequest,
  type: WorktimeDeleteType,
): Promise<RejectScheduleAttendanceResponse> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/schedule/${
    type === 'CALENDAR' ? 'edit_object_calendar' : 'edit_object_attendance'
  }`;
  const res = await http.patch(url, null, { headers, params });
  return res.data;
};

const deleteWorktime = async (id: number, type: WorktimeDeleteType): Promise<any> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const params = { working_object_id: id };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/schedule/${
    type === 'CALENDAR' ? 'delete_object_calendar' : 'delete_object_attendance'
  }`;
  const res = await http.delete(url, { headers, params });
  return res.data;
};

const deleteAllWorktimes = async (ids: number[]): Promise<any> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const params = { working_object_ids: ids };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/schedule/delete_multi_object`;
  const res = await http.delete(url, { headers, params });
  return res.data;
};

const createBreak = async (data: BreakRequest): Promise<Break> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/schedule/create_break`;
  const res = await http.post(url, data, { headers });
  return res.data;
};

const updateBreak = async (params: UpdateBreakRequest): Promise<Break> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/schedule/edit_break`;
  const res = await http.patch(url, null, { headers, params });
  return res.data;
};

const deleteBreak = async (id: Break['id']): Promise<any> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const params = { break_id: id };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/schedule/delete_break`;
  const res = await http.delete(url, { headers, params });
  return res.data;
};

const createMultiWorktime = async (data: DuplicateWorkTimesRequest): Promise<any> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/schedule/create_multi_object`;
  const res = await http.post(url, data, { headers });
  return res.data;
};

const copyCalendar = async (data: CopyCalendarRequest): Promise<CopyCalendarResponse> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/schedule/copy_objects_calendar`;
  const res = await http.post(url, data, { headers });
  return res.data;
};

const copyScheduleOfCalendar = async (
  data: CopyScheduleOfCalendarRequest,
): Promise<CopyScheduleOfCalendarResponse> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${
    import.meta.env.VITE_BASE_URL
  }/api/v1/merchant/schedule/copy_one_day_objects_calendar`;
  const res = await http.post(url, data, { headers });
  return res.data;
};

const leaveRequestsQueryKey = 'CalendarLeaveRequests';
const getLeaveRequests = async (params: LeaveReqParams): Promise<LeaveReq[]> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/schedule/get_leave`;
  const res = await http.get(url, { headers, params });
  return res.data;
};

const calendarService = {
  workingTimesQueryKey,
  getCalendarWorkingTimes,
  createWorktime,
  updateWorktime,
  deleteWorktime,
  createBreak,
  updateBreak,
  deleteBreak,
  createMultiWorktime,
  leaveRequestsQueryKey,
  getLeaveRequests,
  deleteAllWorktimes,
  copyCalendar,
  copyScheduleOfCalendar,
};

export default calendarService;
