import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import clockService from '../services/clock-service';
import reportsService from '../services/reports-service';
import { customToastError } from '../utils/custom-toast-error';
import {
  ChangeClockStatusRequest,
  ChangeClockStatusResponse,
} from '../interfaces/change-clock-status';

export function useChangeClockStatus(
  configOptions: UseMutationOptions<
    ChangeClockStatusResponse,
    any,
    ChangeClockStatusRequest,
    any
  > = {},
) {
  const queryClient = useQueryClient();
  return useMutation((body: ChangeClockStatusRequest) => clockService.changeClockStatus(body), {
    onError: (data: AxiosError) => {
      const err = data.response?.data as any;
      customToastError('Error', err?.message ?? 'Sorry there was a problem');
    },
    onSettled: () => {
      queryClient.invalidateQueries([reportsService.clockInReportsQueryKey]);
    },
    ...configOptions,
  });
}
