import { useNavigate, useParams } from 'react-router-dom';
import { capitalizeFirstLetter } from '../../utils/capitalize-first-letter';
import { useEmployees } from '../../hooks/use-employees';
import Spinner from '../../components/spinner';
import PrevPage from '../../components/ui/prev-page';
import { formatDateToTimeZone } from '../../utils/format-date';
import { useArchivedEmployees } from '../../hooks/use-archived-employees';

const EmployeeDetails = () => {
  const { id } = useParams();
  const { data: employees = [] } = useEmployees();
  const { data: archivedEmployees = [] } = useArchivedEmployees();
  const allEmp = [...employees, ...archivedEmployees];
  const employee = id ? allEmp.find((e) => e.id === +id) : null;

  if (!employee) {
    return (
      <div className="flex items-end justify-center w-full my-9">
        <span className="mr-4">Please wait</span>
        <Spinner size="small" />
      </div>
    );
  }

  return (
    <div className="mx-auto space-y-8 max-w-7xl">
      <PrevPage to="../" />
      <div className="flex flex-col items-center justify-center w-full bg-white border border-gray-100 rounded-md shadow-lg p-9">
        <div className="flex items-center justify-between w-full pb-6 font-medium border-b">
          <span>Employee Details</span>
          <div className="flex-shrink-0 w-10 h-10 bg-green-500 rounded-full">
            {employee.attachment && (
              <img
                className="w-10 h-10 rounded-full"
                src={employee.attachment}
                alt={employee.first_name}
              />
            )}
          </div>
        </div>
        <div className="grid w-full grid-cols-12 gap-6 mt-8">
          <div className="flex flex-col col-span-12 space-y-2 sm:col-span-6">
            <span className="px-4 py-2 font-medium rounded bg-gray-50">Name</span>
            <span className="px-4 py-2">
              {capitalizeFirstLetter(`${employee?.first_name} ${employee?.last_name}`)}
            </span>
          </div>
          <div className="flex flex-col col-span-12 space-y-2 sm:col-span-6">
            <span className="px-4 py-2 font-medium rounded bg-gray-50">Phone</span>
            <span className="px-4 py-2">{employee?.mobile}</span>
          </div>
          <div className="flex flex-col col-span-12 space-y-2 sm:col-span-6">
            <span className="px-4 py-2 font-medium rounded bg-gray-50">Email</span>
            <span className="px-4 py-2">{employee?.email}</span>
          </div>
          <div className="flex flex-col col-span-12 space-y-2 sm:col-span-6">
            <span className="px-4 py-2 font-medium rounded bg-gray-50">Job</span>
            <span className="px-4 py-2">{employee?.job.title}</span>
          </div>
          <div className="flex flex-col col-span-12 space-y-2 sm:col-span-6">
            <span className="px-4 py-2 font-medium rounded bg-gray-50">Type</span>
            <span className="px-4 py-2 capitalize">{employee?.employment_status}</span>
          </div>
          <div className="flex flex-col col-span-12 space-y-2 sm:col-span-6">
            <span className="px-4 py-2 font-medium rounded bg-gray-50">Start Date</span>
            <span className="px-4 py-2">
              {formatDateToTimeZone(employee?.official_start_date, 'dd-MM-y')}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeDetails;
