import { useLayoutEffect, useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import SideBar from './components/side-bar';

const Workspace = () => {
  const location = useLocation();
  const containerRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    // containerRef.current!.scrollTo(0, 0);
    containerRef.current!.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location.pathname]);

  return (
    <div className="flex flex-1 overflow-hidden">
      <div className="hidden lg:flex">
        <SideBar />
      </div>
      <main
        id="main-container"
        ref={containerRef}
        className="block w-full overflow-y-auto my-2 pl-2 md:my-9 md:pl-9 pr-[13px] mr-[13px] h-[calc(100%-16px)] md:h-[calc(100%-72px)] select-none"
        style={{
          scrollbarGutter: 'stable',
        }}
      >
        <Outlet />
      </main>
    </div>
  );
};

export default Workspace;
