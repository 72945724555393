import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTitle } from 'react-use';
import { useMutation, useQuery } from '@tanstack/react-query';
import { LoginRequest } from '../../interfaces/login-interface';
import Spinner from '../../components/spinner';
import FormError from '../../components/form-error';
import appSyncService from '../../services/app-sync-service';
import CheckIcon from '~icons/carbon/checkmark';
import { formatDate } from '../../utils/format-date';

type Step = 'LOGIN' | 'LIST' | 'SUCCESS';

const schema = yup
  .object({
    email: yup.string().email('Please enter a valid email').required('This field is required'),
    password: yup
      .string()
      .required('This field is required')
      .min(6, ({ min }) => `must be at least ${min} characters`),
  })
  .required();

const SyncWithTipping = () => {
  useTitle(`${import.meta.env.VITE_APP_TITLE} | App Sync`);
  const [step, setStep] = useState<Step>('LOGIN');
  const [token, setToken] = useState<string | null>(null);
  const { merchantId, appName, businessId } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginRequest>({
    resolver: yupResolver<any>(schema),
  });
  const appSyncLogin = useMutation(appSyncService.appSyncLogin, {
    onSuccess: (res) => {
      handleChangeStep('LIST');
      setToken(res.data.token);
    },
  });
  const appSyncAddBusiness = useMutation(appSyncService.appSyncAddBusiness, {
    onSuccess: (res) => {
      handleChangeStep('SUCCESS');
    },
  });

  const appSyncGetWorkspace = useQuery(
    ['App Sync WS List', token],
    () => appSyncService.appSyncGetWorkspace(token!),
    {
      enabled: Boolean(token),
      staleTime: 1000 * 60,
    },
  );

  const onSubmit: SubmitHandler<LoginRequest> = (data) => {
    if (!appName || !merchantId) {
      return;
    }
    appSyncLogin.mutate({ ...data, app_name: appName!, app_user_id: merchantId! });
  };

  const handleChangeStep = (step: Step) => {
    setStep(step);
  };

  if (step === 'LIST' && appSyncGetWorkspace.isLoading) {
    return (
      <div className="flex items-center justify-center p-9">
        <Spinner size="large" />
      </div>
    );
  }

  return (
    <>
      {(() => {
        switch (step) {
          case 'LOGIN':
            return (
              <section className="h-full min-h-screen gradient-form bg-neutral-700">
                <div className="flex flex-wrap items-center justify-center flex-1 h-full min-h-screen text-neutral-200 p-9">
                  <div className="flex-1 w-full max-w-5xl">
                    <div className="flex rounded-lg shadow-lg bg-neutral-800">
                      <div className="grid grid-cols-12 gap-x-4 p-9">
                        <div className="items-center justify-center hidden col-span-6 md:flex">
                          <img
                            src="https://tecdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.svg"
                            className="w-full"
                            alt="Phone image"
                          />
                          {/* <h4 className="pb-1 mt-1 mb-12 text-xl font-semibold">We are The Lotus Team</h4> */}
                        </div>
                        <form
                          onSubmit={handleSubmit(onSubmit)}
                          className="col-span-12 py-4 space-y-4 md:py-12 md:col-span-6"
                        >
                          <div className="">
                            <label
                              htmlFor="email"
                              className="block text-sm text-gray-100 capitalize"
                            >
                              Email address
                            </label>
                            <div className="mt-3">
                              <input
                                id="email"
                                autoComplete="email"
                                {...register('email')}
                                className={`block w-full px-4 h-11 placeholder-gray-500 text-gray-700 border-none bg-neutral-200 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-none-indigo-500 sm:text-sm ${
                                  errors.email?.message ? '' : ''
                                }`}
                              />
                            </div>
                            <p className="h-6 pt-2 text-sm text-red-500 first-letter:capitalize">
                              {errors.email?.message}
                            </p>
                          </div>
                          <div className="">
                            <label
                              htmlFor="password"
                              className="block text-sm text-gray-100 capitalize"
                            >
                              Password
                            </label>
                            <div className="mt-3">
                              <input
                                id="password"
                                type={'password'}
                                autoComplete="current-password"
                                {...register('password')}
                                className={`block w-full px-4 h-11 placeholder-gray-500 text-gray-700 border-none bg-neutral-200 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${
                                  errors.password?.message ? '' : ''
                                }`}
                              />
                            </div>
                            <p className="h-6 pt-2 text-sm text-red-500 first-letter:capitalize">
                              {errors.password?.message}
                            </p>
                          </div>
                          <div className="pt-4">
                            <button
                              disabled={appSyncLogin.isLoading}
                              type="submit"
                              className="flex items-center justify-center w-full text-sm font-medium text-white bg-indigo-600 border-none rounded-md shadow-sm h-11 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                              {!appSyncLogin.isLoading ? 'Sign in' : <Spinner size="small" />}
                            </button>
                          </div>
                          <FormError takeSpace={true} error={appSyncLogin.error} />
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            );
          case 'LIST':
            return (
              <section className="h-full min-h-screen gradient-form bg-blue-50">
                <div className="grid w-full grid-cols-12 gap-6 p-9">
                  {appSyncGetWorkspace.data?.map((ws) => {
                    return (
                      <div
                        key={ws.id}
                        className="flex flex-col col-span-12 p-6 overflow-hidden bg-white rounded shadow md:col-span-6 desktop:col-span-4"
                      >
                        <div className="capitalize border-b-[1px] border-b-gray-200 h-10 bg-white flex text-gray-700 text-sm font-medium">
                          {ws.title}
                        </div>
                        <div className="flex flex-col py-4 divide-y-[1px] divide-gray-200">
                          <div className="flex items-center justify-between text-sm text-gray-700 h-11">
                            <span>Total Staffs:</span>
                            <span>{ws?.staffCount ?? 0}</span>
                          </div>
                          <div className="flex items-center justify-between text-sm text-gray-700 h-11">
                            <span>SubDomain:</span>
                            <span>{ws.sub_domain}</span>
                          </div>
                          <div className="flex items-center justify-between text-sm text-gray-700 h-11">
                            <span>Created at:</span>
                            <span>{ws?.created_at ?? formatDate(new Date())}</span>
                          </div>
                        </div>
                        <div className="flex items-center w-full">
                          <button
                            disabled={appSyncAddBusiness.isLoading}
                            onClick={() =>
                              appSyncAddBusiness.mutate({
                                business_id: businessId!,
                                work_space_id: ws.id,
                                token: token!,
                              })
                            }
                            className="w-24 h-10 ml-auto text-sm font-medium text-white transition bg-indigo-500 rounded hover:bg-indigo-600"
                            type="button"
                          >
                            Select
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </section>
            );
          case 'SUCCESS':
            return (
              <section className="flex flex-col items-center justify-center h-full min-h-screen space-y-4 md:space-y-12 gradient-form bg-blue-50">
                <div className="p-6 border-2 border-blue-600 rounded-full md:border-4">
                  <CheckIcon className="w-12 h-12 text-blue-600 md:w-24 md:h-24" />
                </div>
                <h1 className="px-4 text-xl font-semibold text-center text-blue-500 capitalize md:text-6xl">
                  {appSyncAddBusiness.data?.message}
                </h1>
              </section>
            );
          default:
            return null;
        }
      })()}
    </>
  );
};
export default SyncWithTipping;
