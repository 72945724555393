import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { Tooltip } from '@mui/material';
import { addDays, format } from 'date-fns';
import { Calendar } from '../../../interfaces/calendar-interface';
import { useCalendarSlice } from '../../../store/calendar-slice';
import { Resource } from '../calendar-reports';
import CalendarTools from './calendar-tools';

interface CalendarToolbarProps {
  weekStartDate: Date;
  weekEndDate: Date;
  resources: Resource[];
  schedules: Record<string, Calendar[]>;
  employeesLoading: boolean;
  schedulesLoading: boolean;
}

const CalendarToolbar = ({
  weekStartDate,
  weekEndDate,
  resources,
  schedules,
  employeesLoading,
  schedulesLoading,
}: CalendarToolbarProps) => {
  const updateActiveDate = useCalendarSlice((state) => state.updateActiveDate);

  const handleToday = () => {
    updateActiveDate(new Date());
  };

  const handlePrevWeek = () => {
    updateActiveDate(addDays(weekStartDate, -1));
  };

  const handleNextWeek = () => {
    updateActiveDate(addDays(weekEndDate, 1));
  };

  return (
    <div className="flex items-center justify-between w-full pb-6 text-gray-600 item-center">
      <div className="flex items-center space-x-2">
        <button
          type="button"
          className="flex items-center justify-center w-24 h-10 text-white transition rounded bg-brand-primary"
          onClick={handleToday}
        >
          <span className="ml-[2px]">Today</span>
        </button>
        <Tooltip title="Previous">
          <button
            type="button"
            className="flex items-center justify-center w-12 h-10 text-white transition rounded bg-brand-primary"
            onClick={handlePrevWeek}
          >
            <ChevronLeftIcon className="w-6 h-6" />
          </button>
        </Tooltip>
        <Tooltip title="Next">
          <button
            type="button"
            className="flex items-center justify-center w-12 h-10 text-white transition rounded bg-brand-primary"
            onClick={handleNextWeek}
          >
            <ChevronRightIcon className="w-6 h-6" />
          </button>
        </Tooltip>
      </div>
      <h6 className="space-x-2 text-xl font-medium text-gray-600 line-clamp-1">
        {format(weekStartDate, 'dd LLL yyyy')}
        {' - '}
        {format(weekEndDate, 'dd LLL yyyy')}
      </h6>
      <CalendarTools
        loading={employeesLoading || schedulesLoading}
        schedules={schedules}
        resources={resources}
        weekStartDate={weekStartDate}
        weekEndDate={weekEndDate}
      />
    </div>
  );
};

export default CalendarToolbar;
