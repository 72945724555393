import React, { useEffect, useRef, useState } from 'react';
import { useDepartments } from '../../../hooks/use-departments';
import { useJobs } from '../../../hooks/use-jobs';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Popover } from '@mui/material';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useEmployee } from '../../../hooks/use-employee';

interface ExtraJob {
  depId: number;
  depName: string;
  active: boolean;
  isOpen: boolean;
  jobs: {
    jobId: number;
    jobName: string;
    active: boolean;
  }[];
}

export const calculateLength = (extraJob: ExtraJob[]) => {
  let num = 0;
  extraJob.forEach((d) => {
    d.jobs.forEach((j) => {
      if (j.active) {
        num = num + 1;
      }
    });
  });
  return num;
};

interface ExtraJobsProps {
  jobIds: number[];
  handleExtraJobsChange: (jobIds: number[]) => void;
}

const ExtraJobs = ({ handleExtraJobsChange }: ExtraJobsProps) => {
  const [searchParams] = useSearchParams();
  const employeeId = searchParams.get('employeeId');
  const { data: emp } = useEmployee(employeeId);
  const [extraJob, setExtraJob] = useState<ExtraJob[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { data: departments = [] } = useDepartments();
  const { data: jobs = [] } = useJobs();
  const open = Boolean(anchorEl);
  const jobsLength = calculateLength(extraJob);

  useEffect(() => {
    const list = departments.map((d) => {
      const js = jobs.flatMap((j) => {
        if (j.department_id === d.id) {
          return [
            {
              jobId: j.id,
              jobName: j.title,
              active: false,
            },
          ];
        }
        return [];
      });
      return {
        depId: d.id,
        depName: d.title,
        active: false,
        isOpen: false,
        jobs: js,
      };
    });
    setExtraJob(list);
  }, [departments, jobs]);

  useEffect(() => {
    if (emp?.job_ids.length) {
      setExtraJob((state) => {
        return state.map((d) => {
          let active = true;
          return {
            ...d,
            jobs: d.jobs.map((j) => {
              if (!emp?.job_ids.includes(j.jobId)) {
                active = false;
              }
              return { ...j, active: emp?.job_ids.includes(j.jobId) };
            }),
            active: !d.jobs.length ? false : active,
          };
        });
      });
    }
  }, [emp]);

  useEffect(() => {
    handleChange();
  }, [extraJob]);

  const handleChange = () => {
    const ids: number[] = [];
    extraJob.forEach((d) => {
      d.jobs.forEach((j) => {
        if (j.active) {
          ids.push(j.jobId);
        }
      });
    });
    handleExtraJobsChange(ids);
  };

  const handleALDepartment = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, id } = e.target as HTMLInputElement;
    const depId = +id.split('-')[0];
    setExtraJob((deps) => {
      return deps.map((dep) => {
        if (dep.depId === depId) {
          return {
            ...dep,
            active: checked,
            jobs: dep.jobs.map((job) => {
              return {
                ...job,
                active: checked,
              };
            }),
          };
        }
        return dep;
      });
    });
  };

  const handleALJob = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, id } = e.target as HTMLInputElement;
    const str = id.split('-');
    const depId = +str[0];
    const jobId = +str[1];
    setExtraJob((state) => {
      return state.map((d) => {
        if (d.depId === depId) {
          return {
            ...d,
            jobs: d.jobs.map((j) => {
              if (j.jobId === jobId) {
                return {
                  ...j,
                  active: checked,
                };
              }
              return j;
            }),
          };
        }
        return d;
      });
    });
  };

  const handleOpenAccessLevel = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAccessLevel = () => {
    setAnchorEl(null);
  };

  const handleToggleDepartment = (id: number) => {
    setExtraJob((deps) => {
      return deps.map((dep) => {
        if (dep.depId === id) {
          return {
            ...dep,
            isOpen: !dep.isOpen,
          };
        }
        return dep;
      });
    });
  };

  return (
    <>
      <label htmlFor="staffs" className="block text-sm font-medium text-gray-700">
        Extra Responsibilities
      </label>
      <div className="">
        <button
          type="button"
          onClick={handleOpenAccessLevel}
          className="w-40 h-10 text-sm font-medium text-white bg-indigo-500 border border-transparent rounded shadow-sm hover:bg-indigo-700"
        >
          {jobsLength === 1 ? `${jobsLength} Job Selected` : `${jobsLength} Jobs Selected`}
        </button>
        <Popover
          id="al"
          open={open}
          anchorEl={anchorEl}
          onClose={handleCloseAccessLevel}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div className="flex flex-col p-4 select-none">
            <div className="relative grid gap-2 bg-white w-[250px] max-h-[250px] overflow-y-auto divide-y divide-gray-100">
              {extraJob.map((dep) => {
                const depKey = `${dep.depId}-${dep.depName}`;
                return (
                  <React.Fragment key={depKey}>
                    <div className="flex items-center flex-1 p-2 mr-2 bg-gray-200">
                      <input
                        type="checkbox"
                        id={depKey}
                        checked={dep.active}
                        onChange={(e) => handleALDepartment(e)}
                        className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                      />
                      <label
                        htmlFor={depKey}
                        className="flex-1 block ml-4 mr-4 text-sm font-medium text-gray-700 capitalize"
                      >
                        {dep.depName}
                      </label>
                      <ChevronDownIcon
                        onClick={() => handleToggleDepartment(dep.depId)}
                        className={`cursor-pointer h-5 w-5 transition ${
                          !dep.isOpen ? 'rotate-180 transform' : ''
                        }`}
                      />
                    </div>
                    {dep.isOpen &&
                      dep.jobs.map((job) => {
                        const jobKey = `${dep.depId}-${job.jobId}-${job.jobName}`;
                        return (
                          <React.Fragment key={jobKey}>
                            <div className="flex items-center flex-1 p-2 pl-4 mr-2 bg-gray-100">
                              <input
                                type="checkbox"
                                id={jobKey}
                                checked={job.active}
                                onChange={(e) => handleALJob(e)}
                                className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                              />
                              <label
                                htmlFor={jobKey}
                                className="flex-1 block ml-4 text-sm font-medium text-gray-700 capitalize"
                              >
                                {job.jobName}
                              </label>
                            </div>
                          </React.Fragment>
                        );
                      })}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </Popover>
      </div>
    </>
  );
};

ExtraJobs.displayName = 'ExtraJob';

export default ExtraJobs;
