import { Fragment, useRef, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTitle } from 'react-use';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import Spinner from '../../components/spinner';
import FormError from '../../components/form-error';
import leaveService from '../../services/leave-service';
import { useLeaves } from '../../hooks/use-leaves';
import TimeOffTable from './components/time-off-table';
import { LeaveType as ILeaveType, LeaveTypeRequest } from '../../interfaces/leave-type-interface';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { hoursToMilliseconds, millisecondsToHours } from 'date-fns';
import { useEmployeeFormSlice } from '../../store/employee-form-slice';
import { useEmployees } from '../../hooks/use-employees';
import { Popover } from '@mui/material';
import { capitalizeFirstLetter } from '../../utils/capitalize-first-letter';

const schema = yup
  .object({
    title: yup.string().required('This field is required').min(3),
    duration: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required('This field is required'),
    total: yup
      .number()
      .positive()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required('This field is required'),
  })
  .required();

const formDefaultValues: LeaveTypeRequest = {
  title: '',
  duration: 2629800000,
  total: NaN,
  paid: false,
  staff_ids: [],
  // leave_group_id: NaN,
};

const TimeOff = () => {
  useTitle(`${import.meta.env.VITE_APP_TITLE} | Time off`);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const employeePath = searchParams.get('employeePath');
  const { data: leaveTypes, isLoading: leaveTypesLoading } = useLeaves();
  const [updateMode, setUpdateMode] = useState<ILeaveType | null>(null);
  const employeeForm = useEmployeeFormSlice((state) => state.employeeForm);
  const { data: employees = [] } = useEmployees();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [staffIds, setStaffIds] = useState<number[]>([]);
  const open = Boolean(anchorEl);
  const updateEmployeeForm = useEmployeeFormSlice((state) => state.updateEmployeeForm);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<LeaveTypeRequest>({
    defaultValues: formDefaultValues,
    resolver: yupResolver<any>(schema),
  });

  const {
    mutate: createLeaveType,
    isLoading: createLeaveTypeIsLoading,
    error: createLeaveTypeError,
  } = useMutation(leaveService.createLeave, {
    onSuccess: (data) => {
      toast.success(`New Time off Type Created.`);
      queryClient.invalidateQueries([leaveService.leavesQueryKey]);
      queryClient.setQueryData([leaveService.leavesQueryKey], (old: ILeaveType[] | undefined) => {
        return old ? [...old, data] : undefined;
      });
      setStaffIds([]);
      if (employeePath) {
        updateEmployeeForm({
          ...employeeForm,
          leaves: [...employeeForm.leaves, { value: data.id, label: data.title }],
        });
        navigate(employeePath);
      } else {
        containerRef.current?.scrollIntoView(false);
        reset(formDefaultValues);
      }
    },
  });

  const {
    mutate: updateLeaveType,
    isLoading: updateLeaveTypeIsLoading,
    error: updateLeaveTypeError,
  } = useMutation(leaveService.updateLeave, {
    onSuccess: () => {
      toast.success(`Time off Type Updated.`);
      handleResetForm();
      queryClient.invalidateQueries([leaveService.leavesQueryKey]);
    },
  });

  const handleOpenAccessLevel = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!employees.length) return;
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAccessLevel = () => {
    setAnchorEl(null);
  };

  const handleStaffIds = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, id } = e.target as HTMLInputElement;
    if (checked) {
      setStaffIds((state) => [...state, +id]);
    } else {
      setStaffIds((state) => state.filter((empId) => empId !== +id));
    }
  };

  const onSubmit: SubmitHandler<LeaveTypeRequest> = (data) => {
    if (createLeaveTypeIsLoading || updateLeaveTypeIsLoading) {
      return;
    }
    if (updateMode) {
      updateLeaveType({
        ...data,
        paid: data.paid ? 1 : 0,
        leave_id: updateMode.id,
        total: hoursToMilliseconds(data.total),
      });
    } else {
      createLeaveType({
        ...data,
        staff_ids: staffIds,
        total: hoursToMilliseconds(data.total),
      });
    }
  };

  const handleEditLeaveType = (id: ILeaveType['id']) => {
    const lt = leaveTypes?.find((l) => l.id === id);
    if (lt) {
      containerRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      reset({
        title: lt.title,
        duration: lt.duration,
        paid: lt.paid,
        total: millisecondsToHours(lt.total),
      });
      setUpdateMode(lt);
    }
  };

  const handleResetForm = () => {
    setUpdateMode(null);
    reset(formDefaultValues);
  };

  return (
    <div className="w-full mx-auto space-y-4 pb-14" ref={containerRef}>
      <div className="flex flex-col items-center justify-center w-full bg-white border border-gray-100 rounded-md shadow-lg p-9">
        <div className="flex w-full pb-6 font-medium border-b">
          {updateMode ? 'Edit Time Off' : 'New Time Off'}
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-2">
          <div className="divide-y divide-gray-200">
            <div className="py-2">
              <div className="grid grid-cols-1 mt-4 gap-y-1 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                    Title
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      id="title"
                      {...register('title')}
                      className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <p className="h-8 pt-1 text-sm text-red-500 first-letter:capitalize">
                    {errors.title?.message}
                  </p>
                </div>
                <div className="sm:col-span-3">
                  <label htmlFor="duration" className="block text-sm font-medium text-gray-700">
                    Duration
                  </label>
                  <div className="mt-2">
                    <select
                      id="duration"
                      defaultValue="0"
                      {...register('duration')}
                      className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    >
                      <option disabled value="0">
                        Select a duration
                      </option>
                      <option value={2629800000}>Monthly</option>
                      <option value={31556952000}>Yearly</option>
                    </select>
                  </div>
                  <p className="h-8 pt-1 text-sm text-red-500 first-letter:capitalize">
                    {errors.duration?.message}
                  </p>
                </div>
                <div className="sm:col-span-3">
                  <label htmlFor="totall" className="block text-sm font-medium text-gray-700">
                    Total Hours
                  </label>
                  <div className="mt-2">
                    <input
                      type="number"
                      id="totall"
                      {...register('total')}
                      className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <p className="h-8 pt-1 text-sm text-red-500 first-letter:capitalize">
                    {errors.total?.message}
                  </p>
                </div>
                {!updateMode ? (
                  <div className="sm:col-span-3">
                    <label htmlFor="staffs" className="block text-sm font-medium text-gray-700">
                      Assign to Staff
                    </label>
                    <div className="mt-2">
                      <button
                        type="button"
                        onClick={handleOpenAccessLevel}
                        className="w-40 h-10 text-sm font-medium text-white bg-indigo-500 border border-transparent rounded shadow-sm hover:bg-indigo-700"
                      >
                        <>{staffIds.length} Staff Selected</>
                      </button>
                      <Popover
                        id="al"
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleCloseAccessLevel}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        <div className="flex flex-col p-4 select-none">
                          <div className="relative grid gap-2 bg-white w-[250px] max-h-[250px] overflow-y-auto divide-y divide-gray-100">
                            {employees.map((emp) => {
                              const isActive = staffIds.find((s) => s === emp.id);
                              return (
                                <Fragment key={emp.id}>
                                  <div className="flex items-center flex-1 p-2 mr-2 bg-gray-200">
                                    <input
                                      type="checkbox"
                                      id={emp.id.toString()}
                                      checked={!!isActive}
                                      onChange={(e) => handleStaffIds(e)}
                                      className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                                    />
                                    <label
                                      htmlFor={emp.id.toString()}
                                      className="flex-1 block ml-4 mr-4 text-sm font-medium text-gray-700 capitalize line-clamp-1"
                                    >
                                      {capitalizeFirstLetter(`${emp.first_name} ${emp.last_name}`)}
                                    </label>
                                  </div>
                                </Fragment>
                              );
                            })}
                          </div>
                        </div>
                      </Popover>
                    </div>
                  </div>
                ) : null}
                <div className="self-center mt-4 sm:col-span-3 md:mt-0">
                  <div className="flex flex-col items-center">
                    <div className="flex items-center w-full">
                      <input
                        type="checkbox"
                        id="mandatory"
                        {...register('paid')}
                        className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                      />
                      <label
                        htmlFor="mandatory"
                        className="block ml-4 text-sm font-medium text-gray-700 underline underline-offset-2"
                      >
                        Paid
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <FormError error={[createLeaveTypeError, updateLeaveTypeError]} />
            <div className="flex items-center space-x-4">
              {updateMode && (
                <button
                  type="button"
                  disabled={updateLeaveTypeIsLoading}
                  className="flex justify-center w-40 py-3 text-sm font-medium text-white bg-red-500 border border-transparent rounded shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={handleResetForm}
                >
                  Cancel
                </button>
              )}
              <button
                type="submit"
                className="flex justify-center w-40 py-3 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded shadow-sm ml-9 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {!createLeaveTypeIsLoading && !updateLeaveTypeIsLoading ? (
                  updateMode ? (
                    'Update'
                  ) : (
                    'Save'
                  )
                ) : (
                  <Spinner size="small" />
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="flex items-center justify-center mt-6">
        {leaveTypesLoading && !leaveTypes && <Spinner size="large" />}
      </div>
      {leaveTypes && leaveTypes?.length > 0 && (
        <TimeOffTable leaveTypes={leaveTypes} handleEditLeaveType={handleEditLeaveType} />
      )}
    </div>
  );
};

export default TimeOff;
