import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import BarChart from './components/bar-chart';
import PieChart from './components/pie-chart';
// import AreaChart from './components/area-chart';
import { useTitle } from 'react-use';
import Calendar from '../../components/libs/calendar';
import { UserIcon, CheckIcon } from '@heroicons/react/24/outline';
import Widget from './components/widget';
import UserDetails from './components/user-details';
import { FirebaseMessageType, useFirebaseSlice } from '../../store/firebase-slice';
import { useDashboard } from '../../hooks/use-dashboard';
import { addWeeks, subMonths, subWeeks } from 'date-fns';
import { Dashboard as IDashboard } from '../../interfaces/dashboard-interface';
import workspaceService from '../../services/workspace-service';
import { useEmployees } from '../../hooks/use-employees';
import { useCalendar } from '../../hooks/use-calendar';
import { calcTimes } from '../../utils/calc-worktimes';
import { getPercentageProfit } from '../../utils/percentage';
import { capitalizeFirstLetter } from '../../utils/capitalize-first-letter';
import { Employee } from '../../interfaces/employee-interface';
import { Calendar as ICalendar } from '../../interfaces/calendar-interface';
import { useActiveWorkspaceSlice } from '../../store/active-workspace-slice';

// const areaChartData1 = [
//   {
//     name: 'Online',
//     data: [0, 6, 0],
//   },
//   {
//     name: 'Offline',
//     data: [0, 11, 0],
//   },
// ];

// const areaChartData2 = [
//   {
//     name: 'series-1',
//     data: [66, 34, 82, 39, 15, 70, 44, 91],
//   },
// ];

// const handleBarChartCategories = () => {
//   let date = subWeeks(new Date(), 1);
//   const dateArr = [];
//   for (let index = 0; index < 7; index++) {
//     date = addDays(date, 1);
//     dateArr.push(date);
//   }
//   return dateArr.map((d) => formatDate(d, 'd LLL'));
// };

const handleBarChartData = (employees: Employee[], schedules: Record<string, ICalendar[]>) => {
  const resources = {
    data: [
      { name: 'Work Time', data: [] as number[] },
      { name: 'Breaks', data: [] as number[] },
    ],
    categories: [] as string[],
  };
  employees.forEach((emp) => {
    const userSchedules =
      schedules[emp.id.toString()]?.filter(
        (sch) => sch.built_by_staff === 0 || sch.built_by_staff === false,
      ) ?? [];
    const wtData = calcTimes(userSchedules);
    resources.data[0].data.push(getPercentageProfit(wtData.actuallyWorked, wtData.mustWorkMins));
    resources.data[1].data.push(getPercentageProfit(wtData.brMins, wtData.usedBreakMins) * -1);
    resources.categories.push(capitalizeFirstLetter(emp.first_name));
  });
  return resources;
};

const createPieChartData = (dashboard: IDashboard | undefined) => {
  let series1 = [0, 0];
  let series2 = [0, 0, 0];
  let show1 = false;
  let show2 = false;
  if (dashboard) {
    series1 = [dashboard?.attendance_count?.online ?? 0, dashboard?.attendance_count?.offline ?? 0];
    if (+series1[0] !== 0 || +series1[1] !== 0) {
      show1 = true;
    }
    const approved = dashboard?.leave_request_count.find((lv) => lv.status === 'approved');
    const pending = dashboard?.leave_request_count.find((lv) => lv.status === 'pending');
    const declined = dashboard?.leave_request_count.find((lv) => lv.status === 'declined');
    series2 = [approved?.total ?? 0, declined?.total ?? 0, pending?.total ?? 0];
    if (+series2[0] !== 0 || +series2[1] !== 0 || +series2[2] !== 0) {
      show2 = true;
    }
  }
  return [
    {
      series: series1,
      labels: ['online', 'Offline'],
      show: show1,
    },
    {
      series: series2,
      labels: ['Approved', 'Rejected', 'Pending'],
      show: show2,
    },
  ];
};

const createWidgets = (dashboard: IDashboard | undefined) => {
  let leaveRequests = {} as Record<
    'pending' | 'approved' | 'declined',
    { type: string; total: number }
  >;
  dashboard?.leave_request_count.forEach((element) => {
    leaveRequests = { ...leaveRequests, [element.status]: element };
  });
  const widgets = [
    {
      caption: 'Attendance',
      icon: UserIcon,
      path: './employee-work-space/attendance',
      stats: [
        {
          name: `${dashboard?.attendance_count.online ?? 0} Online`,
          color: 'text-purple-600',
        },
        {
          name: `${dashboard?.attendance_count.offline ?? 0} Offline`,
          color: 'text-gray-400',
        },
      ],
    },
    {
      caption: 'Time Off Requests',
      icon: CheckIcon,
      path: './employee-work-space/leaves-of-absences',
      stats: [
        {
          name: `${leaveRequests['pending']?.total ?? 0} Pending`,
          color: 'text-purple-600',
        },
        {
          name: `${leaveRequests['approved']?.total ?? 0} Approved`,
          color: 'text-green-600',
        },
        {
          name: `${leaveRequests['declined']?.total ?? 0} Rejected`,
          color: 'text-red-600',
        },
      ],
    },
  ];

  return widgets;
};

const pastWeek = subWeeks(new Date(), 1).getTime();
const nextWeek = addWeeks(new Date(), 1).getTime();
const pastMonth = subMonths(new Date(), 1).getTime();
const today = new Date().getTime();

const Dashboard = () => {
  useTitle(`${import.meta.env.VITE_APP_TITLE} | Dashboard`);
  const ws = useActiveWorkspaceSlice((state) => state.workspace);
  const queryClient = useQueryClient();
  const firebaseMessage = useFirebaseSlice((state) => state.message);
  const { data: dashboard } = useDashboard(pastMonth, nextWeek, ws?.id);
  const widgets = createWidgets(dashboard);
  const pieChartData = createPieChartData(dashboard);
  const { data: employees = [] } = useEmployees();
  const { data: schedules = {} } = useCalendar(pastWeek, today);
  const barChartData = handleBarChartData(employees, schedules);

  useEffect(() => {
    const type = firebaseMessage?.type as FirebaseMessageType | undefined;
    // console.log({ type });
    if (type === 'leaveRequestCreated' || type === 'leaveRequestEdited') {
      queryClient.invalidateQueries([workspaceService.dashboardQueryKey]);
    }
  }, [firebaseMessage]);

  return (
    <div className="flex w-full mx-auto pb-14">
      <div className="flex flex-col w-full space-y-6">
        {/* <h2 className="text-2xl font-bold leading-6 text-indigo-600">Today</h2> */}
        <div className="flex flex-col flex-1 xl:flex-row">
          <div className="flex flex-col flex-1 space-y-6">
            <div className="grid w-full grid-cols-1 xl:grid-cols-2 md:gap-x-6 gap-y-6 md:gap-y-6">
              {widgets?.map((widget) => {
                return (
                  <Widget
                    key={widget.caption}
                    icon={widget.icon}
                    path={widget.path}
                    caption={widget.caption}
                    stats={widget.stats}
                  />
                );
              })}
            </div>
            {/* <div className="grid w-full grid-cols-1 xl:grid-cols-2 md:gap-x-6 gap-y-6 md:gap-y-6">
              <div className="flex flex-col p-6 bg-white border shadow rounded-2xl">
                <div className="flex items-center justify-between">
                  <div className="flex flex-col">
                    <h6 className="font-medium text-gray-800">Availability</h6>
                    <h6 className="text-sm text-gray-400">Live</h6>
                  </div>
                  <div className="flex flex-col text-sm font-medium">
                    <span className="text-purple-600">Online</span>
                    <span className="text-gray-400">Offline</span>
                  </div>
                </div>
                <AreaChart chartData={areaChartData1} colors={['#9333ea', '#9ca3af']} />
              </div>
              <div className="flex flex-col p-6 bg-white border shadow rounded-2xl">
                <h6 className="font-medium text-gray-800">FEATURE</h6>
                <h6 className="text-sm text-gray-400">Data Overview</h6>
                <AreaChart chartData={areaChartData2} colors={['#9ca3af', '#9333ea']} />
              </div>
            </div> */}
            {pieChartData[0].show || pieChartData[1].show ? (
              <div className="grid w-full grid-cols-1 xl:grid-cols-1 md:gap-x-6 gap-y-6 md:gap-y-6">
                <div className="flex flex-col p-6 bg-white border shadow rounded-2xl">
                  <div className="grid grid-cols-12">
                    <div className="flex flex-col col-span-12 md:col-span-6">
                      <h6 className="font-medium text-gray-800">Attendance Overview</h6>
                      <h6 className="text-sm text-gray-400">Live</h6>
                      {pieChartData[0].show ? (
                        <div className="mt-4 shrink-0">
                          <PieChart
                            chartData={pieChartData[0]}
                            colors={['#22c55e', '#ef4444', '#f97316']}
                          />
                        </div>
                      ) : null}
                    </div>
                    <div className="flex flex-col col-span-12 md:col-span-6">
                      <h6 className="font-medium text-gray-800">Time Off Requests Overview</h6>
                      <h6 className="text-sm text-gray-400">Live</h6>
                      {pieChartData[1].show ? (
                        <div className="mt-4 shrink-0">
                          <PieChart
                            chartData={pieChartData[1]}
                            colors={['#22c55e', '#ef4444', '#f97316']}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                {/* <div className="flex flex-col p-6 bg-white border shadow rounded-2xl">
                <h6 className="font-medium text-gray-800">FEATURE</h6>
                <h6 className="text-sm text-gray-400">Data Overview</h6>
                <PieChart chartData={pieChartData} />
              </div> */}
              </div>
            ) : null}
            <div className="grid w-full grid-cols-1 gap-6">
              <div className="flex flex-col p-6 bg-white border shadow rounded-2xl">
                <h6 className="font-medium text-gray-800">Work Time Stats</h6>
                <h6 className="text-sm text-gray-400">Past 7 Days</h6>
                <BarChart chartData={barChartData.data} categories={barChartData.categories} />
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full pb-4 mt-6 space-y-6 xl:mt-0 xl:ml-6 xl:w-1/3">
            <Calendar />
            <UserDetails />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
