import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import profileService from '../../services/profile-service';
import { useActiveWorkspaceSlice } from '../../store/active-workspace-slice';
import { useAuthSlice } from '../../store/auth-slice';
import { useFirebaseSlice } from '../../store/firebase-slice';
import { isTokenValid } from '../../utils/is-token-expired';

export interface RequireAuthProps {
  component: JSX.Element;
}

export function RequireAuth({ component }: RequireAuthProps) {
  const queryClient = useQueryClient();
  const location = useLocation();
  const resetWorkspace = useActiveWorkspaceSlice((state) => state.reset);
  const exp = useAuthSlice((state) => state.exp);
  const firebaseToken = useFirebaseSlice((state) => state.token);
  const isAuthenticated = exp && isTokenValid(exp);

  useEffect(() => {
    if (firebaseToken && isAuthenticated) {
      const meta = navigator.userAgent;
      sendFirebaseToken({ device_token: firebaseToken, meta });
    }
  }, [firebaseToken, isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated) {
      useAuthSlice.persist.clearStorage();
      queryClient.clear();
      resetWorkspace();
    }
  }, [isAuthenticated]);

  const { mutate: sendFirebaseToken } = useMutation(profileService.sendFirebaseToken);

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return component;
}

export default RequireAuth;
