import { getYear } from 'date-fns';

interface LayoutProps {
  outlet: React.ReactElement;
  header: React.ReactElement;
  WorkspacesBar: React.ReactElement;
}

const Layout = ({ outlet, header, WorkspacesBar }: LayoutProps) => {
  return (
    <div className="h-screen bg-gray-50">
      <div className="flex flex-col h-full">
        {header}
        <div className="flex flex-1 h-[calc(100vh-64px)] overflow-hidden">
          <div className="hidden lg:flex h-[calc(100vh-64px)]">{WorkspacesBar}</div>
          <main className="relative flex w-full h-[calc(100vh-64px)] select-none">
            <div className="hidden lg:fixed bottom-2 right-4 print:hidden">
              <span className="z-10 text-xs text-gray-400">
                Copyright &copy; {getYear(new Date())}
              </span>
              <span className="z-10 ml-4 text-xs text-gray-400">
                Version {import.meta.env.VITE_APP_VERSION}
              </span>
            </div>
            {outlet}
          </main>
        </div>
      </div>
    </div>
  );
};

export default Layout;
