import { useQuery } from '@tanstack/react-query';
import shiftsService from '../services/shifts-service';

export function useShiftsRequest(page?: number, offset?: number) {
  return useQuery(
    [shiftsService.shiftsRequestQueryKey, page, offset],
    () => shiftsService.getShiftsRequest({ page, offset }),
    {
      refetchOnWindowFocus: false,
    },
  );
}
