/* eslint-disable no-constant-condition */
import { useEffect, useRef, useState } from 'react';
import { useTitle } from 'react-use';
import Spinner from '../../components/spinner';
import { formatDateToTimeZone, utcDate } from '../../utils/format-date';
import { useNavigate } from 'react-router-dom';
import { useActiveWorkspaceSlice } from '../../store/active-workspace-slice';
import { useReportLock } from '../../hooks/use-report-lock';
import { toast } from 'react-hot-toast';
import Confirmation from '../../components/libs/confirmation';
import { useGetLastLock } from '../../hooks/use-get-last-lock';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import {
  addDays,
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
  isSameMonth,
  isSameDay,
  subMonths,
  addMonths,
  isBefore,
  startOfDay,
} from 'date-fns';
import { Tooltip } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import reportsService from '../../services/reports-service';

const calcIsReadyForLock = (day: Date, dates: string[]) => {
  // (isAfter(day, new Date(+d)) && isBefore(day, addDays(new Date(+d), 7)))
  const find = dates.find((d) => isSameDay(day, new Date(+d)));
  return find;
};

const daysOfWeek = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
] as const;

const getAllWeeks = (startDate: Date, endDate: Date) => {
  let currentDate = startDate;
  const allWeeks: { id: number; day: Date }[] = [];
  while (currentDate <= endDate) {
    let day = utcDate(currentDate);
    for (let i = 0; i < 7; i++) {
      allWeeks.push({
        id: day.getTime(),
        day,
      });
      day = addDays(day, 1);
    }
    currentDate = addDays(currentDate, 7);
  }
  return allWeeks;
};

const LockSystem = () => {
  useTitle(`${import.meta.env.VITE_APP_TITLE} | Staffs Worktime`);
  const queryClient = useQueryClient();
  const workSpaceSubDomain = useActiveWorkspaceSlice((state) => state.workspace?.sub_domain)!;
  const getLastLock = useGetLastLock(workSpaceSubDomain);
  const reportLock = useReportLock({
    onSuccess: (data) => {
      toast.success(data.message);
    },
    onSettled: () => {
      setSelectedDate(null);
      queryClient.invalidateQueries([reportsService.getLastLockQueryKey]);
    },
  });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [activeDate, setActiveDate] = useState<Date>(new Date());
  const startOfTheSelectedMonth = startOfMonth(activeDate);
  const endOfTheSelectedMonth = endOfMonth(activeDate);
  const startDate = startOfWeek(startOfTheSelectedMonth, { weekStartsOn: 1 });
  const endDate = endOfWeek(endOfTheSelectedMonth, { weekStartsOn: 1 });
  const monthDays = getAllWeeks(startDate, endDate);
  const isFirstRender = useRef(false);
  const sortLastLocks = Object.keys(getLastLock.data ?? {}).sort((a, b) => {
    return new Date(a).getTime() - new Date(b).getTime();
  });

  useEffect(() => {
    if (!isFirstRender.current && sortLastLocks?.[0]) {
      isFirstRender.current = true;
      setActiveDate(new Date(+sortLastLocks?.[0]));
    }
  }, [sortLastLocks]);

  const handlePrevMonth = () => {
    const prev = subMonths(activeDate, 1);
    setActiveDate(prev);
  };

  const handleNextMonth = () => {
    const next = addMonths(activeDate, 1);
    setActiveDate(next);
  };

  const handleToday = () => {
    const now = new Date();
    setActiveDate(now);
  };

  const handleLastLockedDate = () => {
    const d = sortLastLocks?.[0] ? new Date(+sortLastLocks?.[0]) : new Date();
    setActiveDate(d);
  };

  const handleSelectDate = (day: Date) => {
    if (reportLock.isLoading || getLastLock.isLoading) {
      return;
    }
    const isCurrentMonth = isSameMonth(day, activeDate);
    const isBeforeToday = isBefore(day, utcDate(startOfDay(new Date())));
    const isReadyForLock = calcIsReadyForLock(day, sortLastLocks);
    if (isCurrentMonth && isBeforeToday && isReadyForLock) {
      setSelectedDate(day);
      handleOpenConfirmation();
    }
  };

  const handleShowDetails = () => {
    // TODO: navigate to report
  };

  const handleLock = () => {
    if (selectedDate) {
      const to = selectedDate.getTime();
      reportLock.mutate({ to });
    }
  };

  const handleOpenConfirmation = () => {
    setShowConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleConfirmation = (bool: boolean) => {
    if (bool && !reportLock.isLoading) {
      handleLock();
      return;
    }
  };

  return (
    <>
      <div className="w-full mx-auto space-y-0 pb-14 print:p-0 print:max-w-none print:w-full">
        <div className="flex flex-col items-center justify-center w-full bg-white border border-gray-100 rounded-md shadow-lg p-9 print:shadow-none print:p-0 print:border-none">
          <div className="flex items-center justify-between w-full pb-6 mb-2 font-medium border-b md:mb-6 print:hidden print:pb-4 print:mb-0 print:border-b print:border-b-gray-200">
            <div className="flex flex-col w-full font-medium">
              <span>Lock System</span>
              <p className="mt-2 text-sm font-normal text-gray-400">
                Here, you can find the first unlocked date and lock attendance data in 7-day
                intervals. The system automatically skips data-free dates, identifies the next date
                with attendance records, and allows you to secure the next 7 days records. Once the
                attendance records are locked, no further changes can be made to them.
              </p>
            </div>
          </div>
          {getLastLock.isLoading ? (
            <div className="flex items-end justify-center w-full my-9">
              <span className="mr-4">Please wait</span>
              <Spinner size="small" />
            </div>
          ) : (
            <div className="relative flex flex-col w-full">
              <div className="relative flex flex-col items-center justify-between pb-2 mb-2 space-y-4 border-b md:pb-6 md:mb-6 md:space-y-0 md:flex-row">
                <Tooltip
                  title={`Today - ${formatDateToTimeZone(new Date(), 'MMMM yyyy')}`}
                  placement="top"
                >
                  <button
                    onClick={handleToday}
                    className="flex items-center justify-center w-12 h-10 text-lg font-semibold text-white transition rounded shrink-0 bg-brand-primary hover:bg-brand-primary"
                  >
                    T
                  </button>
                </Tooltip>
                <div className="flex items-center space-x-4 md:justify-center">
                  <Tooltip title={'Previous Month'} placement="top">
                    <button
                      onClick={handlePrevMonth}
                      className="flex items-center justify-center w-12 h-10 text-white transition rounded bg-brand-primary hover:bg-brand-primary"
                    >
                      <ChevronLeftIcon className="h-7 w-7" />
                    </button>
                  </Tooltip>
                  <h2 className="text-xs font-bold md:text-3xl text-brand-primary font-lato">
                    {formatDateToTimeZone(activeDate, 'MMMM yyyy')}
                  </h2>
                  <Tooltip title={'Next Month'} placement="top">
                    <button
                      onClick={handleNextMonth}
                      className="flex items-center justify-center w-12 h-10 text-white transition rounded bg-brand-primary hover:bg-brand-primary"
                    >
                      <ChevronRightIcon className="h-7 w-7" />
                    </button>
                  </Tooltip>
                </div>
                <Tooltip title={`Last Locked Date`} placement="top">
                  <button
                    onClick={handleLastLockedDate}
                    className="flex items-center justify-center w-12 h-10 text-lg font-semibold text-white transition rounded shrink-0 bg-brand-primary hover:bg-brand-primary"
                  >
                    L
                  </button>
                </Tooltip>
              </div>
              <div className="grid grid-cols-7 gap-1 md:gap-y-4 md:gap-x-4">
                {daysOfWeek.map((day) => {
                  const name = day.substring(0, 3);
                  return (
                    <div
                      key={day}
                      className="flex items-center w-full h-12 text-xs font-medium text-gray-400 uppercase md:pl-4 md:text-lg"
                    >
                      {name}
                    </div>
                  );
                })}
                {monthDays.map(({ id, day }) => {
                  const isToday = isSameDay(day, utcDate(new Date()));
                  const isCurrentMonth = isSameMonth(day, activeDate);
                  const isBeforeToday = isBefore(day, utcDate(startOfDay(new Date())));
                  const isReadyForLock = calcIsReadyForLock(day, sortLastLocks);
                  return (
                    <div
                      key={id}
                      onClick={() => handleSelectDate(day)}
                      className={`relative flex flex-col justify-between h-16 md:h-32 p-1 md:p-4 border-2 rounded md:rounded-xl border-gray-200 ${
                        isCurrentMonth &&
                        isReadyForLock &&
                        getLastLock.data?.[isReadyForLock] &&
                        isBeforeToday
                          ? 'cursor-pointer'
                          : ''
                      } ${isCurrentMonth ? 'bg-blue-50' : 'bg-gray-50'}`}
                    >
                      <span
                        className={`flex items-center justify-between text-xs md:text-3xl font-semibold font-lato ${
                          isToday
                            ? 'text-indigo-500'
                            : isCurrentMonth
                            ? 'text-gray-600'
                            : 'text-gray-400'
                        }`}
                      >
                        {formatDateToTimeZone(day, 'dd')}
                        {selectedDate && isSameDay(day, selectedDate) && reportLock.isLoading ? (
                          <Spinner size="small" />
                        ) : null}
                      </span>
                      {isCurrentMonth &&
                      isReadyForLock &&
                      getLastLock.data?.[isReadyForLock] &&
                      isBeforeToday ? (
                        <>
                          <span className="hidden text-xs font-medium text-gray-400 md:block md:font-semibold">
                            Lock{' '}
                            {getLastLock.data?.[isReadyForLock] === 1
                              ? `${getLastLock.data?.[isReadyForLock]} Schedule`
                              : `${getLastLock.data?.[isReadyForLock]} Schedules`}
                          </span>
                          <span className="text-xs font-medium text-indigo-500 md:hidden md:font-semibold">
                            {getLastLock.data?.[isReadyForLock] >= 1
                              ? `${getLastLock.data?.[isReadyForLock]}`
                              : null}
                          </span>
                        </>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
      <Confirmation
        show={showConfirmation}
        closeModal={handleCloseConfirmation}
        confirm={handleConfirmation}
        title={`Are you sure you want to proceed and lock?`}
        description={
          <span className="mt-1 text-base text-gray-500">
            Once locked, any modifications to records prior to{' '}
            <b className="inline-block">{formatDateToTimeZone(selectedDate, 'dd-MM-y')}</b> will be
            permanently disabled.
          </span>
        }
      />
    </>
  );
};

export default LockSystem;
