import { memo } from 'react';
import { Resource } from '../calendar-reports';
import { capitalizeFirstLetter } from '../../../utils/capitalize-first-letter';
import Tooltip from '@mui/material/Tooltip';
import IconDrag from '~icons/carbon/drag-vertical';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { convertMToHMWithLabel } from '../../../utils/format-minutes';
import { getInitials } from '../../../utils/get-Initials';

interface EmployeeInfoProps {
  status: Resource['status'];
  name: Resource['name'];
  attachment: Resource['attachment'];
  jobTitle: Resource['jobTitle'];
  totalMins: Resource['totalMins'];
  dragHandler: DraggableProvidedDragHandleProps | null | undefined;
}

const EmployeeInfo = ({
  status,
  name,
  attachment,
  jobTitle,
  totalMins,
  dragHandler,
}: EmployeeInfoProps) => {
  return (
    <div className="relative flex items-center justify-start h-full px-4 w-52 shrink-0 grow-0">
      <button type="button" className="absolute bottom-0 left-0 cursor-move" {...dragHandler}>
        <IconDrag className="w-6 h-6 text-gray-400" />
      </button>
      <div className="flex items-center w-full">
        <div className="relative flex-shrink-0 w-10 h-10">
          <span
            className={`h-3 w-3 absolute top-0 left-0 rounded-full ${
              status === 'ONLINE' ? 'bg-green-500' : ''
            } ${status === 'BREAK' ? 'bg-yellow-500' : ''}`}
          ></span>
          {attachment ? (
            <img
              className="object-cover w-10 h-10 rounded-full"
              src={attachment}
              alt={getInitials(name, true)}
            />
          ) : (
            <span className="block w-10 h-10 overflow-hidden bg-gray-100 rounded-full">
              <svg className="w-full h-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            </span>
          )}
        </div>
        <div
          className="ml-4 w-[calc(100%-56px)]"
          title={`${capitalizeFirstLetter(name)}, ${capitalizeFirstLetter(jobTitle)}`}
        >
          <span className="text-sm font-medium text-gray-900 first-letter:capitalize line-clamp-1">
            {capitalizeFirstLetter(name)}
          </span>
          <Tooltip title={'This Week'}>
            <div className="text-sm text-gray-500 capitalize line-clamp-1">
              {convertMToHMWithLabel(totalMins)}
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default memo(EmployeeInfo);
