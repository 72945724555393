import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { customToastError } from '../utils/custom-toast-error';
import staffsService from '../services/staffs-service';
import { Employee } from '../interfaces/employee-interface';

export function useChangeStaffStatus(configOptions: UseMutationOptions<any, any, any, any> = {}) {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, status }: { id: number; status: 'active' | 'archived' }) =>
      staffsService.changeStaffStatus(id, status),
    {
      onSuccess: (data) => {
        if (data.employment_status === 'active') {
          queryClient.setQueryData(
            [staffsService.archivedStaffsQueryKey],
            (old: Employee[] | undefined) => {
              return (old ?? [])?.filter((emp) => {
                if (emp.id !== data.id) {
                  return true;
                }
                return false;
              });
            },
          );
          queryClient.setQueryData(
            [staffsService.staffsQueryKey],
            (old: Employee[] | undefined) => {
              return [...(old ?? []), data];
            },
          );
        } else {
          queryClient.setQueryData(
            [staffsService.staffsQueryKey],
            (old: Employee[] | undefined) => {
              return (old ?? [])?.filter((emp) => {
                if (emp.id !== data.id) {
                  return true;
                }
                return false;
              });
            },
          );
          queryClient.setQueryData(
            [staffsService.archivedStaffsQueryKey],
            (old: Employee[] | undefined) => {
              return [...(old ?? []), data];
            },
          );
        }
      },
      onError: (data: AxiosError) => {
        const err = data.response?.data as any;
        customToastError('Error', err?.message ?? 'Sorry there was a problem');
      },
      onSettled: () => {
        queryClient.invalidateQueries([staffsService.staffsQueryKey]);
        queryClient.invalidateQueries([staffsService.archivedStaffsQueryKey]);
      },
      ...configOptions,
    },
  );
}
