import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { customToastError } from '../utils/custom-toast-error';
import reportsService from '../services/reports-service';

export function useReportLock(configOptions: UseMutationOptions<any, any, any, any> = {}) {
  return useMutation(({ to }: { to: number }) => reportsService.reportLock(to), {
    onError: (data: AxiosError) => {
      const err = data.response?.data as any;
      customToastError('Error', err?.message ?? 'Sorry there was a problem');
    },
    ...configOptions,
  });
}
