import { create } from 'zustand';
import { StaffWorkTimeReportResponse } from '../interfaces/staff-work-time-report-response';

type State = {
  data: StaffWorkTimeReportResponse[];
};

type Actions = {
  updateData: (workspace: StaffWorkTimeReportResponse[]) => void;
  reset: () => void;
};

const initialState: State = {
  data: [],
};

export const usePrintWorkTimeSlice = create<State & Actions>()((set) => ({
  ...initialState,
  updateData(data: StaffWorkTimeReportResponse[]) {
    set({ data });
  },
  reset: () => {
    set({ ...initialState });
  },
}));
