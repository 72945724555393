import { useTitle } from 'react-use';
import { Outlet } from 'react-router-dom';
// import AttendanceTabs from './components/attendance-tabs';

const Attendance = () => {
  useTitle(`${import.meta.env.VITE_APP_TITLE} | Attendance`);

  return <Outlet />;
};

export default Attendance;
