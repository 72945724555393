import { create } from 'zustand';
import { StaffWorkTimeReportResponse } from '../interfaces/staff-work-time-report-response';

type DayWithClock = StaffWorkTimeReportResponse['days'][0] & {
  name: string;
  email: string;
  job: string;
};

type State = {
  data: DayWithClock | undefined;
};

type Actions = {
  updateData: (workspace: DayWithClock | undefined) => void;
  reset: () => void;
};

const initialState: State = {
  data: undefined,
};

export const usePrintWorkTimeClocksSlice = create<State & Actions>()((set) => ({
  ...initialState,
  updateData(data: DayWithClock | undefined) {
    set({ data });
  },
  reset: () => {
    set({ ...initialState });
  },
}));
