import Dialog from '@mui/material/Dialog';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { getHours, getMinutes, minutesToMilliseconds } from 'date-fns';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from '@mui/material/TextField';

const schema = yup
  .object({
    type: yup.string().required(),
    paid: yup.boolean().required(),
    start_at: yup.string().nullable(),
    total: yup
      .number()
      .positive()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required('this field is required'),
  })
  .required();

interface IForm {
  working_object_id: any;
  type: string;
  paid: boolean;
  start_at?: any;
  total: number | string;
}

export interface EmployeeBreak {
  h: number;
  m: number;
  type: string;
  total: number;
  paid: boolean;
}

interface EmployeeBreakModalProps {
  open: boolean;
  close: (data: EmployeeBreak | null) => void;
}

const EmployeeBreakModal = ({ open, close }: EmployeeBreakModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    control,
  } = useForm<IForm>({
    defaultValues: {
      type: 'fix',
      paid: false,
      start_at: null,
      total: '',
    },
    resolver: yupResolver<any>(schema),
  });
  const type = watch('type');

  const handleCloseModal = () => {
    close(null);
    handleResetForm();
  };

  const handleResetForm = () => {
    reset({
      type: 'fix',
      start_at: null,
      paid: false,
      total: '',
    });
  };

  const onSubmit: SubmitHandler<IForm> = (data) => {
    const total = minutesToMilliseconds(+data.total);
    const h = getHours(new Date(data.start_at));
    const m = getMinutes(new Date(data.start_at));
    close({ total, h, m, type: data.type, paid: data.paid });
    handleResetForm();
  };

  return (
    <Dialog onClose={handleCloseModal} open={open} fullWidth={true} maxWidth={'sm'}>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-full space-y-6 p-9">
        <div className="flex items-center justify-between text-lg font-medium leading-6 text-gray-900 capitalize">
          <div className="flex flex-col">
            <span className="leading-9">Add Break</span>
          </div>
          <button
            type="button"
            onClick={handleCloseModal}
            className="ml-6 transition border border-transparent rounded shadow-sm self-baseline focus:outline-none"
          >
            <XMarkIcon className="w-6 h-6" />
          </button>
        </div>
        <div className="divide-y divide-gray-200">
          <div className="grid grid-cols-1 pt-6 gap-y-2 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label className="block text-sm font-medium text-gray-700">Break Type</label>
              <fieldset className="mt-4">
                <div className="flex py-2 space-x-4">
                  <div className="flex items-center">
                    <input
                      id="fix"
                      {...register('type')}
                      value="fix"
                      type="radio"
                      className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                    />
                    <label htmlFor="fix" className="block pl-3 text-sm font-medium text-gray-700">
                      Fix
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      id="float"
                      {...register('type')}
                      value="float"
                      type="radio"
                      className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                    />
                    <label htmlFor="float" className="block pl-3 text-sm font-medium text-gray-700">
                      Float
                    </label>
                  </div>
                </div>
              </fieldset>
            </div>
            {type === 'fix' && (
              <div className="sm:col-span-3">
                <div className="mt-4">
                  <Controller
                    name="start_at"
                    control={control}
                    render={({ field }) => (
                      <TimePicker
                        label="Start Time"
                        ampm={false}
                        {...field}
                        renderInput={(params) => <TextField fullWidth={true} {...params} />}
                      />
                    )}
                  />
                </div>
              </div>
            )}
            <div className="sm:col-span-6">
              <label htmlFor="total" className="block text-sm font-medium text-gray-700">
                Total (Minutes)
              </label>
              <div className="mt-2">
                <input
                  type="number"
                  id="total"
                  {...register('total')}
                  autoComplete="given-name"
                  className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none h-14 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <p className="h-8 pt-2 text-red-500 first-letter:capitalize">
                {errors.total?.message}
              </p>
            </div>
            <div className="flex items-center sm:col-span-6">
              <input
                type="checkbox"
                id={'paid'}
                {...register('paid')}
                className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
              />
              <label
                htmlFor={'paid'}
                className="flex-1 block ml-4 mr-4 text-sm font-medium text-gray-700"
              >
                Paid
              </label>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between w-full pt-6 mx-6 mt-6 border-t md:mx-0">
          <button
            type="button"
            onClick={handleCloseModal}
            className="h-10 text-sm tracking-wide text-white transition bg-red-500 border border-transparent rounded shadow-sm w-28 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="h-10 text-sm tracking-wide text-white transition bg-indigo-500 border border-transparent rounded shadow-sm w-28 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Confirm
          </button>
        </div>
      </form>
    </Dialog>
  );
};

export default EmployeeBreakModal;
