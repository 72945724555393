export function getPercentage(numA: number, numB: number) {
  const n = (numA / numB) * 100;
  return +(toFixedWithoutRound(n, 2) ?? 0);
}

export function getPercentageProfit(numA: number, numB: number) {
  const num = numA - numB;
  const isN = isNaN(num / numB);
  const n = isN ? 0 : ((numA - numB) / numB) * 100;
  return +(toFixedWithoutRound(n, 2) ?? 0);
}

export function toFixedWithoutRound(num: number, fixed: number) {
  const re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
  return num?.toString()?.match(re)?.[0];
}
