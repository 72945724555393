import { useTitle } from 'react-use';
import { Outlet } from 'react-router-dom';

const Employees = () => {
  useTitle(`${import.meta.env.VITE_APP_TITLE} | Employees`);

  return <Outlet />;
};

export default Employees;
