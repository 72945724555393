import { CustomNotification } from '../interfaces/custom-notification-interface';
import { Notification } from '../interfaces/notification-interface';
import { useActiveWorkspaceSlice } from '../store/active-workspace-slice';
import http from './axios-instance';

const notificationsQueryKey = 'Notifications';
const getNotifications = async (): Promise<Notification> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/notification/get`;
  const res = await http.get(url, { headers });
  return res.data;
};

const customNotificationsQueryKey = 'CustomNotifications';
const getCustomNotifications = async ({
  page,
  offset,
}: {
  page: number | undefined;
  offset: number | undefined;
}): Promise<CustomNotification> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const params = { page, offset };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/notification/custom_notifications`;
  const res = await http.get(url, { headers, params });
  return res.data;
};

const seenNotification = async (notification_id: number): Promise<any> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/notification/seen`;
  const res = await http.post(url, { notification_id }, { headers });
  return res.data;
};

const notificationService = {
  notificationsQueryKey,
  getNotifications,
  seenNotification,
  customNotificationsQueryKey,
  getCustomNotifications,
};

export default notificationService;
