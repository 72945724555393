import { useQuery } from '@tanstack/react-query';
import reportsService from '../services/reports-service';

export function useLogsReport(
  page?: number,
  offset?: number,
  start?: string,
  end?: string,
  causer_type?: 'App\\Models\\Merchant' | 'App\\Models\\Staff',
) {
  return useQuery(
    [reportsService.logsQueryKey, page, offset, start, end, causer_type],
    () => reportsService.getLogs({ page, offset, start, end, causer_type }),
    {
      refetchOnWindowFocus: false,
    },
  );
}
