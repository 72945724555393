import { XMarkIcon } from '@heroicons/react/24/outline';
import Dialog from '@mui/material/Dialog';

interface VideoPlayerProps {
  show: boolean;
  closeModal: () => void;
  url: string;
  title?: string;
  description?: string;
}

const VideoPlayer = ({
  show,
  closeModal,
  url,
  title = 'Are you sure about this?',
  description = '',
}: VideoPlayerProps) => {
  const handleClose = () => {
    closeModal();
  };

  return (
    <Dialog onClose={handleClose} open={show} fullWidth={true} maxWidth={'md'}>
      <div className="flex flex-col w-full p-6 bg-gray-100">
        <div className="flex items-center justify-between text-lg font-medium leading-6 text-gray-900 capitalize">
          <div className="flex flex-col">
            <span className="leading-9">{title}</span>
            {description ? <span className="mt-1 text-base text-gray-500">{description}</span> : ''}
          </div>
          <button
            type="button"
            onClick={handleClose}
            className="ml-6 transition border border-transparent rounded shadow-sm self-baseline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            <XMarkIcon className="w-6 h-6" />
          </button>
        </div>
        <div className="flex items-center justify-between w-full pt-9">
          <video width="100%" height="280" controls>
            <source src={url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </Dialog>
  );
};

export default VideoPlayer;
