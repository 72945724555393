import { useParams } from 'react-router-dom';
import { useTitle } from 'react-use';
import Spinner from '../../components/spinner';
import PrevPage from '../../components/ui/prev-page';
import { useLogReport } from '../../hooks/use-log-report';
import { capitalizeFirstLetter } from '../../utils/capitalize-first-letter';
import { formatDateToTimeZone } from '../../utils/format-date';

const LogDetails = () => {
  useTitle(`${import.meta.env.VITE_APP_TITLE} | Log Details`);
  const { id } = useParams();
  const log = useLogReport(id);
  const reason = log.data?.subject_type.split('\\').at(-1);
  const causer = log.data?.causer_type.split('\\').at(-1);

  if (log.isLoading) {
    return (
      <div className="flex items-end justify-center w-full my-9">
        <span className="mr-4">Please wait</span>
        <Spinner size="small" />
      </div>
    );
  }

  return (
    <div className="mx-auto space-y-4 max-w-7xl pb-14">
      <div className="flex items-center justify-between">
        <PrevPage to="../logs-report" />
      </div>
      <div className="flex flex-col items-center justify-center w-full bg-white border border-gray-100 rounded-md shadow-lg p-9">
        <div className="flex w-full pb-6 font-medium border-b">Log Details</div>
        <>
          <div className="grid w-full grid-cols-12 gap-6 mt-6">
            <div className="flex flex-col col-span-12 space-y-2 md:col-span-6">
              <span className="px-4 py-2 font-medium rounded bg-gray-50">Created At</span>
              <span className="px-4 py-2">
                {log.data ? formatDateToTimeZone(log.data!.created_at!, 'dd-MM-y HH:mm') : null}
              </span>
            </div>
            <div className="flex flex-col col-span-12 space-y-2 md:col-span-6">
              <span className="px-4 py-2 font-medium rounded bg-gray-50">Type</span>
              <span className="px-4 py-2">{capitalizeFirstLetter(causer)}</span>
            </div>
            <div className="flex flex-col col-span-12 space-y-2 md:col-span-6">
              <span className="px-4 py-2 font-medium rounded bg-gray-50">Name</span>
              <span className="px-4 py-2">
                {capitalizeFirstLetter(
                  `${log.data?.causer.first_name} ${log.data?.causer.last_name}`,
                )}
              </span>
            </div>
            <div className="flex flex-col col-span-12 space-y-2 md:col-span-6">
              <span className="px-4 py-2 font-medium rounded bg-gray-50">Subject</span>
              <span className="px-4 py-2">{reason}</span>
            </div>
            <div className="flex flex-col col-span-12 space-y-2 md:col-span-6">
              <span className="px-4 py-2 font-medium rounded bg-gray-50">Event</span>
              <span className="px-4 py-2">{log.data?.event ?? '-'}</span>
            </div>
            <div className="flex flex-col col-span-12 space-y-2 md:col-span-6">
              <span className="px-4 py-2 font-medium rounded bg-gray-50">Description</span>
              <span className="px-4 py-2">{log.data?.description ?? '-'}</span>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default LogDetails;
