import { format } from 'date-fns';
import { formatInTimeZone, zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import { useActiveWorkspaceSlice } from '../store/active-workspace-slice';

const tz = 'Europe/London';

export function formatDate(date: number | string | Date | null, pattern = 'dd-MM-y h:mm a') {
  if (!date) {
    return format(new Date(), pattern);
  }
  return format(new Date(date), pattern);
}

export function formatDateToTimeZone(
  date: number | string | Date | null,
  pattern = 'dd-MM-y h:mm a',
) {
  const timeZone = useActiveWorkspaceSlice.getState()?.workspace?.timezone ?? tz;
  if (!date) {
    return formatInTimeZone(new Date(), timeZone, pattern);
  }
  return formatInTimeZone(new Date(date), timeZone, pattern);
}

export function utcDate(date: string | number | Date, utc?: string) {
  const timeZone = utc ?? useActiveWorkspaceSlice.getState()?.workspace?.timezone ?? tz;
  return zonedTimeToUtc(date, timeZone);
}

export function dateUtc(date: string | number | Date, utc?: string) {
  const timeZone = utc ?? useActiveWorkspaceSlice.getState()?.workspace?.timezone ?? tz;
  return utcToZonedTime(date, timeZone);
}
