import {
  Department,
  DepartmentRequest,
  UpdateDepartmentRequest,
} from '../interfaces/department-interface';
import { useActiveWorkspaceSlice } from '../store/active-workspace-slice';
import http from './axios-instance';

const departmentsQueryKey = 'Departments';
const getDepartments = async (): Promise<Department[]> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/department/get`;
  const res = await http.get(url, { headers });
  return res.data;
};

const createDepartment = async (data: DepartmentRequest): Promise<Department> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/department/create`;
  const res = await http.post(url, data, { headers });
  return res.data;
};

const updateDepartment = async (params: UpdateDepartmentRequest): Promise<Department> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/department/edit`;
  const res = await http.patch(url, null, { headers, params });
  return res.data;
};

const deleteDepartment = async (id: Department['id']): Promise<any> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const params = { department_id: id };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/department/delete`;
  const res = await http.delete(url, { headers, params });
  return res.data;
};

const departmentService = {
  departmentsQueryKey,
  getDepartments,
  createDepartment,
  updateDepartment,
  deleteDepartment,
};

export default departmentService;
