import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { useTitle } from 'react-use';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { ForgotPasswordRequest } from '../../interfaces/forgot-password-interface';
import authService from '../../services/auth-service';
import FormError from '../../components/form-error';
import Spinner from '../../components/spinner';
import UnSplashImage from '../../components/libs/unsplash-image';

const schema = yup
  .object({
    email: yup.string().email('Please enter a valid email').required('This field is required'),
  })
  .required();

const ForgotPassword = () => {
  useTitle(`${import.meta.env.VITE_APP_TITLE} | Forgot Password`);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordRequest>({
    resolver: yupResolver<any>(schema),
  });

  const {
    mutate,
    isLoading,
    error: forgotError,
  } = useMutation(authService.forgotPassword, {
    onSuccess: (data, { email }) => {
      toast.success(`Email has been sent.`);
      navigate(`/reset-password?email=${email}`);
    },
  });

  const onSubmit: SubmitHandler<ForgotPasswordRequest> = async (data) => {
    if (isLoading) {
      return;
    }
    mutate(data);
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="fixed top-0 bottom-0 right-0 left-0 z-[1]">
        <UnSplashImage />
      </div>
      <div className="w-full max-w-sm mx-auto bg-white rounded-md shadow-lg lg:max-w-xl p-9 z-50">
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-3xl font-bold ">Forgot your password?</h1>
          <p className="mt-10 text-sm text-center text-gray-600">
            Enter your email address and we will send you a code to reset your password
          </p>
        </div>
        <div className="mt-8">
          <div className="mt-6">
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    autoComplete="email"
                    {...register('email')}
                    className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <p className="pt-2 text-red-500 h-8 first-letter:capitalize">
                  {errors.email?.message}
                </p>
              </div>
              <div>
                <button
                  type="submit"
                  className="flex justify-center w-full px-4 py-4 mt-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {!isLoading ? 'Reset Password' : <Spinner size="small" />}
                </button>
              </div>
              <FormError error={forgotError} takeSpace={true} />
            </form>
            <div className="flex flex-col items-center mt-10 space-y-4">
              <div className="flex items-center w-full">
                <div className="text-sm">
                  <span className="font-medium text-[#374151]">Dont have an account?</span>
                </div>
              </div>
              <Link
                to="/register"
                className="flex justify-center w-full px-4 py-4 mt-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Create an account
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
