import { useState } from 'react';
import { Link } from 'react-router-dom';
// import EState from '../../assets/vectors/empty-state.svg';
import EStateHappy from '../../assets/vectors/empty-state-happy.svg';
import VideoPlayer from './libs/video-player';

interface EmptyStateProps {
  path: string;
  buttonName: string;
  message: string;
  extraMessage?: string;
  showBtn?: boolean;
  showImg?: boolean;
  showVideo?: boolean;
  videoUrl?: string;
  videoTitle?: string;
}

const EmptyState = ({
  path,
  buttonName,
  message,
  extraMessage,
  showBtn = true,
  showImg = true,
  showVideo = false,
  videoUrl = '',
  videoTitle = '',
}: EmptyStateProps) => {
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);

  const handleOpenShowVideoPlayer = () => {
    setShowVideoPlayer(true);
  };

  const handleCloseShowVideoPlayer = () => {
    setShowVideoPlayer(false);
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center min-h-full py-4 mx-auto overflow-y-auto max-w-7xl">
        <h1 className="max-w-3xl text-4xl font-semibold text-center text-gray-600">{message}</h1>
        {extraMessage ? (
          <p className="max-w-3xl text-center text-gray-400 mt-9">{extraMessage}</p>
        ) : null}
        {showVideo ? (
          <button
            onClick={handleOpenShowVideoPlayer}
            className="mt-6 text-sm text-center text-blue-600 underline transition w-52 underline-offset-2 hover:text-blue-800"
          >
            Click Here to see a video
          </button>
        ) : null}
        {showBtn ? (
          <Link
            to={path}
            type="button"
            className="flex items-center justify-center mt-6 text-sm font-medium text-white transition rounded shadow shrink-0 h-14 w-52 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500"
          >
            {buttonName}
          </Link>
        ) : null}
        {showImg ? <img src={EStateHappy} className="mt-6 w-[40%]" /> : null}
      </div>
      <VideoPlayer
        closeModal={handleCloseShowVideoPlayer}
        show={showVideoPlayer}
        title={videoTitle}
        url={videoUrl}
      />
    </>
  );
};

export default EmptyState;
