import { useQuery } from '@tanstack/react-query';
import staffsService from '../services/staffs-service';
import { useActiveWorkspaceSlice } from '../store/active-workspace-slice';

export function useEmployees() {
  const activeWorkspace = useActiveWorkspaceSlice((state) => state.workspace);
  return useQuery([staffsService.staffsQueryKey, activeWorkspace?.id], staffsService.getStaffs, {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 3,
  });
}
