import { Disclosure } from '@headlessui/react';
import {
  RectangleStackIcon,
  HomeIcon,
  CalendarDaysIcon,
  Cog6ToothIcon,
} from '@heroicons/react/24/outline';
import { NavLink } from 'react-router-dom';
import { classNames } from '../../../utils/tailwind-class-names';

const navigation = [
  { name: 'Dashboard', end: true, href: '#', icon: HomeIcon },
  { name: 'Calendar', end: false, href: '#', icon: CalendarDaysIcon },
  {
    name: 'Employee Workspace',
    end: false,
    href: '#',
    icon: RectangleStackIcon,
    children: [
      { name: 'Attendance', end: false, href: '#' },
      { name: 'Leave Requests', end: false, href: '#' },
      { name: 'Employees', end: false, href: '#' },
    ],
  },
  {
    name: 'Settings',
    end: false,
    href: '#',
    icon: Cog6ToothIcon,
    children: [
      { name: 'Department', end: false, href: '#' },
      { name: 'Job Title', end: false, href: '#' },
      { name: 'Leave Group', end: false, href: '#' },
      { name: 'Leave Type', end: false, href: '#' },
    ],
  },
];

const MockSideBar = () => {
  return (
    <div className="relative flex h-full">
      <div className="hidden lg:flex lg:flex-shrink-0">
        <div className="flex flex-col w-[270px]">
          <div className="flex flex-col flex-1 min-h-0 bg-gray-100 border-r border-gray-200">
            <nav className="flex-1 pt-5 pb-4 overflow-y-auto" aria-label="Sidebar">
              <div className="px-2 space-y-2">
                {navigation.map((item) =>
                  !item.children ? (
                    <NavLink
                      key={item.name}
                      {...(item.end && { end: true })}
                      to={item.href}
                      className={({ isActive }) => {
                        return `text-gray-600group flex items-center p-3 text-sm font-medium rounded-md`;
                      }}
                    >
                      {({ isActive }) => (
                        <>
                          <item.icon
                            className={classNames(
                              isActive
                                ? 'text-gray-500'
                                : 'text-gray-400 group-hover:text-gray-500',
                              'mr-3 h-6 w-6',
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </>
                      )}
                    </NavLink>
                  ) : (
                    <Disclosure as="div" key={item.name} className="space-y-1">
                      {({ open }) => {
                        return (
                          <>
                            <Disclosure.Button
                              className={classNames(
                                open
                                  ? 'bg-gray-200 text-gray-900'
                                  : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                'group w-full flex items-center p-3 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500',
                              )}
                            >
                              <item.icon
                                className="flex-shrink-0 w-6 h-6 mr-3 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"
                              />
                              <span className="flex-1">{item.name}</span>
                              <svg
                                className={classNames(
                                  open ? 'text-gray-400 rotate-90' : 'text-gray-300',
                                  'ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150',
                                )}
                                viewBox="0 0 20 20"
                                aria-hidden="true"
                              >
                                <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                              </svg>
                            </Disclosure.Button>
                            <Disclosure.Panel className="space-y-1">
                              {item.children.map((subItem) => (
                                <NavLink
                                  key={subItem.name}
                                  to={subItem.href}
                                  className={({ isActive }) => {
                                    return `${
                                      isActive
                                        ? 'bg-gray-200 text-gray-900'
                                        : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                                    } group flex items-center p-3 pl-11 text-sm font-medium rounded-md`;
                                  }}
                                >
                                  {subItem.name}
                                </NavLink>
                              ))}
                            </Disclosure.Panel>
                          </>
                        );
                      }}
                    </Disclosure>
                  ),
                )}
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div className="absolute top-0 bottom-0 left-0 right-0 z-40 blur-sm bg-gray-200/20"></div>
    </div>
  );
};

export default MockSideBar;
