import Dialog from '@mui/material/Dialog';
import { AxiosError } from 'axios';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useEmployees } from '../../../hooks/use-employees';
import { Employee } from '../../../interfaces/employee-interface';
import { capitalizeFirstLetter } from '../../../utils/capitalize-first-letter';
import staffsService from '../../../services/staffs-service';
import { customToastError } from '../../../utils/custom-toast-error';

interface IForm {
  title: string;
  body: string;
}

interface SingleNotificationModalProps {
  show: boolean;
  closeModal: () => void;
  empId: Employee['id'] | null;
}

const schema = yup
  .object({
    body: yup
      .string()
      .min(0, 'too short')
      .max(255, 'too long')
      .required('Please provide a Message'),
  })
  .required();

const SingleNotificationModal = ({ show, closeModal, empId }: SingleNotificationModalProps) => {
  const { data: employees = [] } = useEmployees();
  const emp = employees.find((e) => e.id === empId);
  const empName = emp ? `${emp?.first_name} ${emp?.last_name}` : '';
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<IForm>({
    resolver: yupResolver<any>(schema),
  });
  const body = watch('body');
  const currentBodyLength = 255 - (body ?? '').length;

  const {
    mutate,
    isLoading,
    reset: resetMutation,
  } = useMutation(staffsService.sendNotification, {
    onSuccess: () => {
      toast.success('Notification Sent.');
      handleCloseModal();
    },
    onError: (data: AxiosError) => {
      const err = data.response?.data as any;
      customToastError('Error', err?.message ?? 'Sorry there was a problem');
      // console.log(data.response?.data);
    },
  });

  const onSubmit: SubmitHandler<IForm> = (data) => {
    if (isLoading) {
      return;
    }
    mutate({ ...data, title: 'You have a Message', staff_ids: [empId!] });
  };

  const handleCloseModal = () => {
    resetMutation();
    reset({
      body: '',
    });
    closeModal();
  };

  return (
    <Dialog onClose={handleCloseModal} open={show} fullWidth={true} maxWidth={'sm'}>
      <div className="flex flex-col w-full p-6">
        <div className="pb-6 text-lg font-medium leading-6 text-gray-900 border-b">
          Sending Notification to {capitalizeFirstLetter(empName)}
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-2">
          <div className="grid grid-cols-1 m-6 md:m-0 md:mt-4 gap-y-2 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <label
                htmlFor="body"
                className="flex items-center justify-between text-sm font-medium text-gray-700"
              >
                <span>Message</span>
                <span className={`text-sm ${errors.body?.message ? 'text-red-500' : ''}`}>
                  {currentBodyLength >= 0 ? currentBodyLength : 0}/255
                </span>
              </label>
              <div className="mt-2">
                <textarea
                  id="body"
                  rows={4}
                  {...register('body')}
                  className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <p className="h-6 pt-2 text-sm text-red-500 first-letter:capitalize">
                {errors.body?.message}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between w-full pt-4 h-[72px] mx-6 mt-4 border-t md:mx-0">
            <button
              type="button"
              onClick={handleCloseModal}
              className="h-10 text-sm tracking-wide text-white transition bg-red-500 border border-transparent rounded shadow-sm w-28 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Close
            </button>
            <button
              type="submit"
              className="h-10 text-sm tracking-wide text-white transition bg-indigo-500 border border-transparent rounded shadow-sm w-28 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Send
            </button>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default SingleNotificationModal;
