import { create } from 'zustand';
import { StaffClockInReport } from '../interfaces/clockin-reports-interface';

type State = {
  worktime: StaffClockInReport | null;
  isWorktimeOpen: boolean;
  isCreateWorktimeModalOpen: boolean;
  clock: StaffClockInReport['clocks'][0] | null;
  isClockOpen: boolean;
  break: StaffClockInReport['clocks'][0]['breaks'][0] | null;
  isBreakOpen: boolean;
};

type Actions = {
  updateWorktime: (worktime: StaffClockInReport | null) => void;
  updateIsWorktimeOpen: (isWorktimeOpen: boolean) => void;
  updateClock: (clock: StaffClockInReport['clocks'][0] | null) => void;
  updateIsClockOpen: (isClockOpen: boolean) => void;
  updateBreak: (br: StaffClockInReport['clocks'][0]['breaks'][0] | null) => void;
  updateIsBreakOpen: (isBreakOpen: boolean) => void;
  updateIsCreateWorktimeModalOpen: (isCreateWorktimeModalOpen: boolean) => void;
  reset: () => void;
};

const initialState: State = {
  worktime: null,
  isWorktimeOpen: false,
  clock: null,
  isClockOpen: false,
  break: null,
  isBreakOpen: false,
  isCreateWorktimeModalOpen: false,
};

export const useAttendanceClockSlice = create<State & Actions>()((set) => ({
  ...initialState,
  updateWorktime: (worktime) => {
    set({ worktime });
  },
  updateIsWorktimeOpen: (isWorktimeOpen) => {
    set({ isWorktimeOpen });
  },
  updateClock: (clock) => {
    set({ clock });
  },
  updateIsClockOpen: (isClockOpen) => {
    set({ isClockOpen });
  },
  updateBreak: (br) => {
    set({ break: br });
  },
  updateIsBreakOpen: (isBreakOpen) => {
    set({ isBreakOpen });
  },
  updateIsCreateWorktimeModalOpen: (isCreateWorktimeModalOpen) => {
    set({ isCreateWorktimeModalOpen });
  },
  reset: () => {
    set({ ...initialState });
  },
}));
