import { useQuery } from '@tanstack/react-query';
import notificationService from '../services/notification-service';

export function useCustomNotifications(page?: number, offset?: number) {
  return useQuery(
    [notificationService.customNotificationsQueryKey, page, offset],
    () => notificationService.getCustomNotifications({ page, offset }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  );
}
