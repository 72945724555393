import http from './axios-instance';
import { useActiveWorkspaceSlice } from '../store/active-workspace-slice';
import {
  CreateClock,
  CreateClockResponse,
  UpdateBreak,
  UpdateBreakResponse,
  UpdateBuiltByStaff,
  UpdateClock,
  UpdateClockResponse,
} from '../interfaces/clock-interface';
import {
  CreateWorkTimeAttendanceRequest,
  CreateWorkTimeAttendanceResponse,
} from '../interfaces/create-worktime-attendance';
import {
  ChangeClockStatusRequest,
  ChangeClockStatusResponse,
} from '../interfaces/change-clock-status';
import {
  RejectClockAttendanceRequest,
  RejectClockAttendanceResponse,
} from '../interfaces/reject-clock-attendance';
import {
  ChangeScheduleStatusRequest,
  ChangeScheduleStatusResponse,
} from '../interfaces/change-schedule-status';
import {
  RejectScheduleAttendanceRequest,
  RejectScheduleAttendanceResponse,
} from '../interfaces/reject-schedule-attendance';

const createClock = async (body: CreateClock): Promise<CreateClockResponse> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/clock/create`;
  const res = await http.post(url, body, { headers });
  return res.data;
};

const changeClockStatus = async (
  body: ChangeClockStatusRequest,
): Promise<ChangeClockStatusResponse> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/clock/change_status`;
  const res = await http.post(url, body, { headers });
  return res.data;
};

const rejectClockAttendance = async (
  body: RejectClockAttendanceRequest,
): Promise<RejectClockAttendanceResponse> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/clock/reject_clock_attendance`;
  const res = await http.post(url, body, { headers });
  return res.data;
};

const changeScheduleStatus = async (
  body: ChangeScheduleStatusRequest,
): Promise<ChangeScheduleStatusResponse> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/schedule/change_status`;
  const res = await http.post(url, body, { headers });
  return res.data;
};

const rejectScheduleAttendance = async (
  body: RejectScheduleAttendanceRequest,
  source: 'CALENDAR' | 'ATTENDANCE',
): Promise<RejectScheduleAttendanceResponse> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/schedule/${
    source === 'CALENDAR' ? 'reject_object_calendar' : 'reject_object_attendance'
  }`;
  const res = await http.post(url, body, { headers });
  return res.data;
};

const createWorkTimeAttendance = async (
  body: CreateWorkTimeAttendanceRequest,
): Promise<CreateWorkTimeAttendanceResponse> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/schedule/create_object_attendance`;
  const res = await http.post(url, body, { headers });
  return res.data;
};

const clockOutStaff = async (staffId: number): Promise<{ message: string; status: string }> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/clock/out`;
  const res = await http.post(url, { staff_id: staffId }, { headers });
  return res.data;
};

const updateClock = async (params: UpdateClock): Promise<UpdateClockResponse> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/clock/edit`;
  const res = await http.patch(url, null, { headers, params });
  return res.data;
};

const deleteClock = async (id: number): Promise<any> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const params = { clock_id: id };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/clock/delete`;
  const res = await http.delete(url, { headers, params });
  return res.data;
};

const updateBreak = async (params: UpdateBreak): Promise<UpdateBreakResponse> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/clock/edit_break`;
  const res = await http.patch(url, null, { headers, params });
  return res.data;
};

const deleteBreak = async (id: number): Promise<any> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const params = { break_id: id };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/clock/delete_break`;
  const res = await http.delete(url, { headers, params });
  return res.data;
};

const changesBuiltByStaff = async (params: UpdateBuiltByStaff): Promise<any> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/clock/changes_built_by_staff`;
  const res = await http.patch(url, null, { headers, params });
  return res.data;
};

const clockService = {
  createClock,
  updateClock,
  deleteClock,
  updateBreak,
  deleteBreak,
  changesBuiltByStaff,
  createWorkTimeAttendance,
  changeClockStatus,
  rejectClockAttendance,
  changeScheduleStatus,
  rejectScheduleAttendance,
  clockOutStaff,
};

export default clockService;
