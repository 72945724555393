import { useRef, useState } from 'react';
import { useTitle } from 'react-use';
import PencilIcon from '~icons/carbon/edit';
import LockIcon from '~icons/mdi/lock-outline';
import LockOpenIcon from '~icons/mdi/lock-open-variant-outline';
import { formatDateToTimeZone } from '../../utils/format-date';
import Spinner from '../../components/spinner';
import { useShiftsRequest } from '../../hooks/use-shifts-request';
import ChangeStatusModal from './components/change-status-modal';
import { ShiftRequest } from '../../interfaces/shift-interface';
import { EyeIcon } from '@heroicons/react/24/solid';
import { Tooltip } from '@mui/material';
import { capitalizeFirstLetter } from '../../utils/capitalize-first-letter';

export type SelectedShift = {
  staffsStatus: boolean;
  name: string;
  shiftId: number;
  staffId: number;
  staffs: any;
  status: string;
  wtStart: number;
  wtEnd: number;
  wtId: number;
} | null;

const ShiftsRequest = () => {
  useTitle(`${import.meta.env.VITE_APP_TITLE} | Shifts Swap Requests`);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [page, setPage] = useState(1);
  const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);
  const [selectedShift, setSelectedShift] = useState<SelectedShift>(null);
  // const firebaseMessage = useFirebaseSlice((state) => state.message);
  const { data: shifts, isLoading: shiftsIsLoading } = useShiftsRequest(page);

  const handleEditShift = (shift: ShiftRequest['data'][0]) => {
    if (shift.status === 'approved') {
      return;
    }
    setSelectedShift({
      staffsStatus: false,
      name: `${shift.staff.first_name} ${shift.staff.last_name}`,
      shiftId: shift.id,
      staffId: shift.staff_id,
      staffs: shift.staffs,
      status: shift.status,
      wtStart: shift.work_object.start_at,
      wtEnd: shift.work_object.end_at,
      wtId: shift.work_object.id,
    });
    openChangeStatusModal();
  };

  const handleShiftDetails = (shift: ShiftRequest['data'][0]) => {
    if (shift.status !== 'waiting_for_staff_approval') {
      return;
    }
    setSelectedShift({
      staffsStatus: true,
      name: `${shift.staff.first_name} ${shift.staff.last_name}`,
      shiftId: shift.id,
      staffId: shift.staff_id,
      staffs: shift.staffs,
      status: shift.status,
      wtStart: shift.work_object.start_at,
      wtEnd: shift.work_object.end_at,
      wtId: shift.work_object.id,
    });
    openChangeStatusModal();
  };

  const openChangeStatusModal = () => {
    setShowChangeStatusModal(true);
  };

  const closeChangeStatusModal = () => {
    setShowChangeStatusModal(false);
  };

  const handleNextPage = () => {
    if (shifts && page < shifts.last_page) {
      setPage((oldPage) => oldPage + 1);
    }
    containerRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const handlePrevPage = () => {
    if (shifts && page > 1) {
      setPage((oldPage) => oldPage - 1);
    }
    containerRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  if (shiftsIsLoading && page === 1) {
    return (
      <div className="flex items-end justify-center w-full my-9">
        <span className="mr-4">Please wait</span>
        <Spinner size="small" />
      </div>
    );
  }

  return (
    <>
      <div className="w-full mx-auto space-y-8 pb-14" ref={containerRef}>
        <div className="flex flex-col items-center justify-center w-full p-4 bg-white border border-gray-100 rounded-md shadow-lg md:p-9">
          <div className="flex flex-col w-full pb-6 mb-6 font-medium border-b">
            <span>Shift Swap Requests</span>
            <p className="mt-2 text-sm font-normal text-gray-400">
              This feature allows your employees to swap their shifts. They can submit a shift swap
              request using the app. Here, you’ll be able to accept, decline, or offer a
              replacement.
            </p>
          </div>
          <div className="flex flex-col w-full">
            <div className="grid items-center w-full h-16 grid-cols-12 text-gray-700 bg-gray-50">
              <div className="flex items-center col-span-6 pl-4 text-sm font-semibold md:flex md:col-span-2">
                Name
              </div>
              <div className="items-center hidden col-span-2 pl-4 text-sm font-semibold md:flex">
                Job
              </div>
              <div className="items-center hidden col-span-2 pl-4 text-sm font-semibold md:flex">
                Shift Start
              </div>
              <div className="items-center hidden col-span-2 pl-4 text-sm font-semibold md:flex">
                Shift End
              </div>
              <div className="items-center hidden col-span-2 pl-4 text-sm font-semibold md:flex">
                Status
              </div>
              <div className="flex items-center col-span-6 pl-4 text-sm font-semibold md:flex md:col-span-2">
                Actions
              </div>
            </div>
            <div className="flex flex-col w-full divide-y divide-gray-100">
              {shifts?.data.map((shift) => {
                const status = shift?.status?.split('_').join(' ') ?? 'Pending';
                return (
                  <div
                    key={shift.id}
                    className={`grid w-full grid-cols-12 ${
                      shift.is_locked ? 'text-gray-300' : 'text-gray-700'
                    }`}
                  >
                    <div className="flex items-center h-20 col-span-6 pl-4 text-sm font-normal md:col-span-2">
                      <div className="flex items-center w-full space-x-2 text-left first-letter:capitalize">
                        {shift.is_locked ? (
                          <Tooltip title="Request is locked">
                            <span>
                              <LockIcon
                                className={`w-6 h-6 ${
                                  shift.is_locked ? 'text-gray-300' : 'text-gray-400'
                                }`}
                              />
                            </span>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Request can be modify">
                            <span>
                              <LockOpenIcon
                                className={`w-6 h-6 ${
                                  shift.is_locked ? 'text-gray-300' : 'text-gray-400'
                                }`}
                              />
                            </span>
                          </Tooltip>
                        )}
                        <span
                          title={capitalizeFirstLetter(
                            `${shift?.staff?.first_name} ${shift?.staff?.last_name}`,
                          )}
                          className="w-full text-left capitalize line-clamp-2"
                        >
                          {shift?.staff?.first_name} {shift?.staff?.last_name}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center h-20 col-span-6 pl-4 text-sm font-normal capitalize md:col-span-2">
                      {shift?.staff?.job?.title ?? '-'}
                    </div>
                    <div className="flex items-center h-20 col-span-6 pl-4 text-sm font-normal md:col-span-2">
                      {shift?.work_object?.start_at
                        ? formatDateToTimeZone(shift?.work_object?.start_at, 'dd-MM-y HH:mm')
                        : '-'}
                    </div>
                    <div className="flex items-center h-20 col-span-6 pl-4 text-sm font-normal md:col-span-2">
                      {shift?.work_object?.end_at
                        ? formatDateToTimeZone(shift?.work_object?.end_at, 'dd-MM-y HH:mm')
                        : '-'}
                    </div>
                    <div className="flex items-center h-20 col-span-6 pl-4 space-x-2 text-sm font-normal md:col-span-2">
                      <span
                        className={`flex items-center justify-center capitalize rounded text-xs w-full max-w-[144px] text-center line-clamp-2 text-white h-10 leading-[14px] ${
                          status === 'pending' ? 'bg-pending-status' : ''
                        } ${status === 'declined' ? 'bg-reject-status' : ''} ${
                          status === 'waiting for staff approval' ? 'bg-waiting-status' : ''
                        } ${status === 'approved' ? 'bg-green-500' : ''}`}
                      >
                        {status === 'waiting for staff approval' ? 'waiting for approval' : status}
                      </span>
                      {/* {shift?.approved_by_admin > 0 ? (
                        <span className="p-1 px-2 text-xs tracking-wide text-center text-white bg-green-500 rounded">
                          ADMIN
                        </span>
                      ) : null} */}
                    </div>
                    <div className="flex items-center h-20 col-span-6 pl-4 space-x-2 text-sm font-normal md:col-span-2">
                      <button
                        type="button"
                        className="text-gray-400 transition disabled:text-gray-300 hover:text-gray-800"
                        onClick={() => handleEditShift(shift)}
                        disabled={
                          shift.status === 'approved' ||
                          shift.status === 'declined' ||
                          shift.is_locked
                        }
                      >
                        <PencilIcon className={`w-6 h-6`} />
                      </button>
                      <button
                        type="button"
                        className="text-gray-400 transition disabled:text-gray-300 hover:text-gray-800"
                        disabled={shift.status !== 'waiting_for_staff_approval' || shift.is_locked}
                        onClick={() => handleShiftDetails(shift)}
                      >
                        <EyeIcon className={`w-6 h-6`} />
                      </button>
                    </div>
                    {shift.reason ? (
                      <div className="flex items-center h-12 col-span-12 pl-2 space-x-2 text-xs font-medium text-left lg:pl-6">
                        <span className="text-yellow-500">Reason:</span>
                        <span className="line-clamp-3">{capitalizeFirstLetter(shift.reason)}</span>
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>
          <nav
            className="flex items-center justify-between w-full pt-6 bg-white border-t border-gray-200"
            aria-label="Pagination"
          >
            <div className="hidden sm:block">
              <p className="text-sm text-gray-700">
                Showing <span className="font-medium">{shifts?.from ?? 0}</span> to{' '}
                <span className="font-medium">{shifts?.to ?? 0}</span> of{' '}
                <span className="font-medium">{shifts?.total ?? 0}</span> results
              </p>
            </div>
            <div className="flex justify-between flex-1 sm:justify-end">
              <button
                className="relative py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md w-36 hover:bg-gray-50"
                onClick={handlePrevPage}
              >
                Previous Page
              </button>
              <button
                className="relative py-2 ml-4 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md w-36 hover:bg-gray-50"
                onClick={handleNextPage}
              >
                Next Page
              </button>
            </div>
          </nav>
        </div>
      </div>
      <ChangeStatusModal
        show={showChangeStatusModal}
        closeModal={closeChangeStatusModal}
        selectedShift={selectedShift}
      />
    </>
  );
};

export default ShiftsRequest;
