import { useQuery } from '@tanstack/react-query';
import workspaceService from '../services/workspace-service';

export function useDashboard(start: number, end: number, id: number | undefined) {
  return useQuery(
    [workspaceService.dashboardQueryKey, start, end, id],
    () => workspaceService.getDashboard({ start, end }),
    {
      refetchOnWindowFocus: true,
    },
  );
}
