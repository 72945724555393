import { useState } from 'react';
import { isSameDay } from 'date-fns';
import { useCalendarSlice } from '../../../store/calendar-slice';
import { dateUtc, formatDateToTimeZone } from '../../../utils/format-date';
import { EyeIcon } from '@heroicons/react/24/solid';
import PencilIcon from '~icons/carbon/edit';
import { useQueryClient } from '@tanstack/react-query';
// import { allowToModify } from '../../../utils/allow-to-modify';
import { SelectedShift } from '../../shifts-request/shifts-request';
import ChangeStatusModal from '../../shifts-request/components/change-status-modal';
import calendarService from '../../../services/calendar-service';
import { Calendar } from '../../../interfaces/calendar-interface';
import { ChangeShiftResponse } from '../../../interfaces/change-shift-interface';
import { ArrowUpCircleIcon } from '@heroicons/react/24/outline';

const ShiftsList = () => {
  const queryClient = useQueryClient();
  const activeResource = useCalendarSlice((state) => state.activeResource);
  const selectedDate = useCalendarSlice((state) => state.selectedDate);
  const toggleScheduleModal = useCalendarSlice((state) => state.updateShowScheduleModal);
  const updateActiveResource = useCalendarSlice((state) => state.updateActiveResource);
  const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);
  const [selectedShift, setSelectedShift] = useState<SelectedShift>(null);
  // const canModify = selectedDate ? allowToModify(selectedDate) : false;
  const schedules =
    activeResource?.schedules.filter((ev) => {
      if (selectedDate) {
        return isSameDay(dateUtc(ev.start_at), dateUtc(selectedDate));
      }
      return false;
    }) ?? [];

  const handleEditShift = (wt: Calendar, shift: Calendar['shift_requests'][0]) => {
    if (shift.status === 'approved') {
      return;
    }
    setSelectedShift({
      staffsStatus: false,
      name: activeResource!.name,
      shiftId: shift.id,
      staffId: shift.staff_id,
      staffs: shift.staffs,
      status: shift.status,
      wtStart: wt.start_at,
      wtEnd: wt.end_at,
      wtId: wt.id,
    });
    openChangeStatusModal();
  };

  const handleShiftDetails = (wt: Calendar, shift: Calendar['shift_requests'][0]) => {
    setSelectedShift({
      staffsStatus: true,
      name: activeResource!.name,
      shiftId: shift.id,
      staffId: shift.staff_id,
      staffs: shift.staffs,
      status: shift.status,
      wtStart: wt.start_at,
      wtEnd: wt.end_at,
      wtId: wt.id,
    });
    openChangeStatusModal();
  };

  const openChangeStatusModal = () => {
    setShowChangeStatusModal(true);
  };

  const closeChangeStatusModal = (data?: ChangeShiftResponse) => {
    if (data) {
      updateActiveResource({
        ...activeResource!,
        schedules: activeResource!.schedules.map((e) => {
          return {
            ...e,
            shift_requests: e.shift_requests.map((s) => {
              if (data!.id === s.id) {
                return data;
              }
              return s;
            }),
          };
        }),
      });
    }
    queryClient.invalidateQueries([calendarService.workingTimesQueryKey]);
    setShowChangeStatusModal(false);
  };

  const handleCloseModal = () => {
    toggleScheduleModal(false);
  };

  return (
    <>
      <div className="flex flex-col flex-1 w-full h-full">
        <div className="overflow-hidden overflow-y-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg h-[calc(100%-64px)]">
          <div className="min-w-full divide-y divide-gray-300">
            <div className="bg-gray-50">
              <div className="grid grid-cols-4">
                <div className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                  Name
                </div>
                <div className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                  Time
                </div>
                <div className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                  Status
                </div>
                <div className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                  Actions
                </div>
              </div>
            </div>
            <div className="bg-white divide-y divide-gray-200">
              {schedules?.map((wt) => {
                return (
                  <div key={wt.id}>
                    {wt.shift_requests.map((shift) => {
                      const status = shift?.status?.split('_').join(' ') ?? 'Pending';
                      return (
                        <div key={shift.id}>
                          <div className="grid grid-cols-4 h-14">
                            <div className="px-2 text-sm text-gray-500">
                              <div className="flex flex-col items-center justify-center h-full">
                                <div className="font-semibold text-center text-gray-900">
                                  <span className="capitalize line-clamp-1">
                                    {activeResource?.name}
                                  </span>
                                </div>
                                <div className="text-center text-gray-500">
                                  <span className="capitalize line-clamp-1">
                                    {activeResource?.jobTitle}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="px-2 text-sm text-gray-500">
                              <div className="flex flex-col items-center justify-center h-full">
                                <div className="font-semibold text-center text-gray-900">
                                  {formatDateToTimeZone(wt.start_at, 'HH:mm')} -{' '}
                                  {formatDateToTimeZone(wt.end_at, 'HH:mm')}
                                </div>
                                <div className="text-center text-gray-500">
                                  {formatDateToTimeZone(wt.start_at, 'dd-MM-y')}
                                </div>
                              </div>
                            </div>
                            <div className="px-2 text-sm text-gray-500">
                              <div className="flex items-center justify-center h-full text-center text-gray-900">
                                <span className="capitalize line-clamp-2">{status}</span>
                              </div>
                            </div>
                            <div className="relative px-2 text-sm font-medium text-center">
                              <div className="flex items-center justify-center h-full space-x-4">
                                <button
                                  type="button"
                                  disabled={shift.status === 'approved' || shift.is_locked}
                                  className="text-gray-400 transition disabled:text-gray-300 hover:text-gray-800"
                                  onClick={() => handleEditShift(wt, shift)}
                                >
                                  <PencilIcon className="w-6 h-6" />
                                </button>
                                {shift.status === 'waiting_for_staff_approval' ? (
                                  <button
                                    type="button"
                                    disabled={
                                      shift.status !== 'waiting_for_staff_approval' ||
                                      shift.is_locked
                                    }
                                    className="text-gray-400 transition disabled:text-gray-300 hover:text-gray-800"
                                    onClick={() => handleShiftDetails(wt, shift)}
                                  >
                                    <EyeIcon className="w-6 h-6" />
                                  </button>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="grid h-8 grid-cols-1 text-sm text-white place-content-center">
                            {status === 'approved' && (
                              <div className="flex items-center h-full px-4 py-1 bg-indigo-400">
                                <ArrowUpCircleIcon className="w-6 h-6 mr-2" /> Shift received from{' '}
                                {shift?.staff?.first_name} {shift?.staff?.last_name}
                              </div>
                            )}
                            {status === 'declined' && (
                              <div className="flex items-center h-full px-4 py-1 bg-red-400">
                                <ArrowUpCircleIcon className="w-6 h-6 mr-2" /> Declined shift change
                                request
                              </div>
                            )}
                            {status === 'pending' && (
                              <div className="flex items-center h-full px-4 py-1 bg-orange-400">
                                <ArrowUpCircleIcon className="w-6 h-6 mr-2" /> Pending shift change
                                request
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between pt-6 text-base text-blue-500">
          <button
            type="button"
            onClick={handleCloseModal}
            className="h-10 text-sm tracking-wide text-white transition bg-red-500 border border-transparent rounded shadow-sm w-28 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Close
          </button>
        </div>
      </div>
      <ChangeStatusModal
        show={showChangeStatusModal}
        closeModal={closeChangeStatusModal}
        selectedShift={selectedShift}
      />
    </>
  );
};

export default ShiftsList;
