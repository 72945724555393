import http from './axios-instance';

interface AppLogin {
  status: string;
  data: {
    token: string;
    synced: Synced[];
  };
}
interface Synced {
  business_id: number;
  work_space_id: number;
}

interface WSList {
  id: number;
  sub_domain: string;
  title: string;
  staffCount?: number;
  created_at?: string;
  updated_at?: string;
}

interface AddBusinessResponse {
  status: string;
  message: string;
}

const appSyncLogin = async (data: {
  email: string;
  password: string;
  app_user_id: string;
  app_name: string;
}): Promise<AppLogin> => {
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/linked_apps/login`;
  const res = await http.post(url, data);
  return res.data;
};

const appSyncGetWorkspace = async (token: string): Promise<WSList[]> => {
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/linked_apps/get_workspaces`;
  const res = await http.get(url, { headers: { Authorization: `Bearer ${token}` } });
  return res.data;
};

const appSyncAddBusiness = async (data: {
  business_id: number | string;
  work_space_id: number;
  token: string;
}): Promise<AddBusinessResponse> => {
  const { token, ...rest } = data;
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/linked_apps/add_business`;
  const res = await http.post(url, rest, { headers: { Authorization: `Bearer ${token}` } });
  return res.data;
};

const appSyncLogout = async (token: string): Promise<any> => {
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/linked_apps/logout`;
  const res = await http.post(url, { headers: { Authorization: `Bearer ${token}` } });
  return res.data;
};

const appSyncService = {
  appSyncLogin,
  appSyncGetWorkspace,
  appSyncAddBusiness,
  appSyncLogout,
};

export default appSyncService;
