import { useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useCalendarSlice } from '../../../store/calendar-slice';
import { dateUtc, formatDateToTimeZone, utcDate } from '../../../utils/format-date';
import {
  getHours,
  getMinutes,
  isAfter,
  isBefore,
  setHours,
  setMinutes,
  millisecondsToMinutes,
  minutesToMilliseconds,
  addMinutes,
  isSameDay,
  addMilliseconds,
  isEqual,
} from 'date-fns';
import { Calendar } from '../../../interfaces/calendar-interface';
import calendarService from '../../../services/calendar-service';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from '@mui/material/TextField';
import { AxiosError } from 'axios';
import { FormControl, MenuItem, Select, useMediaQuery } from '@mui/material';
import { customToastError } from '../../../utils/custom-toast-error';

interface IForm {
  working_object_id: any;
  type: string;
  start_at?: any;
  totall: number;
  paid: boolean;
}

const schema = yup
  .object({
    working_object_id: yup
      .number()
      .typeError('This field is required')
      .required('This field is required'),
    type: yup.string().required(),
    paid: yup.boolean().required(),
    start_at: yup.string().nullable(),
    totall: yup
      .number()
      .positive()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required('this field is required'),
  })
  .required();

const AddBreak = () => {
  const matches = useMediaQuery('(min-width:1024px)');
  const queryClient = useQueryClient();
  const selectedDate = useCalendarSlice((state) => state.selectedDate);
  const activeResource = useCalendarSlice((state) => state.activeResource);
  const toggleScheduleModal = useCalendarSlice((state) => state.updateShowScheduleModal);
  const updateScheduleModalTab = useCalendarSlice((state) => state.updateScheduleModalTab);
  const updateBreakForUpdate = useCalendarSlice((state) => state.updateBreakForUpdate);
  const updateActiveResource = useCalendarSlice((state) => state.updateActiveResource);
  const breakForUpdate = useCalendarSlice((state) => state.breakForUpdate);
  const schedules =
    activeResource?.schedules.filter((ev) => {
      if (selectedDate) {
        return isSameDay(dateUtc(ev.start_at), dateUtc(selectedDate));
      }
      return false;
    }) ?? [];
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    control,
  } = useForm<IForm>({
    defaultValues: {
      working_object_id: '',
      type: 'fix',
      paid: false,
      start_at: null,
    },
    resolver: yupResolver<any>(schema),
  });
  const options: any[] =
    schedules.map((s) => {
      return {
        value: s.id,
        label: `${formatDateToTimeZone(s.start_at, 'HH:mm')} - ${formatDateToTimeZone(
          s.end_at,
          'HH:mm',
        )}`,
      };
    }) ?? [];

  useEffect(() => {
    if (breakForUpdate) {
      if (breakForUpdate.start_at) {
        setValue('start_at', dateUtc(breakForUpdate.start_at));
      }
      setValue('type', breakForUpdate.type);
      setValue('working_object_id', breakForUpdate.working_object_id);
      setValue('totall', millisecondsToMinutes(breakForUpdate.totall));
    } else {
      if (schedules[0]) {
        setValue('working_object_id', schedules[0].id);
      }
    }
  }, [breakForUpdate]);

  const type = watch('type');

  const { mutate: updateBreak, isLoading: updateBreakIsLoading } = useMutation(
    calendarService.updateBreak,
    {
      onSuccess: (data) => {
        const schedules = activeResource!.schedules
          .map((s) => {
            return { ...s, breaks: s.breaks.filter((b) => b.id !== data.id) };
          })
          .map((s) => {
            if (s.id === data.working_object_id) {
              return { ...s, breaks: [...s.breaks, data] };
            }
            return s;
          });
        updateActiveResource({
          ...activeResource!,
          schedules,
        });
        updateBreakForUpdate(null);
        toggleScheduleModal(false);
        queryClient.invalidateQueries([calendarService.workingTimesQueryKey]);
      },
      onError: (data: AxiosError) => {
        const err = data.response?.data as any;
        customToastError('Error', err?.message ?? 'Sorry there was a problem');
      },
    },
  );

  const { mutate: createBreak, isLoading: createBreakIsLoading } = useMutation(
    calendarService.createBreak,
    {
      onSuccess: (data) => {
        updateActiveResource({
          ...activeResource!,
          schedules: activeResource!.schedules.map((s) => {
            if (s.id === data.working_object_id) {
              return { ...s, breaks: [...s.breaks, data] };
            }
            return s;
          }),
        });
        updateBreakForUpdate(null);
        toggleScheduleModal(false);
        queryClient.invalidateQueries([calendarService.workingTimesQueryKey]);
      },
      onError: (data: AxiosError) => {
        const err = data.response?.data as any;
        customToastError('Error', err?.message ?? 'Sorry there was a problem');
      },
    },
  );

  const onSubmit: SubmitHandler<IForm> = (data) => {
    const totall = minutesToMilliseconds(data.totall);
    const wt = activeResource?.schedules.find((s) => s.id === data.working_object_id) as Calendar;
    let start_at: number | null = null;
    if (createBreakIsLoading || updateBreakIsLoading) {
      return;
    }
    if (data.type === 'float' && isAfter(addMilliseconds(wt.start_at, totall), wt.end_at)) {
      customToastError('Error', `Break time exceeds working time`);
      return;
    }
    if (data.type === 'fix') {
      if (!data.start_at) {
        customToastError('Error', `Please select a start time`);
        return;
      }
      const sH = getHours(new Date(data.start_at));
      const sM = getMinutes(new Date(data.start_at));
      const start = setHours(setMinutes(selectedDate!, sM), sH);
      start_at = utcDate(start).getTime();
      if (isBefore(start_at, wt.start_at)) {
        customToastError('Error', `Start time must be after working time start`);
        return;
      }
      if (isAfter(start_at, wt.end_at) || isEqual(start_at, wt.end_at)) {
        customToastError('Error', `Start time must be before working time end`);
        return;
      }
      if (isAfter(addMinutes(start_at, data.totall), wt.end_at)) {
        customToastError('Error', `Break time exceeds working time`);
        return;
      }
    }
    if (!breakForUpdate) {
      if (data.type === 'float') {
        const findWt = activeResource?.schedules.find((sch) => sch.id === data.working_object_id);
        const floatBreak = findWt?.breaks.find((br) => br.type === 'float');
        if (floatBreak) {
          customToastError('Error', `You already have a Float Break.\n Please Edit It.`);
          updateScheduleModalTab('Break List');
          return;
        }
      }
      createBreak({
        working_object_id: data.working_object_id,
        type: data.type,
        ...(type === 'fix' && { start_at }),
        totall,
        paid: data.paid,
      });
    } else {
      updateBreak({
        break_id: breakForUpdate.id,
        type: data.type,
        ...(type === 'fix' && { start_at }),
        totall,
        working_object_id: data.working_object_id,
        paid: data.paid,
      });
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col justify-between w-full h-full space-y-2"
    >
      <div className="w-full divide-y divide-gray-200 h-[calc(100%-64px)] overflow-hidden overflow-y-auto pr-4 pb-14">
        <div className="flex items-center justify-between pb-6 text-sm font-medium">
          <span className="text-gray-700">
            You are scheduling{' '}
            <span className="font-semibold capitalize first-letter:capitalize">
              {activeResource?.name}
            </span>{' '}
            on:
          </span>
          <span className="text-blue-700">
            {formatDateToTimeZone(selectedDate, 'eeee, dd LLL , yyyy')}
          </span>
        </div>
        <div className="grid grid-cols-1 pt-6 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-6">
            <label htmlFor="staffs" className="block text-sm font-medium text-gray-700">
              Working Time
            </label>
            <div className="mt-2">
              <Controller
                name="working_object_id"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth size="small">
                    {/* <InputLabel id="demo-select-small">Type</InputLabel> */}
                    <Select autoWidth={matches ? false : true} label="" {...field}>
                      {options.map((j) => {
                        return (
                          <MenuItem key={j.value} value={j.value}>
                            {j.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
              />
            </div>
            <p className="pt-1 text-sm text-red-500 capitalize h-7 ">
              {(errors as any).working_object_id?.message}
            </p>
          </div>
          <div className="sm:col-span-3">
            <label className="block text-sm font-medium text-gray-700">Break Type</label>
            <fieldset className="mt-4">
              <div className="flex py-2 space-x-4">
                <div className="flex items-center">
                  <input
                    id="fix"
                    {...register('type')}
                    value="fix"
                    type="radio"
                    className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                  />
                  <label htmlFor="fix" className="block pl-3 text-sm font-medium text-gray-700">
                    Fix
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    id="float"
                    {...register('type')}
                    value="float"
                    type="radio"
                    className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                  />
                  <label htmlFor="float" className="block pl-3 text-sm font-medium text-gray-700">
                    Float
                  </label>
                </div>
              </div>
            </fieldset>
          </div>
          {type === 'fix' && (
            <div className="sm:col-span-3">
              {/* <label htmlFor="start" className="block text-sm font-medium text-gray-700">
                Start Time
              </label> */}
              <div className="mt-4">
                {/* <input
                  type="time"
                  id="start"
                  {...register('start_at')}
                  autoComplete="given-name"
                  className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                /> */}
                <Controller
                  name="start_at"
                  control={control}
                  render={({ field }) => (
                    <TimePicker
                      label="Start Time"
                      ampm={false}
                      {...field}
                      renderInput={(params) => (
                        <TextField fullWidth={true} {...params} size="small" />
                      )}
                    />
                  )}
                />
              </div>
            </div>
          )}
          <div className="sm:col-span-6">
            <label htmlFor="total" className="block text-sm font-medium text-gray-700">
              Total (Minutes)
            </label>
            <div className="mt-2">
              <input
                type="number"
                id="total"
                {...register('totall')}
                autoComplete="given-name"
                className="block w-full h-10 px-3 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <p className="h-8 pt-2 text-red-500 first-letter:capitalize">
              {errors.totall?.message}
            </p>
          </div>
          <div className="flex items-center sm:col-span-6">
            <input
              type="checkbox"
              id={'paid'}
              {...register('paid')}
              className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
            />
            <label
              htmlFor={'paid'}
              className="flex-1 block ml-4 mr-4 text-sm font-medium text-gray-700"
            >
              Paid
            </label>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between w-full pt-6 mx-6 mt-6 border-t md:mx-0">
        <button
          type="button"
          onClick={() => toggleScheduleModal(false)}
          className="h-10 text-sm tracking-wide text-white transition bg-red-500 border border-transparent rounded shadow-sm w-28 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          Close
        </button>
        <button
          type="submit"
          className="h-10 text-sm tracking-wide text-white transition bg-indigo-500 border border-transparent rounded shadow-sm w-28 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Confirm
        </button>
      </div>
    </form>
  );
};

export default AddBreak;
