import { intervalToDuration, minutesToMilliseconds } from 'date-fns';

export const convertMinsToHrsMins = (mins: number, withPadd = false) => {
  let h: number | string = Math.floor(mins / 60);
  if (withPadd) {
    h = h < 10 ? '0' + h : h; // (or alternatively) h = String(h).padStart(2, '0')
  }
  const m = mins % 60;
  const mm = m < 10 ? '0' + m : m; // (or alternatively) m = String(m).padStart(2, '0')
  return `${h}:${mm}`;
};

export const convertMinsToHrsMinsSecs = (mins: number, withPadd = false) => {
  let h: number | string = Math.floor(mins / 60);
  if (withPadd) {
    h = h < 10 ? '0' + h : h; // (or alternatively) h = String(h).padStart(2, '0')
  }
  const m = mins % 60;
  const mm = m < 10 ? '0' + m : m; // (or alternatively) m = String(m).padStart(2, '0')
  return `${h}:${mm}:00`;
};

export const convertMToHMWithLabel = (mins: number) => {
  const abs = Math.abs(mins);
  const h = Math.floor(abs / 60);
  const m = abs % 60;
  const mm = String(m).padStart(2, '0');
  return mins < 0 ? `-${h}h:${mm}m` : `${h}h:${mm}m`;
};

export const timeWithLabel = (mins: number, label = '') => {
  if (mins >= 0 && mins < 60) {
    const twl = mins <= 1 ? `${mins} Min ${label}` : `${mins} Mins ${label}`;
    return twl.trim();
  }
  return `${calculateDuration(mins)} ${label}`;
};

const calculateDuration = (mins: number) => {
  const duration = intervalToDuration({ start: 0, end: minutesToMilliseconds(mins) });
  const d = duration.days ?? 0;
  const h = duration.hours ?? 0;
  const m = duration.minutes ?? 0;
  if (d >= 1) {
    return `${d}D:${String(h).padStart(2, '0')}H:${String(m).padStart(2, '0')}M`;
  }
  return `${String(h).padStart(2, '0')}H:${String(m).padStart(2, '0')}M`;
};
