import { create } from 'zustand';
import { Break } from '../interfaces/break-interface';
import { Calendar } from '../interfaces/calendar-interface';
import { Resource } from '../pages/calendar-reports/calendar-reports';
import { startOfDay } from 'date-fns';
import { utcDate } from '../utils/format-date';

export type Tab =
  | 'Worktime List'
  | 'Add Worktime'
  | 'Add Break'
  | 'Break List'
  | 'Time off Requests'
  | 'Shift Swap Requests'
  | 'Add Time Off';

interface ContextMenuData {
  canModify: boolean;
  resource: Resource;
  date: Date;
}

type State = {
  showScheduleModal: boolean;
  showCopyModal: boolean;
  showFlagModal: boolean;
  activeDate: Date;
  selectedDate: Date | null;
  activeResource: Resource | null;
  activeScheduleTab: Tab;
  worktimeForUpdate: Calendar | null;
  breakForUpdate: Break | null;
  contextMenu: { x: number; y: number } | null;
  contextMenuData: ContextMenuData | null;
  contextCopyData: Resource | null;
};

type Actions = {
  updateShowScheduleModal: (showScheduleModal: boolean) => void;
  updateShowCopyModal: (showCopyModal: boolean) => void;
  updateShowFlagModal: (showFlagModal: boolean) => void;
  updateActiveDate: (date: Date) => void;
  updateSelectedDate: (date: Date | null) => void;
  updateActiveResource: (activeResource: Resource | null) => void;
  updateScheduleModalTab: (activeScheduleTab: Tab) => void;
  updateWorktimeForUpdate: (worktimeForUpdate: Calendar | null) => void;
  updateBreakForUpdate: (breakForUpdate: Break | null) => void;
  updateContextMenu: (contextMenu: { x: number; y: number } | null) => void;
  updateContextMenuData: (contextMenuData: ContextMenuData | null) => void;
  updateContextCopyData: (contextCopyData: Resource | null) => void;
  reset: () => void;
};

const initialState: State = {
  showScheduleModal: false,
  showCopyModal: false,
  showFlagModal: false,
  activeDate: utcDate(startOfDay(new Date())),
  selectedDate: null,
  activeResource: null,
  activeScheduleTab: 'Worktime List',
  worktimeForUpdate: null,
  breakForUpdate: null,
  contextMenu: null,
  contextMenuData: null,
  contextCopyData: null,
};

export const useCalendarSlice = create<State & Actions>()((set) => ({
  ...initialState,
  updateShowScheduleModal: (showScheduleModal: boolean) => {
    set({ showScheduleModal });
  },
  updateShowCopyModal: (showCopyModal: boolean) => {
    set({ showCopyModal });
  },
  updateShowFlagModal: (showFlagModal: boolean) => {
    set({ showFlagModal });
  },
  updateActiveDate: (activeDate: Date) => {
    set({ activeDate });
  },
  updateSelectedDate: (selectedDate: Date | null) => {
    set({ selectedDate });
  },
  updateActiveResource: (activeResource: Resource | null) => {
    set({ activeResource });
  },
  updateScheduleModalTab: (activeScheduleTab: Tab) => {
    set({ activeScheduleTab });
  },
  updateWorktimeForUpdate: (worktimeForUpdate: Calendar | null) => {
    set({ worktimeForUpdate });
  },
  updateBreakForUpdate: (breakForUpdate: Break | null) => {
    set({ breakForUpdate });
  },
  updateContextMenu: (contextMenu: { x: number; y: number } | null) => {
    set({ contextMenu });
  },
  updateContextMenuData: (contextMenuData: ContextMenuData | null) => {
    set({ contextMenuData });
  },
  updateContextCopyData: (contextCopyData: Resource | null) => {
    set({ contextCopyData });
  },
  reset: () => {
    set({ ...initialState });
  },
}));
