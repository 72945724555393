import Dialog from '@mui/material/Dialog';
import { useCalendarSlice } from '../../../store/calendar-slice';
import { XMarkIcon } from '@heroicons/react/24/outline';

type Flag = 'skip' | 'overwrite';

interface CalendarFlagModalProps {
  handleCsvInputFileWithFlag: (flag: Flag) => void;
  handleClearCsvInputFIle: () => void;
}

const CalendarFlagModal = ({
  handleCsvInputFileWithFlag,
  handleClearCsvInputFIle,
}: CalendarFlagModalProps) => {
  const show = useCalendarSlice((state) => state.showFlagModal);
  const updateShowFlagModal = useCalendarSlice((state) => state.updateShowFlagModal);

  const handleCloseModal = () => {
    updateShowFlagModal(false);
    handleClearCsvInputFIle();
  };

  const handleSelectFlag = (flag: Flag) => {
    handleCsvInputFileWithFlag(flag);
    updateShowFlagModal(false);
  };

  return (
    <Dialog onClose={handleCloseModal} open={show} fullWidth={true} maxWidth={'xs'}>
      <div className="flex flex-col w-full p-6">
        <div className="flex items-center justify-between pb-6 mb-6 text-lg font-medium leading-6 text-gray-900 border-b">
          <span>Please select one of these options.</span>
          <button
            type="button"
            onClick={handleCloseModal}
            className="transition border border-transparent rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            <XMarkIcon className="w-6 h-6" />
          </button>
        </div>
        <div className="flex items-center justify-between">
          <button
            type="button"
            onClick={() => handleSelectFlag('skip')}
            className="h-10 ml-auto mr-4 text-sm tracking-wide text-white transition bg-indigo-500 border border-transparent rounded shadow-sm w-28 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Skip
          </button>
          <button
            onClick={() => handleSelectFlag('overwrite')}
            type="button"
            className="h-10 text-sm tracking-wide text-white transition bg-indigo-500 border border-transparent rounded shadow-sm w-28 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Override
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default CalendarFlagModal;
