import { create } from 'zustand';
import { StaffWorkTimeReportResponse } from '../interfaces/staff-work-time-report-response';

type State = {
  data: StaffWorkTimeReportResponse | undefined;
};

type Actions = {
  updateData: (workspace: StaffWorkTimeReportResponse | undefined) => void;
  reset: () => void;
};

const initialState: State = {
  data: undefined,
};

export const usePrintWorkTimeDetailsSlice = create<State & Actions>()((set) => ({
  ...initialState,
  updateData(data: StaffWorkTimeReportResponse | undefined) {
    set({ data });
  },
  reset: () => {
    set({ ...initialState });
  },
}));
