import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { SparklesIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { useTitle } from 'react-use';
import Calendar from '../../components/libs/calendar';
import WorkspaceVector from '../../../assets/vectors/workspace.svg';
import MockSideBar from './components/mock-side-bar';

const WelcomePage = () => {
  useTitle(`${import.meta.env.VITE_APP_TITLE} | WelcomePage`);
  const navigate = useNavigate();

  return (
    <div className="flex w-full">
      <MockSideBar />
      <div className="flex w-full justify-around pt-14 mx-auto max-w-7xl">
        <div className="flex">
          <SparklesIcon className="h-14 w-14 text-indigo-500" />
          <div className="flex flex-col items-center w-[500px] ml-6 space-y-9">
            <h1 className="text-3xl tracking-tight font-bold text-gray-900 sm:text-3xl sm:tracking-tight md:text-4xl md:tracking-tight">
              Awesome! It&apos;s time to create your space
            </h1>
            <div className="text-gray-800 font-medium text-lg space-y-6">
              <p>
                Here, everything happens in a workspace, a place where you schedule your employees,
                while keeping a visual track of everyone&apos;s attendance, absences and leave
                requests.
              </p>
            </div>
            <button
              className="flex items-center justify-center w-56 h-12 text-white bg-indigo-500 rounded "
              onClick={() => navigate(`/nw-name`)}
            >
              Create a workspace <ChevronRightIcon className="w-6 h-6 ml-2" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
