import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter } from 'react-router-dom';
import App from './app/app';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './app/components/error-fallback';
import { Toaster } from 'react-hot-toast';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { persister, toastOptions } from './config';
import './index.scss';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import Loading from './app/components/libs/loading';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import gbLocale from 'date-fns/locale/en-GB';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './mui-theme';

if (import.meta.env.VITE_APP_SENTRY === 'true') {
  Sentry.init({
    dsn: 'https://5ac6e349d80f423a84c6194af4ad7170@o914862.ingest.sentry.io/4504133889032192',
    integrations: [new BrowserTracing(), new Sentry.Integrations.Breadcrumbs({ console: false })],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const queryClient = new QueryClient();
const root = document.getElementById('root');

ReactDOM.createRoot(root!).render(
  // <React.StrictMode>
  <React.Suspense fallback={<Loading />}>
    <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={gbLocale}>
          <ThemeProvider theme={theme}>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <App />
            </ErrorBoundary>
          </ThemeProvider>
        </LocalizationProvider>
        <Toaster toastOptions={toastOptions} />
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </BrowserRouter>
    </PersistQueryClientProvider>
  </React.Suspense>,
  // </React.StrictMode>
);
