import { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from '@mui/material/TextField';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { getHours, getMinutes, isBefore, setHours, setMinutes, setSeconds } from 'date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { dateUtc, utcDate } from '../../../utils/format-date';
import { StaffClockInReport } from '../../../interfaces/clockin-reports-interface';
import reportsService from '../../../services/reports-service';
import { useAttendanceClockSlice } from '../../../store/attendance-clock-slice';
import clockService from '../../../services/clock-service';
import { AxiosError } from 'axios';
import { customToastError } from '../../../utils/custom-toast-error';

interface IForm {
  start: string | Date | null;
  end: string | Date | null;
}

const schema = yup
  .object({
    start: yup
      .date()
      .transform((value) => value ?? undefined)
      .required('Please fill the Start Time'),
    end: yup
      .date()
      .transform((value) => value ?? undefined)
      .required('Please fill the End Time'),
  })
  .required();

const EditBreakModal = () => {
  const queryClient = useQueryClient();
  const br = useAttendanceClockSlice((state) => state.break);
  const isBreakOpen = useAttendanceClockSlice((state) => state.isBreakOpen);
  const resetBreak = useAttendanceClockSlice((state) => state.updateBreak);
  const closeModal = useAttendanceClockSlice((state) => state.updateIsBreakOpen);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IForm>({
    resolver: yupResolver<any>(schema),
  });

  useEffect(() => {
    if (br) {
      setValue('start', dateUtc(br.start));
      setValue('end', dateUtc(br.end));
    } else {
      setValue('start', null);
      setValue('end', null);
    }
  }, [br]);

  const { mutate: updateBreak, isLoading: updateBreakIsLoading } = useMutation(
    clockService.updateBreak,
    {
      onSuccess: (data) => {
        handleCloseModal();
      },
      onError(error: AxiosError<any>) {
        customToastError('Error', error?.response?.data?.message ?? 'Sorry there is a problem');
      },
      onSettled: () => {
        queryClient.invalidateQueries([reportsService.clockInReportsQueryKey]);
      },
    },
  );

  const onSubmit: SubmitHandler<IForm> = (data) => {
    if (updateBreakIsLoading) {
      return;
    }
    const startH = setHours(br!.start, getHours(new Date(data.start!)));
    const startM = setMinutes(startH, getMinutes(new Date(data.start!)));
    const start = utcDate(setSeconds(startM, 0)).getTime();
    const endH = setHours(br!.end, getHours(new Date(data.end!)));
    const endM = setMinutes(endH, getMinutes(new Date(data.end!)));
    const end = utcDate(setSeconds(endM, 0)).getTime();
    if (isBefore(endM, startM)) {
      customToastError('Error', `Please Select a valid time`);
      return;
    }
    updateBreak({
      break_id: br!.id,
      start,
      end,
    });
  };

  const handleCloseModal = () => {
    closeModal(false);
    resetBreak(null);
  };

  return (
    <Dialog onClose={handleCloseModal} open={isBreakOpen} fullWidth={true} maxWidth={'sm'}>
      <div className="flex flex-col w-full p-6">
        <div className="pb-6 mb-6 text-lg font-medium leading-6 text-gray-900 border-b">
          Edit Break
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-6">
          <div className="grid grid-cols-1 pt-6 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <Controller
                name="start"
                control={control}
                render={({ field }) => (
                  <TimePicker
                    {...field}
                    label="Start Time"
                    ampm={false}
                    renderInput={(params) => <TextField fullWidth={true} {...params} />}
                  />
                )}
              />
            </div>
            <div className="sm:col-span-3">
              <Controller
                name="end"
                control={control}
                render={({ field }) => (
                  <TimePicker
                    {...field}
                    label="End Time"
                    ampm={false}
                    renderInput={(params) => <TextField fullWidth={true} {...params} />}
                  />
                )}
              />
            </div>
          </div>
          <div className="flex items-center justify-between w-full pt-6 h-[72px] mx-6 mt-6 border-t md:mx-0">
            <button
              type="button"
              onClick={handleCloseModal}
              className="h-10 text-sm tracking-wide text-white transition bg-red-500 border border-transparent rounded shadow-sm w-28 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Close
            </button>
            <button
              type="submit"
              className="h-10 text-sm tracking-wide text-white transition bg-indigo-500 border border-transparent rounded shadow-sm w-28 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default EditBreakModal;
