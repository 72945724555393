import { useActiveWorkspaceSlice } from '../store/active-workspace-slice';
import http from './axios-instance';
import { Location as ILocation } from '../interfaces/location-interface';

const locationsQueryKey = 'Locations';
const getLocations = async (): Promise<ILocation[]> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/location/get`;
  const res = await http.get(url, { headers });
  return res.data;
};

const createLocation = async (data: any): Promise<ILocation> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/location/create`;
  const res = await http.post(url, data, { headers });
  return res.data;
};

const updateLocation = async (
  locationId: number,
  data: { title: string; points: any[] },
): Promise<ILocation> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const params = { location_id: locationId };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/location/edit`;
  const res = await http.patch(url, data, {
    headers,
    params,
  });
  return res.data;
};

const deleteLocation = async (id: number, new_location_id?: number): Promise<any> => {
  const workspace = useActiveWorkspaceSlice.getState().workspace;
  const headers = { workSpaceSubDomain: workspace!.sub_domain };
  const params = { location_id: id, ...(new_location_id && { new_location_id }) };
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/location/delete`;
  const res = await http.delete(url, { headers, params });
  return res.data;
};

const locationsService = {
  locationsQueryKey,
  getLocations,
  createLocation,
  updateLocation,
  deleteLocation,
};

export default locationsService;
