import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { customToastError } from '../utils/custom-toast-error';
import calendarService from '../services/calendar-service';
import { CopyScheduleOfCalendarResponse } from '../interfaces/copy-schedule-of-calendar-response';
import { CopyScheduleOfCalendarRequest } from '../interfaces/copy-schedule-of-calendar-request';
import toast from 'react-hot-toast';

export function useCopyScheduleOfCalendar(
  configOptions: UseMutationOptions<CopyScheduleOfCalendarResponse, any, any, any> = {},
) {
  const queryClient = useQueryClient();
  return useMutation(
    (body: CopyScheduleOfCalendarRequest) => calendarService.copyScheduleOfCalendar(body),
    {
      onSuccess: () => {
        toast.success('Copied successfully');
      },
      onError: (data: AxiosError) => {
        const err = data.response?.data as any;
        customToastError('Error', err?.message ?? 'Sorry there was a problem');
      },
      onSettled: () => {
        queryClient.invalidateQueries([calendarService.workingTimesQueryKey]);
      },
      ...configOptions,
    },
  );
}
