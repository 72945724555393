import Chart from 'react-apexcharts';

interface PieChartProps {
  chartData: {
    series: number[];
    labels: string[];
  };
  colors: string[];
}

const PieChart = ({ chartData, colors }: PieChartProps) => {
  return (
    <Chart
      options={{
        chart: {
          id: 'basic-pie-chart',
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: false,
                },
                name: {
                  color: '#666666',
                  fontWeight: 500,
                  fontSize: '14px',
                },
                value: {
                  color: '#222222',
                  fontWeight: 500,
                  fontSize: '28px',
                },
              },
            },
          },
        },
        legend: {
          show: true,
          horizontalAlign: 'center',
          position: 'right',
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: true,
        },
        colors,
        labels: chartData.labels,
      }}
      series={chartData.series}
      type="donut"
      width="100%"
      height={250}
    />
  );
};

export default PieChart;
