import { differenceInMinutes, isAfter, isSameDay } from 'date-fns';
import { Resource } from '../pages/calendar-reports/calendar-reports';
import { allowToModify } from './allow-to-modify';
import { dateUtc, formatDateToTimeZone } from './format-date';
import { Calendar } from '../interfaces/calendar-interface';
import { LeaveReq } from '../interfaces/calendar-leave-request-interface';
import { breaksTotalMin } from '../pages/calendar-reports/components/break-list';
import { timeWithLabel } from './format-minutes';

export const calcResource = (date: Date, resource: Resource) => {
  const canModify = allowToModify(date);
  const schedules = resource.schedules.filter((ev) => {
    return isSameDay(dateUtc(ev.start_at), dateUtc(date));
  });
  const leaveRequests = resource.leaveRequests.filter((lr) => {
    return isSameDay(dateUtc(lr.start_at), dateUtc(date));
  });
  let jobTitles: string[] = [];
  let scheduleBySupervisor: Calendar[] = [];
  let scheduleByStaff: Calendar[] = [];
  let dayLeaveAccepted: LeaveReq[] = [];
  let dayLeavePending: LeaveReq[] = [];
  let dayLeaveDeclined: LeaveReq[] = [];
  let hourLeaveAccepted: LeaveReq[] = [];
  let hourLeavePending: LeaveReq[] = [];
  let hourLeaveDeclined: LeaveReq[] = [];
  leaveRequests.forEach((lr) => {
    if (lr.all_day === 0) {
      if (lr.status === 'approved') {
        dayLeaveAccepted = [...dayLeaveAccepted, lr];
      }
      if (lr.status === 'pending') {
        dayLeavePending = [...dayLeavePending, lr];
      }
      if (lr.status === 'declined') {
        dayLeaveDeclined = [...dayLeaveDeclined, lr];
      }
    } else {
      if (lr.status === 'approved') {
        hourLeaveAccepted = [...hourLeaveAccepted, lr];
      }
      if (lr.status === 'pending') {
        hourLeavePending = [...hourLeavePending, lr];
      }
      if (lr.status === 'declined') {
        hourLeaveDeclined = [...hourLeaveDeclined, lr];
      }
    }
  });
  const isLeaveRequestActive =
    dayLeavePending.length ||
    dayLeaveDeclined.length ||
    hourLeaveAccepted.length ||
    hourLeavePending.length ||
    hourLeaveDeclined.length;
  let wtMins = 0;
  let brMins = 0;
  let sw: Calendar['shift_requests'] = [];
  let tempEnd = schedules?.[0]?.end_at;
  const start = schedules?.[0]?.start_at
    ? formatDateToTimeZone(schedules[0].start_at, 'HH:mm')
    : null;
  (schedules ?? []).forEach((sch, index) => {
    sw = [...sw, ...(sch?.shift_requests ?? [])];
    if (index > 0 && isAfter(sch.end_at, tempEnd)) {
      tempEnd = sch.end_at;
    }
    if (sch.over_time === 0) {
      scheduleBySupervisor = [...scheduleBySupervisor, sch];
      if (sch.job && sch.job.id !== resource.jobId) {
        jobTitles = [...jobTitles, sch.job.title];
      }
    } else {
      scheduleByStaff = [...scheduleByStaff, sch];
    }
    wtMins = wtMins + differenceInMinutes(sch.end_at, sch.start_at);
    brMins = brMins + breaksTotalMin(sch.breaks);
  });
  const brTime = timeWithLabel(brMins, 'Break');
  const end = tempEnd ? formatDateToTimeZone(tempEnd, 'HH:mm') : null;
  const [reason] = dayLeaveAccepted?.[0]?.leave?.title?.split(' ') ?? [];

  return {
    canModify,
    schedules,
    jobTitles,
    scheduleBySupervisor,
    scheduleByStaff,
    dayLeaveAccepted,
    dayLeavePending,
    dayLeaveDeclined,
    hourLeaveAccepted,
    hourLeavePending,
    hourLeaveDeclined,
    isLeaveRequestActive,
    sw,
    start,
    brTime,
    end,
    reason,
  };
};
